@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;

.scripts-view-component {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: 0;
  overflow-y: auto;
  @include roboto-regular();

  &__content {
    width: 90%;
  }

  &__empty {
    font-size: 16px;
    text-align: center;
  }
}