@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';

input {
    &:focus {
        outline: 0;
    }
}

.DummyInput__wrapper {
    height: 74px;
    width: 100%;

    &.disabled {
        .textfield {
            .input {
                background: $color-bg;
                cursor: not-allowed;
                border: 1px solid $color-border!important;
            }
            .label {
                cursor: not-allowed;
            }
        }
    }

    .textfield {
        position: relative;
        padding-top: 16px;

        .input {
            height: 40px;
            width: 100%;

            padding: 10px 20px 10px 12px;

            background: $color-contrast;
            border: 1px solid $color-border;
            box-sizing: border-box;
            border-radius: 3px;

            @include roboto-regular;
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: $color-grey-additional;

            white-space: nowrap;
            overflow: hidden;
            
            transition: all 0.07s ease-in-out;

            &:hover {
                border: 1px solid $color-accent;
            }

            &:focus {
                border: 2px solid $color-accent;
                padding: 10px 19px 10px 11px;
            }
        }

        .input_invalid {
            border-color: $color-error!important;
            color: $color-error!important;
        }

        .has_icon_input {
            padding-left: 44px!important;
        }

        .label {
            @include roboto-regular;
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;

            display: block;
            position: absolute;
            top: 26px;
            left: 12px;
            width: 80%;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            pointer-events: none;
            transition: all 0.07s ease-in-out;
            color: $color-grey-additional-2;
        }

        .label_focused {
            top: 0;
            left: 12px!important;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.4px;
            color: $color-accent;
            pointer-events: visible;
        }

        .label_has_value_not_focused_disabled {
            top: 0;
            left: 12px!important;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.4px;
        }

        .label_has_value_not_focused {
            color: $color-grey-additional-2!important;
        }

        .label_invalid {
            color: $color-error!important;
        }

        .has_icon_label {
            left: 44px;
        }

        .error_field {
            @include roboto-regular;
            font-style: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.4px;

            display: block;
            position: absolute;
            top: 60px;
            left: 12px;
            width: 91%;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            opacity: 0;
            visibility: hidden;

            transition: all 0.07s ease-in-out;
            color: $color-error;
        }

        .error_visible {
            opacity: 1;
            visibility: visible;
        }

        .icon {
            position: absolute;
            top: 24px;
            left: 10px;
            width: 24px;
            height: 24px;
            svg {
                path {
                    transition: all 0.07s ease-in-out;
                }
            }
        }

        .icon_focused {
            svg {
                path {
                    fill: $color-accent;
                }
            }
        }

        .icon_invalid {
            svg {
                path {
                    fill: $color-error!important;
                }
            }
        }
    }

}