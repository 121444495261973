@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;


.CustomerServicePage2 {
  background: $color-bg-grey;
  height: 100vh;

  &__wrapper {
    @include borderBox();
    min-height: 100%;
    height: 100%;
    padding: 24px 44px 72px 104px;
    width: calc(100% - 312px);

    .customer_service_tabs_wrapper {
      padding-left: 12px;
      padding-right: 12px;

      @media (max-width: 1200px) {
          display: none;
      }
    }

    .e-spinner {
      display: flex;
      justify-content: center;
      height: 100%;
      align-items: center;
    }
    .waiting_alert {
      @include roboto-regular;
      letter-spacing: 0.15px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: help;
    }

    .adaptive_alert {
      display: none;
      @include roboto-regular;
      letter-spacing: 0.15px;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;

      @media (max-width: 1200px) {
        display: block;
      }
    }

    @media (max-width: $tablet) {
      padding-right: 12px;
      padding-left: 76px;
      width: 100%;
    }

    @media (max-width: $mobile) {
      padding-left: 12px;
    }
  }

  &__pageTitle {
    @include arial-bold();
    color: $color-secondary;
    font-size: 36px;
    letter-spacing: 0.2px;
    line-height: 40px;
    margin-bottom: 24px;

    @media (max-width: 540px) {
      font-size: 22px;
      white-space: nowrap;
    }
  }

  &__content {
    @include borderBox();
    @include zeroFont();
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1),
    0 5px 5px 2px rgba(0, 0, 0, 0.05);
    padding: 0 0 10px;
    margin: 0;
  }
}