@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/breakpoint' as *;

.contentBlock {
  background-color: $color-contrast;
  border-radius: 4px;
  margin-top: 16px;
  border: 1px solid $color-border;

  .contentBlock__header {
    display: flex;
    justify-content: space-between;
    padding: 26px 24px 0 24px;

    @media (max-width: $mobile) {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 16px;
    }

    .contentBlock__title {
      @include arial-bold();
      color: #8E8E93;
      font-size: 20px;
      line-height: 20px;
    }

    .contentBlock__actionButton {

    }

  }

  .contentBlock__content {

  }
}
