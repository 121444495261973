@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.billing-list-info {

  display: flex;
  font-family: Roboto;

  &__avatar {
    width: 88px;
    height: 88px;
    margin: 6px 12px 0 2px;
    background: rgba(22, 101, 216, 0.1);
    border-radius: 50%;

    flex-shrink: 0;
  }

  &__info {

    flex-basis: 236px;
    margin: 6px 16px 0 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > * {
      margin-bottom: 8px;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  &__name {

    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $color-grey-additional;
    max-width: 300px;
  }

  &__id-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    color: $color-gray-text;

  }

  &__id-country {
    border-radius: 50%;
    height: 16px;
    width: 16px;
    margin-right: 8px;
  }

  &__call-center {
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;
    color: $color-gray-text;
  }

  &__call-center-name {
    color: $color-grey-additional;
  }
}