@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;

.choosed {
  &:hover {
    .ProductItemToAdd__product_image__wrapper {
      box-shadow: none!important;
    }
  }
}

.ProductItemToAdd__wrapper {
  height: 196px;
  width: 128px;
  font-size: 14px;
  @include arial-regular();
  color: $color-main;
  transition: all 0.2s ease-in-out;
  margin: 6px;
  box-shadow: none;
  position: relative;

  .ProductBlur {
    height: 56.72%;
    position: absolute;
    top: 18px;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    mix-blend-mode: normal;
    backdrop-filter: blur(5px);
    z-index: 1;
    border-left: 1px solid #D1D1D6;
    border-right: 1px solid #D1D1D6;

    .lock-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .promo_alert {
    @include roboto-regular();
    font-weight: 500;
    text-transform: uppercase;
    font-size: 10px;
    position: absolute;
    top: 167px;
    z-index: 3;
    width: 128px;
    height: 27.5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-top: 1px solid #D1D1D6;
    padding: 8px;
  }

  .ProductItemToAdd__product_image__wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 196px;
    width: 128px;
    border: 1px solid #D1D1D6;
    background: #fff;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    .ProductImage {
      height: 56.72%;
      position: absolute;
      top: 18px;
      .ProductImage__name {
        color: transparent;
      }
    }

    .ProductItemToAdd__product_image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 4px;
    }
  }

  .ProductItemToAdd__product_type {
    position: absolute;
    width: auto;
    max-width: 152px;
    left: 4px;
    top: 3.26%;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 2px 4px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
    z-index: 2;
  }

  .ProductItemToAdd__product_name {
    @include roboto-bold();
    font-style: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $color-main;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    padding: 8px;
    width: 128px;
    top: 120px;
    position: absolute;
  }

  .ProductItemToAdd__product_price_wrapper {
    display: flex;
    padding: 8px;
    width: 128px;
    top: 137px;
    position: absolute;

    svg {
      max-width: 14px;
      min-width: 14px
    }

    .product_price {
      @include roboto-regular();
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.4px;
      color: $color-grey-additional;
      padding-left: 4px;
      max-width: 43px;
      min-width: 43px;
      width: 43px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .product_discount {
      @include roboto-regular();
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.4px;
      color: $color-grey-additional;
      margin-left: 10px;
      max-width: 41px;
      min-width: 41px;
      width: 41px;
      color: $color-error;
    }

  }

  .e-button__wrapper {
    position: absolute;
    top: 167px;
    z-index: 3;
    .v-size-0 {
      height: 28px;
      line-height: 28px;
    }

    .link {
      @include roboto-regular();
      font-weight: 500;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .e-button {
      @include simpleAnimateZeroPointTwoSecs(all);
      &:before,
      &:after {
        @include simpleAnimateZeroPointTwoSecs(all);
      }
    }

    .link {
      @include simpleAnimateZeroPointTwoSecs(all);
      svg {
        path {
          @include simpleAnimateZeroPointTwoSecs(all);
        }
      }
    }

    .e-button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-color: transparent;
      &::before {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-color: transparent;
        border-top: 1px solid #D1D1D6;
      }
    }
  }

  &:hover {
    .ProductItemToAdd__product_image__wrapper {
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
    }
  }
  
  &.disabled {
    .ProductItemToAdd__product_image__wrapper {
      box-shadow: none!important;
    }

    .promo_alert {
      @include roboto-regular();
      font-weight: 500;
      text-transform: uppercase;
      font-size: 10px;
      position: absolute;
      top: 167px;
      z-index: 3;
      width: 128px;
      height: 27.5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-top: 1px solid #D1D1D6;
      padding: 8px;
    }

    .e-button__wrapper {
      .e-button {
        &:before {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}
