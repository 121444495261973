@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/slick';

.callHistory__wrapper {
  .b-call-history {
    @include borderBox();
    display: block;
    text-align: left;
    position: relative;
    padding: 0 8px;
    &:before {
      @include pseudoBlock();
      left:0;
      right: 0;
      top: 8px;
      height: 1px;
      background-color: fade($color-tertiary, 0.6);
    }
  }

  .history-title {
    @include arial-bold();
    font-size: 14px;
    letter-spacing: 0.2px;
    color: $color-secondary;
    position: relative;
    display: inline-block;
    line-height: 1;
    background-color: $color-contrast;
    margin-bottom: 9px;
    padding: 0 8px;
  }

  .slide {
    text-align: center;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      background: fade($color-tertiary, 0.6);
      top: 12px;
      left: 0;
    }
  }

  .item {
    text-align: center;
    position: relative;
    display: flex;
    padding-left: 16px;
    justify-content: flex-end;
    &:hover {
      .tooltip {
        display: block;
      }
    }
    &.success {
      .image {
        border-color: $color-success;
        background-color: $color-success;
      }
      .image-icon {
        fill: $color-contrast;
      }
    }
  }

  .image {
    @include borderBox();
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    z-index: 10;
    background-color: $color-contrast;

    &.status__2 {
      border-color: $color-tertiary;
      svg {
        path {
          fill: $color-tertiary;
        }
      }
    }

    &.status__3 {
      border-color: $color-accent;
      svg {
        path {
          fill: $color-accent;
        }
      }
    }

    &.status__4 {
      border-color: $color-success;
      svg {
        path {
          fill: $color-success;
        }
      }
    }

    &.status__5 {
      border-color: $color-error;
      svg {
        path {
          fill: $color-error;
        }
      }
    }

    &.status__6 {
      border-color: $color-warning;
      svg {
        path {
          fill: $color-warning;
        }
      }
    }
  }

  .image-icon {
    @include absoluteCenter();
    width: 15px;
    height: 15px;
    display: block;
    fill: $color-icony;
  }

  .date {
    margin-top: 2px;
    @include arial-regular();
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.4px;
    color: $color-secondary;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 7px;
    background: #fff;
    padding-right: 8px;
  }

  .history-tip {
    width: 224px;
    @include arial-regular();
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    z-index: 9999999;
    transform: translate3d(0,0,0);
  }

  .swiper__navButtons {
    position: absolute;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 25px;
    padding: 0 5px;

    .arrowNav_next {
      transform: rotate(-180deg);
    }

    .arrowNav_prev,
    .arrowNav_next {
      cursor: pointer;

      path {
        transition: opacity ease-in-out .3s;
        fill: $color-accent;
        opacity: .5;
      }
    }

    .arrowNav_prev,
    .arrowNav_next {
      &:hover {
        path {
          opacity: 1;
        }
      }
    }
  }

  .swiper__customWrapper {
    height: 35px;
    width: 95%;

    .swiper__customSlideWrapper {
      width: 119px !important;
    }
  }

  .history-slider {
    position: relative;
    padding-left: 35px;
    padding-right: 35px;
    .slider {
      z-index: 20;
    }
    div {
      outline: none !important;
    }
    .slick-arrow {
      position: absolute;
      top: 4px;
      width: 16px;
      height: 16px;
      overflow: hidden;
      color: fade($color-main, 0);
      @include borderReset();
      &.slick-disabled {
        display: none !important;
      }
      &.slick-prev {
        left: -24px;
        &:after {
          @include transform(rotate(-135deg));
          left: 4px;
        }
      }
      &.slick-next {
        right: -24px;
        &:after {
          @include transform(rotate(45deg));
          left:0;
        }
      }
      &:after {
        @include pseudoBlock();
        @include borderBox();
        top: 3px;
        width: 11px;
        height: 11px;
        border-top: 2px solid $color-icony;
        border-right: 2px solid $color-icony;
      }
    }
  }
}
