@use '@styles/core/normalize';
@use '@styles/core/mixins/fonts' as *;

#tooltip-portal {
    z-index: 99999999999;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;

    @include roboto-regular();
}
