@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;


.NomenclatureWrapper {
  & + .NomenclatureWrapper {
    border-top: 1px solid $color-border;
  }

  background: $color-surface;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
  border-top-right-radius: unset;
  border-top-left-radius: unset;

  &:last-child {
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: unset;
    border-top-left-radius: unset;
  }

  &:first-child {
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: unset;
    border-top-left-radius: unset;
  }

  .currentProducts__table {
    &-col {
      &-first {
        padding-left: 115px;

      }
    }

    &-avatar {
      left: 40px;
    }
  }
}
