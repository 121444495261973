@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';

.choosed {
  .ProductSetToAdd__product_image__wrapper {
    border-color: $color-accent!important;
    cursor: initial;
  }
}

.ProductSetToAdd__wrapper {
  height: 184px;
  width: 168px;
  font-size: 14px;
  @include arial-regular();
  color: $color-main;
  transition: all 0.2s ease-in-out;
  margin: 6px;
  box-shadow: none;
  position: relative;

  &__size_handler {
    width: 88px;
    height: 88px;
  }

  .ProductSetToAdd__product_image__wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 184px;
    width: 168px;
    border: 1px solid #D1D1D6;
    background: #fff;
    border-radius: 4px 4px 0 0;
    transition: all 0.2s ease-in-out;

    .ProductImage {
      height: 106px;

      .ProductSetToAdd__product_image {
        width: 100%;
        height: 106px;
        object-fit: contain;
        padding: 4px;
        transition: all 0.2s ease;
      }
    }

    .ProductSetToAdd__product_gallery__wrapper {
      display: flex;
      flex-direction: row;
      height: 42px;

      .ProductImage {
        height: 42px;
        width: 41.5px;
        border: 1px solid #F3F3F3;
        box-sizing: border-box;
        border-radius: 0;
        color:#fff;

        .ProductSetToAdd__product_image {
          height: 42px;
        }
        .blur {
          background: rgba(255, 255, 255, 0.2);
          mix-blend-mode: normal;
          backdrop-filter: blur(2px);
          width: auto;
          position: absolute;
          z-index: 11;
          left: 1px;
          right: 1px;
          top: 1px;
          bottom: 1px;
          .remaining_number {
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            -webkit-transform: translate(-50%, -50%);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }

  .ProductSetToAdd_controls {
    transition: all 0.2s ease-in-out;
    height: 36px;
    width: 100%;
    max-height: 36px;;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 148px;
    z-index: 10;

    .button_add {
      .link {
        color: $color-accent;
      }

      &:hover {
          .link {
            color: $color-contrast;
          }
      }
    }

    .e-button__wrapper {
      margin-left: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      .v-type-3 {
        &::before {
          border: 1px solid $color-accent;
        }
      }
      .e-button {
        padding: 0 6px;
        border-radius: 4px;
        height: 24px;
        line-height: 24px;

        .link {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 10px;
          line-height: 24px;
        }
      }
    }
  }

  .ProductSetToAdd__product_type {
    background: $color-surface;
    position: absolute;
    width: auto;
    max-width: 152px;
    left: 8px;
    top: 3.26%;
    border: 0.6px solid $color-border;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 2px 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    transition: all 0.2s ease;
  }

  .ProductSetToAdd__popup {
    top: 30%;
    margin-top: 0;
    left: 2px;
    width: 98%;
    overflow-x: hidden;
    @include normalLine();
    border-radius: 4px;
    background-color: none;
    border: none;
    box-shadow: none;
    visibility: hidden;
    opacity: 0;
    @include simpleAnimateZeroPointTwoSecs(all);

    ul {
      padding: 4px 0;
      margin: 0;

      li {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        @include borderBox();
        padding: 5px 8px;
        font-size: 12px;
        line-height: 12px;
        color: $color-main;
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        .flex-inner {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        .product_name {
          width: 60%;
          height: 100%;
          padding-right: 3px;


          &--container {
            min-width: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: help;
          }
        }

        .product_quantity_image {
          width: 25%;
          height: 100%;
          cursor: help;

          .quantity__img {
            height: 8px;
            width: 18px;
          }
        }

        .product_quantity_spec {
          width: 15%;
          height: 100%;
        }
      }
    }
  }

  .ProductSetToAdd__product_name {
    z-index: 5;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    color: $color-main;
    font-size: 12px;
    line-height: 16px;
    padding: 8px;
    overflow: hidden;
    border: 1px solid #1665D8;
    border-top: none;
    border-bottom: none;
    background: #fff;
    width: 136px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
    top: 88px;
    left: 0;
    position: absolute;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 122px;

    .ProductSetToAdd__product_contains {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;

      .ProductSetToAdd__product_price {
        margin-top: 7px;
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  &:hover {
    z-index: 3;

    .ProductSetToAdd__popup {
      visibility: visible;
      opacity: 1;
      top: calc(100% + 40px);
      margin-top: -5px;
      left: 2px;
      width: 98%;
      min-height: 100px;
    }

    .ProductSetToAdd__product_image__wrapper {
      box-shadow: 3px 15px 25px rgba(0, 0, 0, 0.17);
      border: 1px solid #1665D8;
      width: 168px;
      height: 336px;
      top: -16px;
      left: 0;

      .ProductSetToAdd__product_image {
        height: 106px;
      }
    }

    .ProductSetToAdd__product_type {
      top: -10px;
    }

    .ProductSetToAdd__product_name {
      width: 100%;
      top: 163px;
      left: 0;
      opacity: 1;
      visibility: visible;
    }

    .ProductSetToAdd_controls {
      top: 136px;
    }
  }

  &.disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.6;
  }
}
