.modal-window__wrapper {
  .-table-block {
    @include tableBlock();
  }
  .-cell-like {
    @include tableCell();
  }
  .c--popup-window-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    text-align: center;
  }
  .c--popup-window__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: fade($color-main, 0.8);
    z-index: 1001;
  }
  .c--popup-window {
    position: relative;
    display: inline-block;
    background-color: $color-contrast;
    box-shadow: 0 0 10px 0 fade($color-main, 0.15);
    z-index: 1002;
    width: var(--modalWindow-width);
    height: var(--modalWindow-height);
    border-radius: 4px;
  }
  .c--popup-window__close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    z-index: 100;
  }
  .c--popup-window__close-icon {
    fill: $color-icony;
    @include simpleAnimate(fill);
    width: 100%;
    height: 100%;
    display: block;
    &:hover {
      fill: $color-accent;
    }
  }
  .c--popup-window__content {
    width: 100%;
    height: 100%;
  }
}