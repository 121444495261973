.collapse__wrapper {
  .e-collapse {
    display: block;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.1), 0 5px 5px 2px rgba(0,0,0,0.05);
    &.active {
      .collapse-head-name,  {
        color: $color-accent;
      }
      .collapse-head-icon {
        fill: $color-accent;
      }
    }
  }

  .collapse-head {
    display: block;
    border-bottom: 1px solid fade($color-tertiary, 0.6);
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    position: relative;
  }

  .collapse-head-name {
    @include arial-bold();
    @include borderBox();
    color: $color-icony;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.8px;
    padding: 0 16px;
    @include simpleAnimate(color);
  }

  .collapse-head-icon {
    width: 20px;
    height: 20px;
    fill: fade($color-icony, 0.6);
    position: absolute;
    right: 16px;
    top: 10px;
    @include simpleAnimate(fill);
  }

  .collapse-body {
    display: block;
    @include borderBox();
    padding: 8px 0;
    position: relative
  }
}
