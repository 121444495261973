.tag__wrapper {
  .e-tag {
    @include ib();
    @include arial-bold();
    height: 20px;
    min-width: 48px;
    padding: 0 4px;
    text-align: center;
    line-height: 19px;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 4px;
    letter-spacing: 1px;
    border: 1px solid $color-accent;
    color: $color-accent;
  }
}