.notify__wrapper {
  .e-notify {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 728px;
    height: 64px;
    z-index: 9999999;
    padding: 16px;
    &.info {
      color: #fff;
      fill: #fff;
      background-color: $color-info;
    }
    &.warning {
      color: $color-main;
      fill: $color-main;
      background-color: $color-warning;
    }
    &.success {
      color: #fff;
      fill: #fff;
      background-color: $color-success;
    }
    &.error {
      color: #fff;
      fill: #fff;
      background-color: $color-error;
    }
  }
  .table {
    @include tableBlock();
  }
  .cell {
    @include tableCell();
  }
  .icon {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
  }
  .content {
    display: inline-block;
    vertical-align: middle;
    padding-left: 24px;
    width: 660px;
    position: relative;
  }
  .title {
    @include arial-bold();
    font-size: 14px;
    color: inherit;
    letter-spacing: 0.2px;
    line-height: 16px;
  }
  .text {
    @include arial-regular();
    font-size: 12px;
    color: inherit;
    letter-spacing: 0.4px;
    line-height: 16px;
    margin-top: 2px;
  }
  .close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    &:hover {
      .close-icon {
        fill: $color-accent;
      }
    }
  }
  .close-icon {
    width: 10px;
    height: 10px;
  }
}