.orderCustomerInfo__form {
  .e-text-input {
    .input-label {
      &:before {
        background-color: #F2F2F2;
      }
    }
  }

  @include flex();
  width: 100%;
  padding: 21px 24px 0 24px;

  @media (max-width: $mobile) {
    padding-left: 12px;
    padding-right: 12px;
  }

  &-block {
    @include flex();
    @include flexWrap(wrap);
    width: 100%;

    @media (max-width: $mobile) {
      justify-content: center;
      align-items: center;
      flex-direction: column;

      & .textInput__wrapper {
        width: 100% !important;
      }
    }

    .order-input {
      margin-bottom: 24px;
    }
  }

  .order-input {
    &.highlight__success-phone {
      .input-label {
        .input-label-text {
          color: $color-success;
        }
      }
      input {
        border-color: $color-success;
      }
      span {
        border-color: $color-success;
      }
    }
  }

  &--newNumber {
    @include flex();
    @include flexAlignItems(center);
    margin-bottom: 28px;
    position: relative;

    @media (max-width: $mobile) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;

      & .textInput__wrapper {
        width: 100% !important;
      }
    }

    .order-input {
      margin-bottom: 0;

      &.with-add-button {
        input {
          padding-right: 40px;
        }

        + .input-right-icon {
          position: absolute;
          right: 30px;
          cursor: pointer;

          svg.removePhoneCrossIconSvg {
            height: 40px;
            width: 30px;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            right: -5px;
          }

          svg.addPhoneRoundIconSvg {
            height: 40px;
            width: 35px;
            padding-left: 10px;
            padding-right: 5px;
            position: relative;
            left: 4px;
          }

          svg.addPhoneRoundIconSvg, svg.removePhoneCrossIconSvg {
            circle, path {
              @include simpleAnimate(all);
            }
          }

          &:hover {
            svg.addPhoneRoundIconSvg {
              circle {
                fill: $color-success;
                stroke: $color-success;
              }

              path {
                fill: #fff;
              }
            }

            svg.removePhoneCrossIconSvg {
              path {
                fill: $color-error;
              }
            }
          }
        }
      }
    }
  }
}

.orderCustomer__off {
  &.offMode__table {
    .offMode__table-column {
      @include flex();
      @include flexJustify(flex-start);
      @include flexWrap(wrap);
      width: 100%;

      .offMode__table-row {
        width: 323px;

        &:first-child {
          margin-top: 12px;
        }

        .offMode__table-row-name {
          width: 100px;
        }

        .offMode__table-row-value {
          width: 300px;
        }
      }
    }
  }
}

.orderCustomerInfo__form-block {
  .select__wrapper.e-select {
    .arrow {
      top: 11px;
      cursor: pointer;
    }
    .clear-selection {
      position: absolute;
      right: 35px;
      top: 15px;
      cursor: pointer;
    }
  }
  .select__wrapper.e-select.error .handler {
    border-color: $color-error;
  }
}

#customerInfo {
  position: relative;
  z-index: 10;
}