@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;

.Accordion {
    width: 100%;
    @include roboto-regular;

    .AccordionItem {
        color: $color-main;

        &__title {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            min-height: 26px;
            border-bottom: 1px solid $color-border;

            &__value {
                flex: 1;
                font-size: 14px;
                line-height: 18px;
                padding-right: 10px;

                &__expanded {
                    color: $color-accent;
                }
            }
        }

        &__toggle_button {
            width: 16px;
            height: 16px;
            & > .IcAccordionChevronUpSvg path {
                fill: $color-accent;
                transform: rotate(0deg);
                transform-origin: center;
                @include simpleAnimate(transform);
            }

            &__collapsed {
                & > .IcAccordionChevronUpSvg path {
                    fill: $color-icony;
                    transform: rotate(180deg);
                }
            }
        }

        &__payload {
            margin: 8px 20px;
            padding: 0;
            font-size: 12px;
            line-height: 16px;
            max-height: 0;
            overflow: hidden;
            pointer-events: none;
            visibility: hidden;
            opacity: 0;
            @include simpleAnimate(all);
            transition: all 0.2s ease-in-out;

            &__expanded {
                max-height: 500px;
                pointer-events: initial;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
