@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.billing-list-stats {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(2, 124px);
    grid-template-rows: repeat(2, 48px);

    margin-top: 6px;
    margin-right: 50px;

    &__item {
        &-value {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            color: #3A3A3A;

            &-red {
                font-weight: 500;
                font-size: 24px;
                line-height: 28px;
                color: #FF3A47;
            }
        }
        &-title {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.4px;
            color: #99A2AD;
        }
    }
}
@media (max-width: 960px) {
    .billing-list-stats {
        margin-right: 16px;
    }
}

@media (max-width: $mobile) {
    .billing-list-stats {
        display: flex;
        justify-content: space-between;

        margin: 0;
        padding: 12px 0;
        width: 312px;
        gap: 19px;
    }
}