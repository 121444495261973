.orderAddress {
  &__showAddressOnMap {
    padding: 0 10px;
  }

  &__input {
    vertical-align: middle;
  }
  &__country-form {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(center);
    @include borderBox();
    padding: 24px 16px;
    border-bottom: 1px solid $color-border;

    .orderAddress__country-form-label {
      position: relative;
    }

    .orderAddress__country-form-button {
      position: absolute;
      right: -128px;
      top: 0;
    }

    @media (max-width: $mobile) {
      flex-wrap: wrap;
      padding: 12px 8px;
    }

    @media (max-width: 1300px) {
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .orderAddress__country-form-label {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    &__row-on-mobile {
      @media (max-width: $mobile) {
        flex: 1;
        justify-content: space-between;

        .textInput__wrapper, .clad-autofill-select__wrapper {
          width: calc(100% - 125px) !important;
          margin-left: 0;
        }
      }
    }
    &-label {
      @media (max-width: $mobile) {
        width: 100%;
        margin-left: 0;
        margin-top: 4px;
      }
      @include borderBox();
      @include arial-bold();
      color: $color-secondary;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      width: 188px;
      margin-left: 25px;
    }
    .orderAddress__input {
      &:nth-child(1) {
        margin-right: 8px;
      }
      &:nth-child(2) {
        margin-left: 8px;
      }
    }
  }
  &__main-form {
    @include flex();
    @include flexDirection(column);
    @include flexAlignItems(center);
    @include flexJustify(center);
    padding: 16px;

    @media (max-width: 1300px) {
      align-items: flex-start;
    }

    @media (max-width: $mobile) {
      padding-left: 12px;
      padding-right: 12px;

      & .textInput__wrapper,
      & .clad-autofill-select__wrapper {
        width: 100% !important;
      }
    }

    &--customAddresses,
    &--addresses {
      width: 100%;
      max-width: 590px;
    }
    &--addresses {
      @include flex();
      @include flexWrap(wrap);
      @include flexJustify(space-between);
    }
    .orderAddress__input {
      margin-bottom: 32px;
    }
  }
}
