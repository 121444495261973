@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;

.PanelUserLabel {
  display: flex;
  user-select: none;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  margin-top: 4px;
  width: 100%;

  .label {
    align-items: center;
    @include arial-regular();
    display: inline-block;
    font-size: 10px;
    letter-spacing: .4px;
    color: $color-icony;
    position: relative;

    text-overflow: ellipsis;
    overflow: hidden;
    cursor: help;

    &:nth-child(1) {
      width: 30%;
    }

    &:nth-child(2) {
      width: 50%;
    }

    &:nth-child(3) {
      width: 25%;
    }
  }

  .label-icon {
    width: 16px;
    height: 16px;
    fill: $color-icony;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    display: block;
  }
}
