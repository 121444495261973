@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;


.activeChip {
  path {
    fill: $color-accent;
  }
}

.Nav__chip {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .NavigationSvgIcon {
    path {
      transition: all 0.1s 0.1s ease;
    }
  }

  &:hover {
    .NavigationSvgIcon {
      path {
        fill: $color-accent;
      }
    }
  }
}

.ProductNavBar {
  background-color: $color-bg;
  position: absolute;
  top: 55px;
  right: 13px;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  box-shadow: 3px 15px 25px rgba(0, 0, 0, 0.17);

  &.entering {
    opacity: 0;

    transition: all .1s ease;
    transform: translateY(-20px);
  }

  &.entered {
    opacity: 1;

    transition: all .1s ease;
    transform: translateY(0px);
  }

  &.exiting {
    transition: all .1s ease;
    transform: translateY(-10px);
    opacity: .5;
  }

  &.exited {
    transition: all .1s ease;
    opacity: 0;
    display: none;
  }

  .item__bar {
    height: 44px;
    width: 88px;
    border-radius: 4px;
    transition: all 0.1s 0.1s ease;

    &.noBorderRadiusTop {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.noBorderRadiusBottom {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.5px;

    color: $color-grey-additional-2;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      background-color: $color-primary-light-blue;
      color: $color-accent;
    }
  }
}
