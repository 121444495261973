@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.appeal__wrapper {
    position: relative;
    min-height: 669px;
    height: 669px;

    &-close-button{
        position: absolute;
        right: 25px;
        top: 25px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
            svg {
                path {
                    fill: $color-accent;
                }
            }
        }
    }
}

.appeal__wrapper-content {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 28px;
}

.appeal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
    @include roboto-regular();
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.appeal-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 10px;
    }

    &-label {
        color: $color-grey-additional-2;
        @include roboto-regular();
        font-size: 14px;
        line-height: 18px;
        min-width: 100px;
    }

    &-content {
        padding-left: 8px;
        @include roboto-regular();
        font-size: 14px;
        line-height: 18px;
        color: $color-grey-additional;
    }
}

.appeal-title__table {
    display: inline-grid;
    flex: 1 1 0%;
    grid-template-columns: minmax(80px, 150px) minmax(50px, 1fr);
    grid-template-rows: repeat(7, max-content);

    .grid-item {
        margin-bottom: 20px;
    }

    &-label {
        color: $color-grey-additional-2;
        @include roboto-regular();
        font-size: 14px;
        line-height: 18px;
        min-width: 100px;
    }

    &-content {
        padding-left: 8px;
        @include roboto-regular();
        font-size: 14px;
        line-height: 18px;
        color: $color-grey-additional;
    }
}

.appealSubject {
    overflow-y: auto;
    max-height: 55px;
}

.appealText {
    overflow-y: auto;
    max-height: 330px;
}

.appealSubject,
.appealText {
    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background: #90CAF9;
        border: 2px solid #90CAF9;
        border-radius: 2px;
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
}