@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.thumb-vertical {
    background: $color-primary-light-blue;
    border-radius: 2px;
}

.customer_order_controls_wrapper {
    width: 100%;
    padding: 20px 52px 28px 52px;

    .headline {
        @include roboto-regular;
        letter-spacing: 0.15px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;

        color: $color-grey-additional-2;

        margin-bottom: 28px;
    }

    .customer_order_block_controls {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        &:last-child {
            margin-right: 0;
        }

        .customer_order_item_name {
            width: 26.3%;
            min-width: 160px;
            margin-right: 16px;
        }

        .customer_order_item_order_id {
            width: 14.5%;
            min-width: 88px;
            margin-right: 16px;
        }

        .customer_order_item_client_name {
            width: 25.7%;
            min-width: 156px;
            margin-right: 16px;
        }

        .customer_order_item_phone_number {
            width: 25.7%;
            min-width: 156px;
        }

        .customer_order_item {
            margin-right: 16px;
            width: 296px;
        }
    }
}

.customer_order-grid-orders-table {
    .grid_header_cell:nth-child(1) {
        padding-left: 19px;
    }

    .langIcon.round {
        border-radius: 50%;
        width: 16px;
        min-width: 16px;
        height: 16px;
    }

    .grid_row {
        .grid_cell:nth-child(1) {
            padding-left: 19px;
        }

        .grid_cell {
            &.dots_in_text {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: help !important;
            }

            .smsStatus {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 2px 4px;
                border: 0.6px solid $color-border;
                box-sizing: border-box;
                border-radius: 3px;
                background: $color-surface;
                color: $color-grey-additional-2;
                font-size: 10px;
                line-height: 12px;
            }

            transition: all 0.1s ease-in-out;
            min-height: 48px;

            &--products {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;

                .generalProductLine {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    padding-top:3px;
                }

                .product_image {
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    min-height: 24px;
                    margin-right: 8px;

                    .ProductImage {
                        .ProductImage__name {
                            display: none;
                        }
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                .product_name {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    .product-label-wrapper {
                        margin-right: 7px;
                    }
                }
            }
        }

        &:hover {
            .grid_cell {
                background: rgba(22, 101, 216, 0.1);
                cursor: pointer;
            }
        }
    }
}

.result-alert {
    width: 100%;
    flex-wrap: nowrap;
    min-height: 48px;
    transition: all 0.1s ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include roboto-regular;
    color: $color-grey-additional;
    opacity: 0.8;
    margin-top: 13px;

    .message_header {
        font-size: 24px;
        line-height: 28px;

    }

    .message_content {
        margin-top: 13px;

        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.5px;
    }

    .message_button {
        width: 100px;
        margin-top: 13px;
    }

    &:hover {
        background: transparent;
        cursor: initial;
    }
}


