.call-widget-modal {
  width: 332px;
  padding: 20px 10px;

  .call-time-row {
    margin-bottom: 0;
    margin-top: 16px;
  }
}

.call-widget__message {
  padding: 8px;
  margin: 0 16px 16px 16px;
  font-family: arial, sans-serif;
  border: 1px solid red;
}
