.currentProducts__form {
  //@media (max-width: $orderBlockBreakpoint) {
  //  display: none;
  //}

  &-tabs-container {
    position: sticky;
    top: 0;
    z-index: 90;
    background: $color-contrast;
    @include flex();
    @include flexJustify(flex-start);
    width: 100%;
    border-style: none none solid none;
    border: 1px solid #D4D4D8;

    & div:last-child {
      margin-left: auto;
    }

    .colorize-me {
      .currentProducts__text {
        color: $color-accent!important;
      }
      & img {
        -webkit-filter: brightness(0) saturate(100%) invert(31%) sepia(100%) saturate(1633%) hue-rotate(204deg) brightness(85%) contrast(100%);
        filter: brightness(0) saturate(100%) invert(31%) sepia(100%) saturate(1633%) hue-rotate(204deg) brightness(85%) contrast(100%);
      }
    }

    .currentProducts__tab {
      height: 36px;
      line-height: 34px;
      display: block;
      width: auto;
      margin: 0;
      text-align: center;
      cursor: pointer;
      @include simpleAnimate(all);

      padding: 0 26px;

      &:first-child {
        border-right: 1px solid $color-border;
      }

      &:hover,
      &.active {
        &:after {
          width: 100%;
          opacity: 1;
        }
      }

      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 0;
        background: $color-accent;
        border: 1px solid $color-accent;
        opacity: 0;
        margin: auto;
        transition: opacity, width ease-in-out 0.2s;
        border-style: none none solid none;
        margin-top: 1px;
      }

      &.active {
        border-bottom: 1px solid $color-accent;

        .currentProducts__text {
          color: $color-accent;
        }
        & img {
          -webkit-filter: brightness(0) saturate(100%) invert(31%) sepia(100%) saturate(1633%) hue-rotate(204deg) brightness(85%) contrast(100%);
          filter: brightness(0) saturate(100%) invert(31%) sepia(100%) saturate(1633%) hue-rotate(204deg) brightness(85%) contrast(100%);
        }
      }

      & img {
        vertical-align: middle;
        margin: 0 5px;
      }

      .currentProducts__text {
        font-size: 14px;
        letter-spacing: 0.8px;
        line-height: 1;
        @include arial-bold();
        @include ib();
        position: relative;
        color: #9EA0A5;
        @include simpleAnimate(color);

        .counter {
          background: $color-accent;
          min-width: 16px;
          height: 16px;
          border-radius: 16px;
          color: $color-contrast;
          font-size: 10px;
          line-height: 12px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          margin-left: 4px;
          position: relative;
          top: -2px;
        }

        .counter__empty {
          background: #9EA0A5;
        }
      }

      .currentProducts__counter {
        @include arial-bold();
        display: block;
        position: absolute;
        top: -10px;
        right: -20px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        line-height: 15px;
        font-size: 8px;
        letter-spacing: 0;
        color: $color-secondary;
        background-color: $color-contrast;
      }
    }
  }
}
