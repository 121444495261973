.ProductImage {
  position: relative;
  width: 100%;
  height: 100%;
  &__name {
    user-select: none;
    color: #3A3A3A;
    text-align: center;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    background: rgba(255, 255, 255, 0.6);
    word-break: break-all;
    padding: 2px;
    line-height: 11.5px;
  }
}

.ProductSetImage {
  height: auto;
  width: 41.5px;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  border-radius: 0;
  & img {
    height: 78px;
  }
}
