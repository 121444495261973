@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.select__wrapper {
  &.e-select {
    position: relative;
    width: var(--select-width);
    height: var(--select-height);
    display: inline-block;
    border-radius: 4px;
    text-align: left;

    &.disabled {
      cursor: default;
      opacity: 0.5;
      @include simpleAnimate(all);
    }

    .select-popup {
      top: 100%;
      margin-top: 4px;
      left: 0;
      width: 100%;

      ul {
        padding: 4px 2px 4px 0;
        margin: 0 0 10px 0;

        li {
          @extend %unselectable;
          @include borderBox();
          line-height: 1.3em;
          padding: 8px 16px;
          cursor: pointer;
          @include arial-regular();
          font-size: 14px;
          letter-spacing: 0.2px;
          color: $color-secondary;
          list-style: none;

          &:hover {
            background-color: fade($color-accent, 0.4);
          }
        }
      }
    }

    .input-label {
      &:before {
        @include simpleAnimate(background-color);
        background-color: lighten($color-tertiary, 20%);
      }
    }

    &.selected,
    &.opened {
      .input-label {
        &:before {
          background-color: $color-contrast;
        }
      }
    }

    &.selected,
    &.opened,
    &.error {
      .input-label {
        opacity: 1;
      }
    }

    &.error {
      border-color: $color-error;

      .handler {
        color: $color-error;
      }

      .input-label-text {
        color: $color-error;
      }
    }

    &.opened {
      .input-label-text {
        color: $color-accent;
      }
    }

    .input-error {
      @include arial-regular();
      display: block;
      position: absolute;
      font-size: 10px;
      line-height: 10px;
      left: 16px;
      top: 100%;
      margin-top: 4px;
      color: $color-error;
    }

    .checkIcon,
    .arrow {
      position: absolute;
      right: 11px;
      will-change: transform;

      path {
        fill: $color-secondary;
      }
    }

    .checkIcon {
      width: 17px;
      height: 17px;
      top: 12px;
    }

    .arrow {
      top: 16px;
      width: 8px;
      height: 20px;

      &.arrow--up {
        transform: rotate(-180deg);
      }
    }

    &.disabled {
      .handler {
        cursor: default;

        &.selected, &.placeholder {
          background-color: lighten($color-tertiary, 20%);
          color: $color-secondary;
        }
      }
    }

    .handler {
      @extend %unselectable;
      @include borderBox();
      cursor: pointer;
      height: 100%;
      width: 100%;
      padding-left: 16px;
      padding-right: 16px;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: $color-secondary;
      @include arial-regular();
      @include simpleAnimate(background-color);
      overflow: hidden;
      @include oneLine();
      border: 1px solid $color-tertiary;
      background-color: lighten($color-tertiary, 20%);
      border-radius: 4px;
      line-height: var(--select-height);
      text-overflow: ellipsis;

      &.selected {
        background-color: $color-contrast;
      }

      &.opened {
        background-color: $color-contrast;
        line-height: var(--select-height);
      }

      &.placeholder {
        color: $color-secondary;
      }
    }

    .add-more-padding {
      padding-right: 28px;
    }
  }
}
