.priority-tag__wrapper {
  &.e-tag {
    @include ib();
    @include arial-bold();
    height: 20px;
    text-align: center;
    line-height: 19px;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 4px;
    letter-spacing: 1px;
    padding: 0 5px;
  }
  &.Default {
    border: 1px solid $color-error;
    color: $color-error;
    background-color: fade($color-error, 0.2);
  }
  &.New {
    border: 1px solid $color-accent;
    color: $color-accent;
    background-color: fade($color-accent, 0.2);
  }
  &.Deleted {
    border: 1px solid $color-success;
    color: $color-success;
    background-color: fade($color-success, 0.2);
  }
  &.Applicant {
    border: 1px solid $color-expiried;
    color: $color-expiried;
    background-color: fade($color-expiried, 0.2);
  }
  &.Blocked {
    border: 1px solid $color-accent;
    color: $color-accent;
  }
}
