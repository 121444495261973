@mixin borderBox() {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@mixin zeroFont() {
  font-size: 0.0001em;
}

@mixin absoluteCenter() {
  position: absolute;
  left: 50%;
  top: 50%;
  @include translate(-50%, -50%)
}

@mixin absoluteCenterHorizontal() {
  position: absolute;
  left: 50%;
  @include translate(-50%, 0)
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}

@mixin tableBlock() {
  display: table;
  width: 100%;
  height: 100%;
}

@mixin tableCell() {
  height: 100%;
  width: 100%;
  vertical-align: middle;
  display: table-cell;
}

@mixin clearLink() {
  text-decoration: none !important;
  outline: none !important;
}

@mixin normalLine() {
  white-space: normal;
}

@mixin flex() {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flexJustify($justifyStyle) {
  -webkit-justify-content: $justifyStyle;
  justify-content: $justifyStyle;
}

@mixin flexDirection($direction) {
  -ms-flex-direction: $direction;
  -webkit-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flexWrap($wrap) {
  -ms-flex-wrap: $wrap;
  -webkit-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flexOptions($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}

@mixin borderRadiusLeft($radius) {
  -webkit-border-top-left-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
};

@mixin borderRadiusRight($radius) {
  -webkit-border-top-right-radius: $radius;
  -webkit-border-bottom-right-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
};

@mixin transform($css)  {
  -webkit-transform: $css;
  -ms-transform: $css;
  -o-transform: $css;
  transform: $css;
};

@mixin transition($css, $time, $easing, $delay) {
  transition: $css $time $easing $delay;
}

@mixin sidebarAnimate($css) {
  @include transition($css, .2s, ease-out, 0s);
}

@mixin simpleAnimate($css) {
  @include transition($css, .4s, ease-out, 0s);
}

@mixin simpleAnimateZeroPointTwoSecs($css) {
  @include transition($css, .2s, ease-out, 0s);
}

@mixin fullSize() {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

@mixin borderRadiusTop($radius) {
  -webkit-border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-right-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin ibt() {
  display: inline-block;
  vertical-align: top;
}

@mixin borderReset() {
  border: 0;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
  transition: none;
  outline: none;
}

@mixin borderRadiusTopLeft($radius) {
  -webkit-border-top-left-radius: $radius;
  -moz-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
}

@mixin oneLine() {
  white-space: nowrap;
}

@mixin absoluteCenterVertical() {
  position: absolute;
  top: 50%;
  @include translate(0, -50%);
}

@mixin ibt() {
  display: inline-block;
  vertical-align: top;
}

@mixin borderRadiusBottomLeft($radius) {
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin borderRadiusBottom($radius) {
  -webkit-border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  -moz-border-bottom-right-radius: $radius;
  -moz-border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin pseudoBlock() {
  position: absolute;
  display: block;
  content: "";
}

@mixin flexAlignItems($alignStyle) {
  -ms-flex-align: $alignStyle;
  -webkit-align-items: $alignStyle;
  align-items: $alignStyle;
}

@mixin ib() {
  display: inline-block;
  vertical-align: middle;
}

%unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
