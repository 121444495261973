@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.Tab__wrapper {
    overflow-y: hidden;
    &.pre_defined_scrollbar {
        &::-webkit-scrollbar-track {
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
            background: #90CAF9;
            border: 2px solid #90CAF9;
            border-radius: 2px;
        }
    
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
    }

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 40px;

    .TabElement {
        height: 40px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background: #DADEE0;
        border: 1px solid #D4D4D8;
        box-sizing: border-box;
        border-radius: 4px 4px 0px 0px;
        border-bottom: none;
        margin-right: 1px;
        cursor: pointer;
        transition: all 0.07s ease-in-out;

        &.active {
            background: #FFFFFF;
            height: 39px;

            .close_tab {
                svg {
                    path {
                        fill: #8E8E93;
                    }
                }
            }
        }

        &.tab_with_close_button {
            min-width: 85px;
            justify-content: flex-start;
            position: relative;

            .tab_content {
                padding: 0 0 0 12px;
                width: 70%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &::selection {
                    background: transparent;
                }
            }

            .close_tab {
                position: absolute;
                top: 11px;
                right: 8px;
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: 8px;
                    height: 8px;
                    transition: all 0.07s ease-in-out;
                }

                &:hover {
                    svg {
                        path {
                          fill: $color-accent;
                        }
                    }
                }
            }
        }

        .tab_content {
            @include roboto-regular();
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.4px;
            padding: 0 7px;
            white-space: nowrap;
        }
    }
}