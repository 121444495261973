.calendar__wrapper {
  .e-calendar {
    display: block;
    position: relative;
    width: 296px;
  }

  .calendar-head {
    display: block;
    padding-left: 16px;
    height: 32px;
    line-height: 32px;
  }

  .week {
    @include flex();
    @include flexJustify(center);
  }

  .days {
    @include flex();
    @include flexJustify(center);
    @include flexWrap(wrap);
  }

  .weekday, .day {
    @include borderBox();
    display: block;
    text-align: center;
    width: 40px;
    height: 32px;
    position: relative;
  }

  .weekday {
    @include arial-bold();
    color: $color-icony;
    font-size: 8px;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 32px;
  }

  .day {
    @include arial-regular();
    .number {
      @include borderBox();
      position: absolute;
      display: block;
      height: 20px;
      width: 24px;
      line-height: 20px;
      left: 8px;
      top:6px;
      border: 1px solid transparent;
      border-radius: 4px;
      color: $color-icony;
      font-size: 12px;
      letter-spacing: 0.4px;
      cursor: pointer;
    }
    &.green {
      .number {
        color: $color-success;
        border-color: $color-success;
      }
    }
    &.red {
      .number {
        color: $color-error;
        border-color: $color-error;
      }
    }
    &.yellow {
      .number {
        color: $color-warning;
        border-color: $color-warning;
      }
    }
    &.blue {
      .number {
        color: $color-contrast;
        border-color: $color-accent;
        background-color: $color-accent;
      }
    }
    &.inactive {
      opacity: 0;
    }
  }
}