@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;


.confirmModal {
  padding: 20px;
  @include arial-regular();
  width: 400px;

  @media (max-width: $mobile) {
    max-width: 100%;
  }
  &__content {
    width: 100%;
  }
  &__field {
    @include flex();
    @include flexAlignItems(center);
    width: 100%;
    &:last-child {
      @include flexJustify(flex-end);
      margin-top: 100px;
    }
  }
  &__success-button {
    margin-right: 10px;
    padding: 0 20px;
  }

  &__cancel-button {
    padding: 0 20px;
  }
}
