.shipCheckbox__wrapper {
  &.e-ship-checkbox {
    display: inline-block;
    height: 144px;
    width: 160px;
    border: 1px solid $color-tertiary;
    border-radius: 4px;
    padding: 48px 16px 0;
    position: relative;
    cursor: pointer;
    @include simpleAnimate(all);
    &.checked {
      border-color: $color-accent;
      .icon {
        opacity: 1;
      }
      .title, .price {
        color: $color-accent;
      }
      .text {
        color: $color-main;
      }
    }

    .icon {
      position: absolute;
      left: 14px;
      top: 14px;
      filter: invert(68%) sepia(10%) saturate(2156%) hue-rotate(76deg) brightness(91%) contrast(86%);
      opacity: 0;
    }

    .title, .price {
      @include arial-bold();
      color: $color-secondary;
      font-size: 14px;
      letter-spacing: 0.2px;
      margin-bottom: 8px;
    }

    .text {
      @include arial-regular();
      color: $color-icony;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
    }
  }
}
