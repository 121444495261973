@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/breakpoint' as *;


.rejectSubStatus {
  padding: 0 25px 25px 25px;
  font-family: arial, sans-serif;
  @media (max-width: $mobile) {
    width: 300px;
    padding: 12px;
  }

  &__status {
    padding-bottom: 20px;
    font-size: 18px
  }

  &__status span {
    font-size: 16px;
  }

  &__status span,
  &__id {
    color: $color-secondary;
  }

  &__id-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    .e-select, .textArea__wrapper {
      margin-bottom: 10px;
    }

    .e-button__wrapper {
      align-self: flex-end;
      justify-self: right;
    }

    @media (max-width: $tablet) {
      .textArea__wrapper {
        --textArea-width: 350px !important;
      }
    }

    @media (max-width: $mobile) {
      .select__wrapper {
        --select-width: 100% !important;
      }
      .textArea__wrapper {
        --textArea-width: 100% !important;
      }
    }
  }
}




