.flag__wrapper {
  .e-flag {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    z-index: 2;
    overflow: hidden;
  }
  .e-flag .flag {
    display: block;
    height: 16px;
    width: 16px;
    //background-image: url("");
    background-repeat: no-repeat;
    position: absolute;
  }
  .e-flag.v-size-0 {
    width: 12px;
    height: 12px;
  }
  .e-flag.v-size-0 .flag {
    left: -2px;
    top: -1px;
  }
  .e-flag.v-size-1 {
    width: 16px;
    height: 16px;
  }
  .e-flag.v-size-1 .flag {
    left: 0;
    top: 1px;
    transform: scale(1.5);
  }
}