@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;
.comments-and-sms_wrapper {
    min-height: 150px;

    .tabs-container {
        background: $color-contrast;
        @include flex();
        @include flexJustify(flex-start);
        width: 100%;
        
        border: 1px solid #D4D4D8;
        border-style: none none solid none;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    
        .currentProducts__tab {
          height: 36px;
          line-height: 34px;
          display: block;
          width: auto;
          margin: 0;
          text-align: center;
          cursor: pointer;
          @include simpleAnimate(all);
    
          padding: 0 26px;
    
          &:first-child {
            border-right: 1px solid $color-border;
          }
    
          &:hover,
          &.active {
            &:after {
              width: 100%;
              opacity: 1;
            }
          }
    
          &:after {
            content: "";
            display: block;
            height: 1px;
            width: 0;
            background: $color-accent;
            border: 1px solid $color-accent;
            opacity: 0;
            margin: auto;
            transition: opacity, width ease-in-out 0.2s;
            border-style: none none solid none;
            margin-top: 1px;
          }
    
          &.active {
            border-bottom: 1px solid $color-accent;
    
            .tabs__text {
              color: $color-accent;
            }
          }
    
          .tabs__text {
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 18px;
            @include roboto-regular();
            font-weight: 500;
            @include ib();
            position: relative;
            color: #7C7C7C;
            @include simpleAnimate(color);
          }
        }
      }
}


.grid-sms-table {
  .grid_header_cell:nth-child(1) {
      padding-left: 19px;
  }

  .grid_row {
      .grid_cell:nth-child(1) {
          padding-left: 19px;
      }

      .grid_cell {
          &.dots_in_text {
              display: flex;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              text-overflow: ellipsis;
              overflow: hidden;
              cursor: help !important;
          }

          .smsStatus {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 2px 4px;
              border: 0.6px solid $color-border;
              box-sizing: border-box;
              border-radius: 3px;
              background: $color-surface;
              color: $color-grey-additional-2;
              font-size: 10px;
              line-height: 12px;
          }

          transition: all 0.1s ease-in-out;
          min-height: 48px;
      }
  }
}