.collapse-table__wrapper {
  .collapse-head-handler {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .collapse-body {
    border-top: 1px solid fade($color-tertiary, 0.6);
    display: block;
  }

  .collapse-head {
    display: block;
    height: 55px;
    line-height: 48px;
    cursor: pointer;
    position: relative;
  }

  .collapse-head-name {
    @include arial-bold();
    @include borderBox();
    color: $color-secondary;
    font-size: 16px;
    letter-spacing: 0.4px;
    padding-left: 40px;
    padding-right: 112px;
    padding-top: 3px;
    @include simpleAnimate(color);
  }

  .e-collapse {
    display: block;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.1), 0 5px 5px 2px rgba(0,0,0,0.05);
    @include simpleAnimate(all);
    &.active {
      box-shadow: 0 14px 35px rgba(0, 0, 0, 0.14), 0 0 0 rgba(0, 0, 0, 0.1);
      @include simpleAnimate(all);
      .collapse-head-icon {
        &:before, &:after {
          background-color: $color-accent;
        }
        &:after {
          opacity: 0;
        }
      }
      .collapse-head-name {
        color: $color-accent;
      }
    }
  }

  .collapse-head-icon {
    width: 13px;
    height: 15px;
    position: absolute;
    left: 18px;
    top: 20px;
    &:before, &:after {
      @include pseudoBlock();
      background-color: $color-icony;
      @include simpleAnimate(background-color);
    }
    &:before {
      left: 0;
      right: 0;
      height: 2px;
      top: 6px;
    }
    &:after {
      top: 0;
      bottom: 0;
      width: 2px;
      left: 5px;
    }
  }
}