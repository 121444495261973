@use '@styles/core/mixins/color' as *;

.removeItemButton {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: $color-error;
      }
    }
  }
}
