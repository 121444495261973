@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.recalls-page {
    background: #F5F7FB;
    height: 100vh;

    padding: 20px 20px 20px 104px;

    .recalls-header {
        padding: 20px 0 20px 20px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #3A3A3A;
    }

    @media (max-width: 640px) {
        padding: 10px 5px 20px 79px;
    }
    @media (max-width: $mobile) {
        padding: 0 0 60px 0;
    }
}
.recalls-table {

    &-container {
        position: relative;
        width: 100%;
        min-height: 144px;
        background: #FFFFFF;
        border: 1px solid #D5D6D8;
        border-radius: 4px;

        .recalls-header {
            padding: 20px 0 20px 20px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #3A3A3A;
        }
    }

    &_content {
        overflow-y: auto;
    }
    &-cells {
        min-height: 48px !important;
        padding: 8px;

        font-family: Roboto !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 12px !important;
        line-height: 14px !important;
        letter-spacing: 0.4px !important;
        color: #3A3A3A !important;

        &:first-child {
            padding-left: 19px;
            @media (max-width: 640px) {
                padding-left: 8px;
            }
        }
    }

    .grid_header_cell {
        padding: 6px 8px;
        font-size: 10px !important;
        line-height: 12px !important;
        letter-spacing: 1.5px !important;
        height: unset !important;

        &:first-child {
            padding-left: 19px;
            @media (max-width: 640px) {
                padding-left: 8px;
            }
        }
        &:nth-child(4) {
            padding: 6px 28px 6px 8px;
        }
    }

    .recall-item {
        &-country {
            display: flex;
            align-items: center;
            padding: 8px 28px 8px 16px;

            &-name {
                font-family: Roboto !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 12px !important;
                line-height: 14px !important;
                letter-spacing: 0.4px !important;
                color: #3A3A3A !important;
            }
            &-flag {
                border-radius: 50%;
                margin-right: 8px;
            }
        }
        &-action {
            display: flex;
            position: relative;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            font-family: Roboto !important;
            font-style: normal !important;
            font-weight: bold !important;
            font-size: 20px !important;
            line-height: 22px !important;
            letter-spacing: 0.4px !important;
            color: #B8C1CC;

            &-text {
                transform: rotate(90deg) translateY(-30%);
            }
        }
        &-action:hover {
            color: #0060FF;
        }
    }
}

.mobile-recalls-table {
    display: flex;
    overflow: scroll;

    & > div {
        border-right: 1px solid #D5D6D8;
    }
    .header-col {
        max-width: 144px;

        & > div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 24px;
            padding-right: 16px;

            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 1.5px;
            color: #3A3A3A;

            background: #F2F3F5;
            text-transform: uppercase;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
    .recall-col > div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 144px;

        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
    }
    .row {
        &-id {
            height: 40px;
        }
        &-partner {
            height: 40px;
        }
        &-id-by-partner {
            height: 40px;
        }
        &-country {
            height: 52px;
        }
        &-customer-name {
            height: 70px;
        }
        &-action {
            height: 70px;

            .action-button {
                padding: 8px 16px;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                letter-spacing: 1.25px;
                border: 1px solid #D5D6D8;
                border-radius: 4px;
                background: #fff;
            }
        }
    }

    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background: #90CAF9;
        border: 2px solid #90CAF9;
        border-radius: 2px;
    }

    &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    .actions-modal {
        visibility: hidden;
        animation: fade-out 0.2s ease-out;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        background: rgba(33, 33, 33, 0.75);
        z-index: 111;

        .actions-container {
            position: absolute;
            bottom: 20px;
            left: 50%;
            width: 95%;
            transform: translateX(-50%);
            padding: 0 8px;
            display: flex;
            flex-direction: column;

            .actions-list {
                display: flex;
                flex-direction: column;
                border-radius: 14px;
                overflow: hidden;
                margin-bottom: 8px;

                .action-button {
                    padding: 16px;
                    border: none;
                    background: #FFFFFF;

                    text-align: center;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    line-height: 24px;
                    text-align: center;
                    letter-spacing: 0.15px;
                    color: #0060FF;

                    &:first-child {
                        border-bottom: 1px solid #D5D6D8;
                    }
                }
            }

            .cancel-button {
                border-radius: 14px;
                padding: 16px;
                background: #FFFFFF;

                text-align: center;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: #0060FF;
            }
        }
    }
    .visible {
        animation: fade-in 0.2s ease-in;
        visibility: visible;
    }
    @keyframes fade-in {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes fade-out {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}

.no-orders-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    width: 100%;
    padding: 15px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #3A3A3A;
}

.ProductNavBar {
    left: unset;
    right: 10%;
    top: 70%;

    background: #FFFFFF;
    border: 1px solid #F5F7FB;
    box-sizing: border-box;
    box-shadow: 3px 15px 25px rgba(0, 0, 0, 0.17);
    border-radius: 4px;

    .item__bar {
        border-radius: 3px;
        width: 100%;
        padding: 0 16px;
    }
}

.dropdown.icon {
    &::before {
        content: '';
    }
    margin: 0;
}

@media (max-width: 1100px) {
    .recalls {
        &-header {
            position: sticky;
            left: 0;
        }
        &-table {
            width: 1500px;
            &-container {
                overflow-x: auto;
            }
        }
    }
}



