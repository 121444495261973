@use '@styles/core/breakpoint' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.collapse-summary__wrapper {
  font-family: Roboto, sans-serif;

  .smooth-collapse-summary {
    display: block;
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
    z-index: 75;
    @include borderRadiusBottom(4px);
    background-color: $color-contrast;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.1), 0 5px 5px 2px rgba(0,0,0,0.05);
  }

  .summary-head {
    display: block;
    height: 48px;
    line-height: 48px;
    position: relative;
    user-select: none;
  }

  .summary-body {
    display: block;
    padding-bottom: 8px;
  }

  .summary-table {
    display: block;
    padding: 8px;
    border-bottom: 1px solid fade($color-tertiary, 0.6);

    .no-items {
      font-size: 14px;
      text-align: center;
      width: 100%;
      font-weight: bold;
    }

    @media (max-width: $tablet) {
      max-height: 50%;
      overflow: auto;
    }
  }

  .e-summary {
    display: block;
    position: relative;
    margin: 0;
    &.active {
      .summary-head {
        background: #fff;
      }
      .summary-head-name {
        color: #3A3A3A;
      }
      .summary-head-value {
        color: $color-accent;
      }
      .summary-head-icon {
        &:before, &:after {
          background-color: $color-accent;
        }
        &:after {
          opacity: 0;
        }
      }
    }
  }

  .summary-head-name {
    font-weight: 500;
    @include borderBox();
    color: #7C7C7C;
    font-size: 16px;
    letter-spacing: 0.4px;
    padding-left: 8px;
    @include simpleAnimate(color);
  }

  .summary-head-value {
    font-weight: 500;
    display: inline-block;
    color: #3A3A3A;
    font-size: 16px;
    letter-spacing: 0.4px;
    @include absoluteCenterVertical();
    right: 16px;
    @include simpleAnimate(color);
  }

  .summary-head-icon {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 12px;
    top: 15px;
    background: url('./cross-rounded.svg') no-repeat;
    &:before, &:after {
      @include pseudoBlock();
      background-color: $color-icony;
      @include simpleAnimate(background-color);
    }
  }

  .table-row {
    @include flex();
    @include flexJustify(space-between);
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .name {
      display: block;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $color-main;
      width: 54%;
      max-width: 54%;
    }
    .count {
      display: block;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $color-icony;
      width: 18%;
      max-width: 18%;
      text-align: right;
    }
    .value {
      display: block;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $color-main;
      width: 28%;
      max-width: 28%;
      text-align: right;
    }
  }

  .summary-row {
    @include borderBox();
    @include flex();
    @include flexJustify(space-between);
    margin-top: 12px;
    padding: 0 8px;
    .item {
      display: block;
      font-weight: bold;
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $color-main;
    }
  }
}
