.InputCharacterCounter {
  position: absolute;
  bottom: 2px;
  right: 4px;
  z-index: 2;

  font-family: arial, sans-serif;
  font-weight: normal;
  color: rgba(#6B6C6F, 0.6);
  font-size: 10px;

  pointer-events: none;
  &.overflow {
    color: #FF3B30;
  }
}