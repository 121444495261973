@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/breakpoint' as *;


.snackbar__wrapper {
  &.visible {
    animation: fade-in .3s;
    visibility: visible;
  }
  $leftPanelWidth: 63px;
  $rightPanelWidth: 311px;

  animation: fade-out .3s;
  visibility: hidden;
  padding: 8px;
  position: fixed;
  bottom: 5%;  
  left: $leftPanelWidth;
  right: $rightPanelWidth;
  max-width: calc(90% - #{$leftPanelWidth} - #{$rightPanelWidth});
  margin: auto;
  background: rgba(33, 33, 33, 0.75);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12), 0px 16px 16px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
  z-index: 999999;

  font-family: Roboto;
  font-style: normal;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: $tablet) {
    $rightPanelWidth: 0px;

    left: $leftPanelWidth;
    right: $rightPanelWidth;
    max-width: calc(90% - #{$leftPanelWidth} - #{$rightPanelWidth});
  }

  @media (max-width: $mobile) {
    $leftPanelWidth: 0px;
    $bottomPanelHeight: 56px;

    left: $leftPanelWidth;
    right: $rightPanelWidth;
    max-width: calc(90% - #{$leftPanelWidth} - #{$rightPanelWidth});
    bottom: calc(#{$bottomPanelHeight} + 1%);
  }

  .snackbar__content {
    display: flex;
    justify-content: space-between;
    min-height: 34px;
    
    @media (max-width: $mobile) {
      flex-wrap: wrap;
    }
    .snackbar__message {
      padding: 8px;
      margin: 0;
    }
    .snackbar__action {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-left: auto;
      padding: 8px;

      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1.25px;
      white-space: nowrap;

      cursor: pointer;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
      visibility: visible;
    }
    to {
      opacity: 0;
      visibility: hidden;
    }
  }
}

