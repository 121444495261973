@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.OrderSearch {
  width: 100%;
  margin: 0 0 30px;
  padding: 20px 15px;
  @include borderBox();
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  background-color: $color-contrast;
  border-radius: 4px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1),
  0 5px 5px 2px rgba(0, 0, 0, 0.05);

  &__inputs {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: $orderBlockBreakpoint) {
      flex-direction: column;
    }
  }

  &__actions {
    margin-top: 20px;
    display: flex;
    width: 100%;

    .e-button__wrapper {
      width: 192px;

      @media (max-width: $orderBlockBreakpoint) {
        width: calc(50% - 8px);
      }
      &:not(:last-child) {
        margin-right: 15px;
      }

      .e-button {
        width: 100%;
      }
    }
  }

  .textInput__wrapper.e-text-input.orderSearch__input {
    width: calc(33.333% - 10px);

    @media (max-width: $orderBlockBreakpoint) {
      width: 100%;
    }

    &:not(:last-child) {
      @media (max-width: $orderBlockBreakpoint) {
        margin-bottom: 10px;
      }
    }
  }
}
