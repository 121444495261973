@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;


.call-widget__wrapper {
  &.b-call-widget {
    @include borderBox();
    position: relative;
    height: 100vh;
    @media (max-width: $mobile) {
      height: calc(var(--vh, 1vh) * 100);
    }
    @supports (-webkit-overflow-scrolling: touch) {
      /* CSS specific to iOS devices */
      height: calc(var(--vh, 1vh) * 100 - 56px);
    }
    padding: 0;
    display: flex;
    flex-direction: column;
    background: $color-surface;
  }

  .b-call-widget__block {
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;

    &__padding {
      padding: 0 16px;
      flex: 0;
      display: flex;
      flex-direction: column;
    }

    @media (max-width: $mobile) {
      margin-bottom: 56px;
    }
  }

  .b-call-widget__scripts {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .call-time {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    @include borderBox();
    padding: 0 30px;

    .time {
      @include arial-regular();
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: $color-main;
      text-align: center;
    }

    .scale {
      display: block;
      overflow: hidden;
      margin-top: 28px;
      height: 3px;
      background-color: fade($color-tertiary, 0.4);
      position: relative;
    }

    .scale-handler {
      display: block;
      height: 100%;
      width: 30%;
      position: absolute;
      top: 0;
      background-color: $color-accent;
      animation: scale 5s linear infinite;
      left: -30%;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 2.5s;
      }
    }
  }

  .call-time-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 10px;

    @media (max-width: $mobile) {
      margin-bottom: 20px;
    }

    @include flex();
    @include flexJustify(space-between);
    @include flexAlignItems(center);
  }

  .call-time-button {
    display: inline-block;
    height: 24px;
    width: 24px;
    cursor: pointer;
    position: relative;
  }

  .problemSvg {
    &.active {
      circle {
        stroke: $color-error;
      }

      path {
        fill: $color-error;
      }
    }
  }

  .call-time-button {
    width: 24px;
    height: 24px;
    display: block;
    background-size: cover;

    &.call-problem-button {
      width: 44px;
      height: 44px;
    }

    svg {
      circle {
        stroke: $color-icony;
      }

      path {
        fill: $color-icony;
      }
    }

    &.disabled {
      opacity: 0.3;

      svg {
        path {
          fill: $color-icony;
        }
      }

      cursor: default;
    }

    &.muted {
      opacity: 1;

      svg {
        path {
          fill: $color-accent;
        }
      }
    }
  }

  .call-info {
    text-align: center;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }

  .call-info-name {
    @include arial-regular();
    color: #7C7C7C;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .call-info-inputs {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 16px;

    .call-input {
      margin: 0 8px;

      input {
        padding-left: 8px;
      }
    }
  }

  .call-info-number {
    @include arial-regular();
    @include ib();
    color: $color-secondary;
    font-size: 16px;
    letter-spacing: 0.4px;
    margin-top: -4px;
  }

  .call-phone-actions {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    flex: 1;

    .call-action-button-wrapper {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }

  .call-footer {
    display: block;
    align-self: flex-end;
    justify-self: flex-end;
    width: 100%;

    .change-order-buttons {
      @media (max-height: $changeOrderButtonHeightBreakpoint) {
        display: none;
      }
    }

    .show-order-buttons-in-modal {
      display: none;
      @media (max-height: $changeOrderButtonHeightBreakpoint) {
        display: block;
      }
    }
  }

  .call-button-wrap {
    @include flex();
    @include flexJustify(space-between);
    @include flexAlignItems(center);
    position: relative;
    text-align: center;
    padding: 0 12px;
  }

  .call-button {
    @include ib();
    @include simpleAnimate(all);
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;

    .icon {
      width: 24px;
      height: 24px;
      display: block;
      @include absoluteCenter();
      fill: $color-icony;
      @include simpleAnimate(fill);
    }

    &.active, &:hover {
      background-color: $color-contrast;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 14px 35px 6px rgba(0, 0, 0, 0.14);

      .icon {
        fill: $color-accent;
      }
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include flex();
    @include flexWrap(wrap);
    @include flexJustify(space-between);

    .button {
      margin-top: 16px;
    }

    .approve-btn-wrapper {
      width: 100%;
    }

    .change-queue-btn-wrapper {
      width: 100%;
    }
  }

  .call-widget__center {
    flex: 1;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @keyframes scale {
    from {
      left: -30%;
    }
    to {
      left: 170%;
    }
  }
}

.call-button-action {
  position: relative;
    .calls_counter {
      position: absolute;
      top: 4px;
      right: 12px;
      pointer-events: none;
    }
}