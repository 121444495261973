@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;

.FAQ {
  @media (max-width: 1200px) {
    display: none;
  }

  background: #FFFFFF;
  border: 1px solid #D4D4D8;
  border-radius: 4px;
  padding: 20px 52px;

  &__header {
    @include roboto-regular;
    color: $color-grey-additional-2;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__spinner {
    display: flex;
    width: 100%;
    height: 100px;
    @include roboto-regular;
  }

  .no-result {
    @include roboto-regular;
    color: $color-grey-additional-2;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
  }
}
