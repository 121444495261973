@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;

.CallPanelCommunicationQualityScreen {
  width: 100%;
  height: 100%;
  background: $color-bg-grey;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  @media (max-width: $mobile) {
    visibility: visible;
    display: flex;
    flex-direction: column;
    transform: translateX(100%);

    &.visible {
      transform: translateX(0);;
    }
  }

  .header {
    flex-shrink: 0;
    background-color: $color-contrast;
    border-bottom: 1px solid $color-border;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #212121;
    height: 56px;
    display: flex;

    .arrow-back {
      background-size: 24px 24px;
      background: url('../../../sidebar/includes/icons/arrorw-left.svg') no-repeat center;
      width: 56px;
      height: 56px;
      transform: rotate(180deg);
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
    }
  }

  &__desc {
    margin: 10px 0 5px;

    @include arial-regular();
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }

  &__block {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: calc(100% - 111px);
  }

  &__items {
    flex-grow: 1;
    padding-top: 4px;
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    box-sizing: border-box;
  }

  &__item {
    label {
      user-select: none;
      display: flex;
      align-items: center;
      min-height: 50px;
      padding: 0 16px;

      transition: all 0.3s ease;
      background: transparent;
      cursor: pointer;

      &:hover {
        background: rgba(35, 35, 35, 0.05);
      }

      .cq_checkbox {
        input {
          width: 20px;
          height: 20px;
        }
        margin-right: 18px;
      }

      .cq_name {
        @include arial-bold();
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  &__bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0 10px;
    flex-wrap: wrap;

    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(5px);

    .e-button__wrapper + .e-button__wrapper {
      margin-left: 7px;
    }
  }
}