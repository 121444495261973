@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.link__jira {
    @include roboto-regular();
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;

    text-decoration-line: underline;
    color: #3366BB;

    &:visited {
        color: #3366BB;
    }
}

.thumb-vertical {
    background: $color-primary-light-blue;
    border-radius: 2px;
}

.customer_order_controls_wrapper {
    width: 100%;
    padding: 20px 52px 28px 52px;

    .headline {
        @include roboto-regular;
        letter-spacing: 0.15px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;

        color: $color-grey-additional-2;

        margin-bottom: 28px;
    }
}

.order_information-grid-table-appeals {
    .grid_header_cell:nth-child(1) {
        padding-left: 19px;
    }

    .langIcon.round {
        border-radius: 50%;
        width: 16px;
        min-width: 16px;
        height: 16px;
    }

    .grid_row {
        .grid_cell:nth-child(1) {
            padding-left: 19px;
        }

        .grid_cell {
            transition: all 0.1s ease-in-out;
            min-height: 48px;
        }

        &:hover {
            .grid_cell {
                background: rgba(22, 101, 216, 0.1);
                cursor: pointer;
            }
        }
    }
}

.result-alert {
    width: 100%;
    flex-wrap: nowrap;
    min-height: 48px;
    transition: all 0.1s ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include roboto-regular;
    color: $color-grey-additional;
    opacity: 0.8;
    margin-top: 13px;

    .message_header {
        font-size: 24px;
        line-height: 28px;

    }

    .message_content {
        margin-top: 13px;

        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.5px;
    }

    &:hover {
        background: transparent;
        cursor: initial;
    }
}

.appeal_view__wrapper {
    position: relative;
    width: 100%;

    &-content {
        position: absolute;
        background: #fff;
        width: 0px;
        display: block;
        min-height: 669px;
        max-height: 669px;
        height: 669px;
        z-index: 3;
        transition: all 0.2s ease-in-out;

        &.active {
            width: 100%;
        }
    }
}


.entering {
    opacity: 0;
    transition: all 0.2s 0.2s ease-in-out;
}

.entered {
    opacity: 1;
    transition: all 0.2s 0.2s ease-in-out;
}

.exiting {
    transition-delay: 0s;
    opacity: 0;
}

.exited {
    display: none;
}