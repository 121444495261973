@use '@styles/core/breakpoint' as *;

.FloatingCallButton {
  visibility: visible;
  opacity: 1;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }

  transition: all 0.2s ease-in;
  position: fixed;
  right: 20px;
  bottom: 20px;
  transform: translate3d(0, 0, 0);
  z-index: 9999;

  .callButtonSvg {
    width: 80px;
    height: 80px;
    .callButtonSvg__phone__simple {
      display: block;
      fill: #007AFF;
    }
  }

  display: none;
  @media (max-width: $tablet) {
    display: block;
  }

  @media (max-width: $mobile) {
    display: none !important;
  }
}
