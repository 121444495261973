@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.shipping-datepicker__wrapper {
  @include flex();
  @include flexAlignItems(center);
  @include flexJustify(center);
  color: #8E8E93;
  background: $color-contrast;
  border-radius: 4px;
  border: 1px solid $color-tertiary;
  height: 40px;

  .shipping-datepicker__content {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(space-between);
    padding: 0 7px;

    .calendarIcon {
      width: 25px;
      height: 25px;
      padding-right: 7px;
      path {
        fill: #8E8E93;
      }
    }

    span {
      font-size: 14px;
      font-family: arial, sans-serif;
    }
  }
}
