@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;


$orderListBreakPoint: 1493px;

.CustomerServicePage {
  @include borderBox();
  height: 100vh;
  overflow-y: hidden;
  &__wrapper {
    @include borderBox();
    min-height: 100%;
    height: 100%;
    background-color: $color-bg;
    padding: 24px 44px 72px 104px;
    width: calc(100% - 312px);
    overflow-y: auto;

    @media (max-width: $tablet) {
      padding-right: 12px;
      padding-left: 76px;
      width: 100%;
    }

    @media (max-width: $mobile) {
      padding-left: 12px;
    }
  }

  &__pageTitle {
    @include arial-bold();
    color: $color-secondary;
    font-size: 36px;
    letter-spacing: 0.2px;
    line-height: 40px;
    margin-bottom: 24px;

    @media (max-width: 540px) {
      font-size: 22px;
      white-space: nowrap;
    }
  }

  &__content {
    @include borderBox();
    @include zeroFont();
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1),
    0 5px 5px 2px rgba(0, 0, 0, 0.05);
    padding: 0 0 10px;
    margin: 0;
  }
}

.CustomerServicePageTable {
  font-family: arial, sans-serif;
  font-weight: normal;
  width: 100%;
  &__head {
    @include borderBox();
    padding: 20px 20px 10px;
    border-bottom: 1px solid rgba(#000, 0.2);
    display: flex;
    align-items: center;
    width: 100%;

    list-style: none;
    flex-shrink: 0;
    font-size: 14px;
    color: #6B6C6F;

    @media (max-width: $orderListBreakPoint) {
      display: none;
    }
  }
  &__body {
    padding: 0;
    margin: 0;
    color: #333;
  }
  &__empty {
    padding: 20px 0;
    text-align: center;
    font-size: 14px;
  }
  &__col {
    flex-shrink: 0;
    padding-right: 10px;
    &.col-id {
      width: 10%;
      min-width: 80px;

      @media (max-width: $orderListBreakPoint) {
        order: 1;
        width: 50%;
      }
      .col-item {
        @media (max-width: $orderListBreakPoint) {
          display: flex;
          align-items: flex-end;
        }

        &:not(:first-of-type) {
          margin-top: 5px;
          font-family: arial, sans-serif;
          font-size: 10px;
          color: #8E8E93;

          @media (max-width: $orderListBreakPoint) {
            font-size: 14px;
            color: $color-main;
          }
        }

        &.order-status {
          margin-top: 10px;
          font-size: 12px;
          color: #232323;
        }
      }
      .mobile-label {
        margin-bottom: 1px;
        margin-right: 4px;
      }
    }
    &.col-phones {
      width: 15%;
      min-width: 150px;

      .phone-value {
        word-break: break-all;
      }

      @media (max-width: $orderListBreakPoint) {
        order: 3;
        display: flex;
        width: 100%;
        margin-top: 10px;
      }
      .col-item {
        @media (max-width: $orderListBreakPoint) {
          width: 50%;
        }

        &:not(:first-of-type) {
          margin-top: 5px;
        }
      }
    }
    &.col-name {
      width: 15%;
      word-break: break-all;
      min-width: 110px;

      @media (max-width: $orderListBreakPoint) {
        order: 4;
        width: 100%;
        margin-top: 10px;
      }
    }
    &.col-products {
      width: 220px;

      @media (max-width: $orderListBreakPoint) {
        order: 5;
        width: 100%;
        margin-top: 10px;
      }
    }
    &.col-shipping {
      width: 210px;

      @media (max-width: $orderListBreakPoint) {
        order: 6;
        width: 100%;
        margin-top: 10px;
      }

      > .col-item:nth-of-type(2) {
        margin-top: 5px;
        @media (max-width: $orderListBreakPoint) {
          margin-top: 10px;
        }
      }

      .col-row {
        display: flex;
        margin-top: 5px;
        @media (max-width: $orderListBreakPoint) {
          margin-top: 10px;
        }
        .col-item {
          &:nth-child(1),
          &:nth-child(2) {
            width: 25%;
          }
          &:nth-child(3) {
            width: 50%;
          }
        }
      }
    }
    &.col-price {
      width: 90px;

      @media (max-width: $orderListBreakPoint) {
        order: 2;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        width: 50%;
      }
      .mobile-label {
        margin-bottom: 1px;
        margin-right: 4px;
      }
    }
    &.col-actions {
      flex-grow: 1;
      display: flex;

      @media (max-width: $orderListBreakPoint) {
        order: 7;
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  &__item {
    @include borderBox();
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;

    list-style: none;
    flex-shrink: 0;
    font-size: 14px;

    @media (max-width: $orderListBreakPoint) {
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 20px 15px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba(#000, 0.1);
    }

    span {
      display: block;
      font-family: arial, sans-serif;
      font-size: 10px;
      color: #8E8E93;

    }
    .mobile-label {
      display: none;

      @media (max-width: $orderListBreakPoint) {
        display: block;
        font-family: arial, sans-serif;
        font-size: 10px;
        color: #8E8E93;
      }
    }
  }
  &__btn {
    @include borderBox();
    flex-shrink: 0;
    margin-left: auto;
    font-size: 14px;
    padding: 10px 30px;
  }
  .spinner__wrapper.e-spinner {
    display: block;
    margin: 30px auto 10px;
  }
  .product-list {
    margin: 0;
    padding: 0 5px 0 0;

    list-style: none;

    li {
      word-break: break-all;
    }
  }
}