@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.firstStep {
  width: 100%;

  &__content {
    padding: 10px 25px 25px 25px;
    width: 100%;

    @media (max-width: 540px) {
      padding: 10px 20px 20px 20px;

      .e-button__wrapper {
        width: 100%;
      }
    }
  }

  &__block {
    width: 100%;


    @media (min-width: 2560px) {
      width: 50%;
    }

    &:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      @media (min-width: 2560px) {
        justify-content: flex-start;
        flex-wrap: nowrap;
      }
    }

    &.button_block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 50px;

      @media (max-width: 540px) {
        justify-content: flex-start;
        margin-top: 30px;
      }

      @media (min-width: 2560px) {
        margin-top: 35px;
      }

      .button__submit {
        width: 192px;

        @media (max-width: 540px) {
          width: 100% !important;
        }
      }
    }
  }

  &__selected {
    width: 280px !important;
    margin: 15px 15px 0 15px;

    @media (max-width: 540px) {
      width: 100% !important;
      font-size: 12px !important;
    }

    @media (min-width: 2560px) {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
