.textSlider__wrapper {
  .e-slider {
    display: block;
    text-align: left;
    position: relative;
    padding: 16px 0 48px;
    div {
      outline: none !important;
    }
    .dots {
      margin: 0;
      padding: 0;
      height: 3px;
      position: absolute;
      top: -64px;
      left: 0;
      right: 0;
      @include oneLine();
      @include flex();
      @include flexJustify(flex-start);
      li {
        @include ibt();
        display: block;
        padding: 0;
        margin: 0;
        height: 3px;
        width: 50%;
        background-color: fade($color-tertiary, 0.4);
        cursor: pointer;
        overflow: hidden;
        position: relative;
        &.slick-active {
          background-color: $color-accent;
        }
      }
      button {
        opacity: 0;
        height: 3px;
        width: 100%;
      }
    }
  }

  .e-slider-separator {
    display: block;
    height: 1px;
    background-color: $color-tertiary;
    margin-top: 20px;
  }

  .slide {
    padding: 0 16px;
  }

  .slide-title {
    @include arial-bold();
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 16px;
    color: $color-main;
  }

  .slide-text {
    @include arial-regular();
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 18px;
    margin-top: 8px;
    color: $color-main;
  }

  .slick-arrow {
    height: 32px;
    width: 112px;
    border: 1px solid $color-tertiary;
    border-radius: 4px;
    color: $color-secondary;
    @include arial-bold();
    font-size: 12px;
    letter-spacing: 0.4px;
    background-color: transparent;
    position: absolute;
    bottom: -64px;
    &.slick-prev {
      left: 16px;
    }
    &.slick-next {
      right: 16px;
    }
  }
}