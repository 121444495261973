@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/breakpoint' as *;


.modalContainer__wrapper {
  opacity: 0;
  visibility: hidden;
  padding-left: 24px;
  padding-right: 24px;

  @media (max-width: $mobile) {
    padding-left: 6px;
    padding-right: 6px;
  }

  @include simpleAnimate(all);

  .modalContainer__content {
    @media (max-width: $mobile) {
      max-width: 100%;
    }

    display: var(--visibleContent);
    position: relative;
    background: #FFFFFF;
    box-shadow: 0 20px 65px rgba(0, 0, 0, 0.25), 0 1px 0 rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    max-height: 640px;

    .modalContainer__header {
      @include flex();
      @include flexAlignItems(center);
      @include flexJustify(flex-end);
      height: 40px;
      margin-bottom: 15px;
      padding-right: 10px;
      svg {
        cursor: pointer;
      }
    }

    &.entering {
      opacity: 0;

      max-height: 100%;
      transition: all .3s ease;
      transform: translateY(-50px);
    }

    &.entered {
      opacity: 1;

      max-height: 100%;
      transition: all .3s ease;
      transform: translateY(0px);
    }

    &.exiting {
      opacity: 0;
    }

    &.exited {
      display: none;
    }
  }
}

.modalVisible {
  opacity: 1;
  visibility: visible;
  @include flex();
  @include flexAlignItems(center);
  @include flexJustify(center);
  background-color: rgba(61, 64, 66, 0.6);
  mix-blend-mode: normal;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999999;
}
