.Profile {
  box-sizing: border-box;
  font-size: 0.0001em;
  display: block;
  position: relative;
  padding-right: 20px;
  min-height: 100vh;
  padding-top: 24px;

  background-color: $color-bg-grey;

  padding-left: 80px;
  padding-bottom: 72px;

  @media (max-width: $tablet) {
    padding-right: 12px;
  }

  @media (max-width: $mobile) {
    padding-left: 12px;
  }

  &.customer-service {
    width: calc(100% - 312px);

    @media (max-width: $tablet) {
      width: 100%;
    }
  }

  .page-title {
    font-family: arial, sans-serif;
    font-weight: bold;
    color: $color-secondary;
    font-size: 36px;
    letter-spacing: 0.2px;
    line-height: 40px;
    margin-bottom: 24px;
  }

  .b-form {
    display: block;
    margin: auto;
    text-align: center;
  }

  .file-input-label {
    padding-left: 10px;
  }

  .row {
    margin-bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    div.input-form__profile {
      margin-bottom: 10px !important;
    }

    @media (max-width: $tablet) {
      div.input-form__profile {
        width: 80% !important;
      }

      &.file-upload {
        width: 80% !important;
      }
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      div.input-form__profile {
        width: 100% !important;
      }

      &.file-upload {
        width: 100% !important;
      }
    }
  }

  .row div.input-form__profile {
    width: 33%;
    margin: 0 2px;
  }

  .row.file-upload {
    width: 33%;
    height: 48px;
    display: inline-flex;
    justify-content: space-around;
    background-color: #ffffff;
    border: 1px solid $color-tertiary;
    border-radius: 4px;
    color: $color-secondary;
    align-items: center;
  }

  .row.file-upload .e-file-input .file-input-label-wrap {
    display: inline-flex;
    justify-content: space-around;
    width: 100%;
  }

  .row.file-upload .e-file-input {
    font-size: 16px;
    letter-spacing: 0.2px;
    width: 100%;
    font-family: arial, sans-serif;
    font-weight: normal;
  }

  .row.file-upload input {
    width: 100%;
  }
}

