@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';

select {
    &:focus {
        outline: 0;
    }
}

.DummySelect__wrapper {
    height: 74px;
    width: 100%;

    &.disabled {
        .textfield {
            .select {
                background: $color-bg;
                cursor: not-allowed!important;
                border: 1px solid $color-border!important;
            }
            .label {
                cursor: not-allowed;
            }
        }
    }

    .textfield {
        position: relative;
        padding-top: 16px;

        .select {
            caret-color: transparent;
            cursor: default;
            height: 40px;
            width: 100%;

            padding: 10px 35px 10px 12px;

            background: $color-contrast;
            border: 1px solid $color-border;
            box-sizing: border-box;
            border-radius: 3px;

            @include roboto-regular;
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            color: $color-grey-additional;

            white-space: nowrap;
            overflow: hidden;
            
            transition: all 0.07s ease-in-out;

            &:hover {
                border: 1px solid $color-accent;
                cursor: pointer;
            }

            &:focus {
                border: 2px solid $color-accent;
                padding: 10px 35px 10px 11px;
            }
        }

        .select_invalid {
            border-color: $color-error!important;
            color: $color-error!important;
        }

        .has_icon_select {
            padding-left: 44px!important;
        }

        .label {
            @include roboto-regular;
            font-style: normal;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;

            display: block;
            position: absolute;
            top: 26px;
            left: 12px;
            width: 75%;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            pointer-events: none;
            transition: all 0.07s ease-in-out;
            color: $color-grey-additional-2;
        }

        .label_focused {
            top: 0;
            left: 12px!important;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.4px;
            color: $color-accent;
            pointer-events: visible;
        }

        .label_has_value_not_focused_disabled {
            top: 0;
            left: 12px!important;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.4px;
        }

        .label_has_value_not_focused {
            color: $color-grey-additional-2!important;
        }

        .label_invalid {
            color: $color-error!important;
        }

        .has_icon_label {
            left: 44px;
        }

        .error_field {
            @include roboto-regular;
            font-style: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.4px;

            display: block;
            position: absolute;
            top: 60px;
            left: 12px;
            width: 91%;

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            opacity: 0;
            visibility: hidden;

            transition: all 0.07s ease-in-out;
            color: $color-error;
        }

        .error_visible {
            opacity: 1;
            visibility: visible;
        }

        .icon {
            position: absolute;
            top: 24px;
            left: 10px;
            width: 24px;
            height: 24px;
            svg {
                path {
                    transition: all 0.07s ease-in-out;
                }
            }
        }

        .icon_focused {
            svg {
                path {
                    fill: $color-accent;
                }
            }
        }

        .icon_invalid {
            svg {
                path {
                    fill: $color-error!important;
                }
            }
        }

        .select_chevron {
            display: block;
            position: absolute;
            top: 26px;
            right: 17px;
            pointer-events: none;
            will-change: transform;
            transition: all 0.07s ease-in-out;
            svg {
                path {
                    fill: $color-icony;
                    transition: all 0.07s ease-in-out;
                }
            }
        }

        .select_chevron_focused {
            svg {
                path {
                    fill: $color-accent;
                    transition: all 0.07s ease-in-out;
                }
            }
        }

        .select_chevron_opened {
            transform: rotate(-180deg);
        }

        .select_chevron_invalid {
            svg {
                path {
                    fill: $color-error;
                    transition: all 0.07s ease-in-out;
                }
            }
        }
    }

    .popup__wrapper {
        .select-popup {
            position: absolute;
            border-radius: 4px;
            background-color: $color-contrast;
            border: 1px solid $color-icony;
            box-shadow: 3px 15px 25px rgba(0, 0, 0, 0.17);
            visibility: hidden;
            opacity: 0;
            z-index: 1000;
            transition: all 0.2s ease-in-out;

            .values {
                padding: 4px 0;
                margin: 0;

                li {
                    @include borderBox();
                    list-style: none;
                    padding: 8px 16px;
                    @include roboto-regular();
                    letter-spacing: 0.5px;
                    font-size: 16px;
                    line-height: 20px;

                    color: $color-grey-additional-2;

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &:hover {
                        background: #e4f2fe;;
                        color: #1665D8;
                        cursor: pointer;
                    }
                }
            }

            .select-popup_renderVertical {
                position: absolute;
                width: 6px;
                right: 6px;
                bottom: 2px;
                top: 2px;
                border-radius: 3px;
                visibility: visible;
                background: #e5e5e5;
                div {
                    background-color: $color-primary-light-blue!important;
                }
            }
        }

        .select-popup.active {
            visibility: visible;
            opacity: 1;
          }

    }
}