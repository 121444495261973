.textArea__wrapper {
  .e-textarea-input {
    display: inline-block;
    position: relative;
    height: var(--textArea-height);
    width: var(--textArea-width);
    &.focus,
    &.error,
    &.has-value {
      .input-label {
        opacity: 1;
      }

      .input-label::before {
        background-color: $color-contrast;
      }
    }
    &.error {
      .input-label-text {
        color: $color-error;
      }
    }
    &.focus {
      textarea {
        padding: 11px 15px;
        border: 2px solid $color-accent;
        background-color: $color-contrast;
      }
      .input-label-text {
        color: $color-accent;
      }

      .input-label::before {
        background-color: $color-contrast;
      }
    }

    .input-label::before {
      background-color: lighten($color-tertiary, 20%);
    }
  }

  textarea {
    background-clip: padding-box;
    @include arial-regular();
    @include borderReset();
    @include borderBox();
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $color-tertiary;
    padding: 12px 16px;
    font-size: 14px;
    letter-spacing: 0.2px;
    background-color: lighten($color-tertiary, 20%);
    resize: none;
  }

  .e-textarea-input {
    &.error {
      textarea {
        border-color: $color-error;
        color: $color-error;
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
          color: $color-error;
          opacity: 1;
        }
      }
    }
  }

  .input-error {
    @include gilroy-regular();
    display: block;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    left: 16px;
    top: 100%;
    margin-top: 4px;
    color: $color-error;
  }
}
