@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;


.react-datepicker-popper {
  z-index: 11;
}

.orderShipping__wrapper {
  .orderShipping__content {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(flex-start);
    flex-wrap: wrap;
    padding: 24px;

    @media (max-width: $mobile) {
      padding: 12px;
    }

    &.card-payment {
      padding-top: 0;
    }

    .shipping-datepicker__wrapper {
      @media (max-width: $mobile) {
        margin-right: 0;
      }

      &.has-value {
        background: #fff;
      }
    }

    .react-datepicker-popper {
      z-index: 10;
    }

    .cardPayment__checkbox {
      height: 18px;
      width: 18px;
    }

    .cardPayment__label {
      font-size: 15px;
      font-family: sans-serif;
      margin-left: 20px;
      color: #7C7C7C;
    }

    .react-datepicker-wrapper {
      &.date_from {
        @media (max-width: $mobile) {
          width: 100%;
        }
      }

      &.date_to {
        @media (max-width: $mobile) {
          width: 100%;
        }
      }
    }

    .delivery-select-wrapper {
      width: 100%;
      max-width: 296px;
      @media (max-width: $mobile) {
        max-width: 100%;

        .select__wrapper {
          max-width: 100% !important;
        }
      }

      .select__wrapper {
        margin-bottom: 10px;
        width: 100%;
        max-width: 296px;
      }
    }

    .delivery-time-hint {
      @include arial-regular();
      font-style: italic;
      color: $color-secondary;
      font-size: 14px;
      flex: 1;
      width: 100%;
      min-width: 100%;
    }
  }

  .empty-delivery-list {
    color: $color-error;
    font-size: 14px;
    @include arial-bold();
  }
}

.react-datepicker__day.no-work-day {
  height: 27px;
  line-height: 1.6rem;
  border: 1px solid #90CAF9;
  border-radius: 50%;
  &:hover {
    border-radius: 50% !important;
  }
}

.react-datepicker__day {
  border-radius: 0.3rem;
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

.orderShipping__datepicker {
  margin: 0 15px 10px 0;
  &_empty {

    .shipping-datepicker__wrapper {
      border-color: red;
    }
  }
}
