.ButtonWithSplit {
  position: relative;

  & * {
    box-sizing: border-box;
  }

  &.full-width {
    width: 100%;
  }

  .popup {
    width: 250px;
    position: absolute;
    background: #FFFFFF;
    box-shadow: 3px 15px 25px rgba(0, 0, 0, 0.17);
    border-radius: 4px;
    bottom: 100%;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s ease-in-out;
    z-index: 9999;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    .values {
      list-style: none;
      margin: 0;
      padding: 0;
      font-family: arial, sans-serif;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.5px;
      max-height: 200px;
      overflow-x: auto;
      border: 1px solid #D4D4D8;
      border-radius: 4px;
      width: 250px;

      li {
        overflow: hidden;
        width: 100%;
        color: #7C7C7C;
        min-height: 40px;
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        user-select: none;
        cursor: pointer;
        border-bottom: 1px solid #D4D4D8;

        &:hover {
          background: rgba(144, 202, 249, 0.25);
          color: #3A3A3A;
        }

        svg {
          position: relative;
          top: -1px;
          margin-left: 4px;
        }
      }
    }
  }

  .IcChevronUpSvg {
    margin-left: 5px;
    position: relative;
    top: -1px;
  }
}