.script-call-tabs {
  display: flex;
  justify-content: center;
  margin: 16px 0 34px 0;

  & .script-call-tabs__btn-call::before, & .script-call-tabs__btn-call {
   border-radius: 10px 0 0 10px;
  }

  & .script-call-tabs__btn-script::before, & .script-call-tabs__btn-script {
    border-radius: 0 10px 10px 0;
  }
}