@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.sipMessageBoard {
  min-height: 24px;

  &__content {
    padding: 2px 10px;
    @include arial-regular();
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 16px;
    text-align: center;
    @include normalLine();
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    .incomingCallNumber {
      margin-right: 5px;
    }

    &.error {
      background-color: #FF3B30;
      color: $color-contrast;
    }

    &.ok {
      background-color: $color-success;
      color: $color-contrast;
    }

    &.info {
      background-color: $color-info;
      color: $color-contrast;
    }

    &.warning {
      background-color: $color-warning;
      color: $color-contrast;
    }

    &.pending {
      background-color: $color-accent;
      color: $color-contrast;
    }

    .bold {
      font-weight: bold;
    }
  }
}
