@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;


.OrderCreationPage {
  &.customer-service {
    width: calc(100% - 312px);

    @media (max-width: $tablet) {
      width: 100%;
    }
  }
  &__wrapper {
    @include borderBox();
    display: block;
    position: relative;
    min-height: 100vh;
    background-color: $color-bg;
    padding: 24px 44px 72px 104px;

    @media (max-width: $tablet) {
      padding-right: 12px;
    }

    @media (max-width: $mobile) {
      padding-left: 12px;
    }
  }

  &__pageTitle {
    @include arial-bold();
    color: $color-secondary;
    font-size: 36px;
    letter-spacing: 0.2px;
    line-height: 40px;
    margin-bottom: 24px;

    @media (max-width: 540px) {
      font-size: 22px;
      white-space: nowrap;
    }
  }

  &__content {
    @include borderBox();
    @include zeroFont();
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1),
    0 5px 5px 2px rgba(0, 0, 0, 0.05);
  }
}
