@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;

.script-item {
  @include roboto-regular();

  margin: 16px 0;

  &__title {
    line-height: 20px;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding-right: 16px;
    border-bottom: 1px solid $color-test-border;
    padding-bottom: 10px;

    .arrowNav_next {
      transform: rotate(180deg);
      order: 2;
    }
  }

  &__name {
    order: 1;
  }

  &__title_reverse {

    justify-content: flex-start;

    .arrowNav_prev {
      order: 1;
      margin-right: 16px;
    }

    .script-item__name {
      order: 2;
    }
  }
}