@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.SipPanel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: #efefef;
  box-sizing: border-box;
  width: 312px;
  border-left: 1px solid #D4D4D8;
  height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: $mobile) {
    width: 100%;
  }

  @media (max-width: $tablet) {
    position: fixed;
    transform: translateX(400px);
    z-index: 101;
    transition: all 0.2s ease-in-out;

    &.visible {
      display: block;
      transform: translateX(0);
    }
  }

  @media (max-width: $mobile) {
    width: 100%;
    transform: translateX(100%);
  }

  &__content {
    display: flex;
    width: 100%;
    height: auto;

    flex: 1;
    flex-direction: column;
    padding: 24px 16px 0;

    @media (max-width: $mobile) {
      padding-bottom: 80px;
    }

    @media (max-width: $tablet) {
      height: calc(100vh - 25px);
      height: calc(var(--vh, 1vh) * 100 - 25px);
    }

    .call-time-button {
      width: 24px;
      height: 24px;
      display: block;
      background-size: cover;

      &.call-problem-button {
        width: 44px;
        height: 44px;
      }

      svg {
        circle {
          stroke: $color-icony;
        }

        path {
          fill: $color-icony;
        }
      }

      &.disabled {
        opacity: 0.3;

        svg {
          path {
            fill: $color-icony;
          }
        }

        cursor: default;
      }

      &.muted {
        opacity: 1;

        svg {
          path {
            fill: $color-accent;
          }
        }
      }
    }

    & .top {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .call-time-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    & .bottom {
      margin-bottom: 48px;

      .buttons {
        display: flex;
      }
    }

    @media (max-width: $mobile) {
      margin-bottom: 56px;
      height: calc(var(--vh, 1vh) * 100);
    }

    @supports (-webkit-overflow-scrolling: touch) {
      /* CSS specific to iOS devices */
      height: calc(var(--vh, 1vh) * 100 - 56px);
    }
  }
}

.SipPanel-overlay {
  z-index: 100;
  pointer-events: none;
  @include fullSize();
  background-color: fade($color-contrast, 0.6);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}