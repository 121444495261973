.userStatistic__wrapper {
  .progress-value {
    display: block;
    height: 100%;
  }

  .b-statistic {
    @include flex();
    @include flexJustify(space-between);
    @include flexWrap(wrap);
    padding: 4px 16px 0;
  }

  .success {
    color: $color-success;
  }

  .accent {
    color: $color-accent;
  }

  .warning {
    color: $color-warning;
  }

  .error {
    color: $color-error;
  }

  .item {
    display: inline-block;
    text-align: left;
    width: 30%;
    margin-bottom: 20px;
  }

  .name {
    @include arial-regular();
    color: $color-secondary;
    font-size: 10px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  .number {
    @include arial-regular();
    font-size: 20px;
    line-height: 28px;
    letter-spacing: .1px;
  }

  .number sup {
    font-size: 11px;
    top: -10px;
  }

  .time {
    @include arial-regular();
    color: $color-main;
    font-size: 12px;
    letter-spacing: .4px;
    line-height: 28px;
  }

  .label {
    @include arial-regular();
    font-size: 10px;
    letter-spacing: .4px;
    color: $color-main;
    .up {
      @include ib();
      margin-right: 2px;
      width: 10px;
      height: 10px;
      fill: $color-success;
    }
  }

  .progress {
    @include ib();
    margin-left: 2px;
    height: 3px;
    width: 32px;
    background-color: fade($color-tertiary, 0.5);
    position: relative;
    &.high {
      .progress-value {
        background-color: $color-success;
      }
    }
    &.medium {
      .progress-value {
        background-color: $color-warning;
      }
    }
    &.low {
      .progress-value {
        background-color: $color-error;
      }
    }
  }
}