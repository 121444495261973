@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.BlockWithBonusProducts {
  padding: 20px;
  background-color: $color-surface;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top: 1px solid $color-border;

  .swiper-scrollbar-drag {
    background-color: $color-accent;
  }

  &__product-list {
    height: 230px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .bonusItem {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__swiper_slide {
    width: 140px;
    display: flex;
    flex-direction: row;
    order: 100;

    &.bonus_item__OLD_PROMOTION {
      transition: 0.2s ease-in-out width;
      will-change: width;
      width: auto;
      &.available {
        order: 10;
      }

      &.unavailable {
        order: 110;
      }

      .PromotionToApply__wrapper {
        transition: 0.2s ease-in-out width;
        will-change: width;

        &:hover {
          width: 319px;
        }
      }
    }

    &.bonus_item__PROMOTION_BUILDER {
      transition: 0.2s ease-in-out width;
      will-change: width;
      width: auto;

      // TODO: задать order для promotionBuilder как нужно в соответствии с available и unavailable
      &.available {
        order: 10;
      }

      // &.unavailable {
      //   order: 110;
      // }

      .PromotionBuilderToApply__wrapper {
        transition: 0.2s ease-in-out width;
        will-change: width;

        &:hover {
          width: 451px;
        }
      }
    }

    &.bonus_item__PROMO {
      &.available {
        order: 20;
      }

      &.unavailable {
        order: 120;
      }
    }

    &.bonus_item__GIFT {
      &.available {
        order: 30;
      }

      &.unavailable {
        order: 130;
      }
    }
  }

  .swiper-container {
    padding-bottom: 20px;
  }

  .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 3px;
  }

  .swiper-pagination-bullet {
    background: #FFFFFF;
    border: 1px solid #D4D4D8;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background-color: $color-accent;
      border: none;
    }
  }

  .no_items_found {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 208px;

    color: #6B6C6F;

    @include roboto-regular();
    font-size: 20px;
    line-height: 24px;
  }
}
