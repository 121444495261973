.dialing__wrapper {
  .e-dialing {
    @include zeroFont();
    @include normalLine();
    display: block;
    margin-top: 48px;
    text-align: center;
  }
  .number {
    @include ibt();
    @include arial-regular();
    color: $color-secondary;
    font-size: 24px;
    line-height: 1;
    width: 32%;
    margin-bottom: 40px;
  }
}