$desktop: 1140px;
$tablet: 970px;
$mobile: 480px;
$orderBlockBreakpoint: 700px;
$changeOrderButtonHeightBreakpoint: 600px;

:root {
  --tablet: 970;
  --mobile: 480;
  --orderBlockBreakPoint: 700;
  --changeOrderButtonHeightBreakpoint: 600;
}
