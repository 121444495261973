@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.statusList {
  opacity: 0;
  visibility: hidden;

  position: absolute;
  right: -183px;
  bottom: 0;

  background: #FFFFFF;
  border: 0.5px solid #F2F2F7;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13);
  border-radius: 6px;

  max-height: 190px;
  overflow-y: auto;

  @include sidebarAnimate(all);

  &__wrapper {
    @include flex();
    @include flexDirection(column);
    padding: 0 15px;
  }

  &__content {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(flex-start);
    max-width: 250px;
    cursor: pointer;
    height: 46px;

    .icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      &.un-ready {
        background-image: url('./../../includes/icons/not-active-to-call.svg');
      }

      &.unready-after-save, &.ready {
        background-image: url('./../../includes/icons/active-to-call.svg');
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      .statusList__title {
        color: #4887f5;
        @include simpleAnimate(color);
      }
    }

    &_disabled {
      cursor: default;

      .statusList__title,
      .statusList__limit {
        opacity: 0.3;
      }
    }
  }

  &__title {
    @include flex();
    @include flexAlignItems(center);
    text-align: left;

    padding-left: 15px;

    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.15px;

    color: #3A3A3A;
  }

  &__limit {
    margin-left: auto;

    .timer {
      border-radius: 3px;
      background: $color-accent;
      color: $color-contrast;
      font-weight: bold;
      font-size: 12px;
      width: 56px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.expired {
        background: #FF3B30;
      }
    }
  }
}
