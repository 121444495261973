.productCard__wrapper {
  .b-product-card {
    @include borderBox();
    display: block;
    text-align: left;
    position: relative;
    padding: 12px;
    background-color: $color-contrast;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0,0,0,0.1), 0 5px 5px 2px rgba(0,0,0,0.05);
    margin-top: 8px;

    .wrap {
      @include flex();
      @include flexAlignItems(top);
    }

    .product-slider {
      display: block;
      @include flexOptions(0 0 auto);
      width: 224px;
      min-width: 224px;
      position: relative;
    }

    .product-info {
      display: block;
      margin-left: 16px;
      @include flexOptions(1 1 auto);
    }

    .image {
      @include borderBox();
      display: block;
      border-radius: 4px;
      height: 224px;
      width: 224px;
      position: relative;
      overflow: hidden;
      z-index: 10;
      background-color: #F9F9F9;
    }

    .image-img {
      @include absoluteCenter();
      max-width: 96%;
      max-height: 96%;
      display: block;
    }

    .product-name {
      @include arial-bold();
      font-size: 16px;
      letter-spacing: 0.4px;
      color: $color-main;
      line-height: 1.2;
    }

    .product-tabs {
      width: 100%;
      @include flex();
      @include flexAlignItems(top);
      margin-top: 8px;
    }

    .tab {
      width: 25%;
      @include borderBox();
      display: block;
      height: 24px;
      padding: 0 8px;
      text-align: center;
      line-height: 22px;
      @include arial-bold();
      font-size: 10px;
      letter-spacing: 0.4px;
      color: $color-secondary;
      border-top: 1px solid $color-tertiary;
      border-bottom: 1px solid $color-tertiary;
      border-right: 1px solid $color-tertiary;
      @include flexOptions(1 0 auto);
      @include simpleAnimate(all);
      &:first-child {
        @include borderRadiusLeft(4px);
        border-left: 1px solid $color-tertiary;
      }
      &:last-child {
        @include borderRadiusRight(4px);
      }
      &:active, &.activeTab {
        color: $color-contrast;
        border-color: $color-accent;
        background-color: $color-accent;
        @include simpleAnimate(all);
      }
    }

    .tab-container {
      display: none;
      height: 176px;
      &.activeTab {
        display: block;
      }
    }

    .productCard__container__row {
      min-height: 32px;
      width: 100%;
      @include flex();
      @include flexAlignItems(center);
      padding: 4px 0;
      &:nth-child(2n) {
        background-color: fade($color-tertiary, 0.2);
      }
    }

    .productCard__name {
      display: block;
      width: 25%;
      font-size: 10px;
      letter-spacing: 0.4px;
      color: $color-secondary;
      @include arial-regular();
      padding-left: 16px;
      @include borderBox();
    }

    .productCard__value {
      display: block;
      width: 75%;
      font-size: 12px;
      letter-spacing: 0.4px;
      line-height: 16px;
      color: $color-main;
      @include arial-regular();
      padding-left: 4px;
      @include borderBox();
      &.full-width {
        width: 100%;
      }

      &.text-content {
        padding-top: 10px;
      }
    }

    .product-slider {
      div {
        outline: none !important;
      }
      .dots {
        @include zeroFont();
        margin: 0;
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
        text-align: center;
        padding: 0;
        li {
          @include ib();
          padding: 0;
          height: 4px;
          width: 4px;
          border-radius: 4px;
          background-color: #e6ecf2;
          margin: 0 4px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
          &.slick-active {
            background-color: $color-accent;
          }
        }
        button {
          opacity: 0;
          height: 4px;
          width: 4px;
          border-radius: 4px;
        }
      }
    }
  }
}




