.tablePagination__wrapper {
  .e-table-pagination {
    display: block;
    text-align: right;
    @include oneLine();
  }

  .label {
    @include ib();
    margin-right: 32px;
    font-size: 10px;
    letter-spacing: 0.4px;
    color: $color-secondary;
    @include arial-regular();
  }

  .page-select {
    @include ib();
    @include normalLine();
    margin-right: 24px;
    position: relative;
    padding-right: 12px;
    .arrow {
      display: block;
      height: 12px;
      width: 12px;
      fill: $color-icony;
      @include absoluteCenterVertical();
      right: 0;
    }
  }

  .handler {
    display: inline-block;
    cursor: pointer;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: $color-main;
    @include arial-regular();
    @include simpleAnimate(color);
  }

  .numbers {
    @include ib();
    margin-right: 32px;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: $color-main;
    @include arial-regular();
    @include simpleAnimate(color);
  }

  .arrows {
    @include ib();
    .prev, .next {
      cursor: pointer;
      width: 16px;
      height: 16px;
      @include ib();
    }
    .prev {
      margin-right: 24px;
    }
    .icon {
      fill: $color-icony;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}