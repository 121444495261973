@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.erpStatusName {
  min-width: 73px;
  width: 10.3%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @include roboto-regular;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;

  &.approve,
  &.erpStatusType__initial {
    color: $color-success;
  }

  &.no_answer {
    color: $color-accent;
  }

  &.recall {
    color: $color-icony;
  }

  &.reject,
  &.erpStatusType__final {
    color: $color-error;
  }

  &.trash,
  &.erpStatusType__intermediate {
    color: $color-warning;
  }

  &.crash {
    color: $color-warning;
  }

  &.new {
    color: $color-primary-light-blue;
  }
}
