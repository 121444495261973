@use '@styles/core/breakpoint' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.ErrorNotifyModal {
  padding: 20px;
  @include arial-regular();
  width: 400px;
  &__content {
    width: 100%;
  }
  &__field {
    color: $color-error;
    @include flex();
    @include flexAlignItems(center);
    width: 100%;
    &:last-child {
      @include flexJustify(flex-end);
      margin-top: 100px;
    }
  }
  &__success-button {
    margin-right: 10px;
    padding: 0 20px;
  }

  &__cancel-button {
    padding: 0 20px;
  }
}
