@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.langList {
  opacity: 0;
  visibility: hidden;

  position: absolute;
  right: -299px;
  bottom: 30px;

  background: #FFFFFF;
  border: 0.5px solid #F2F2F7;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.13);
  border-radius: 6px;

  max-height: 190px;
  overflow-y: auto;

  @include sidebarAnimate(all);

  &__wrapper {
    @include flex();
    @include flexDirection(column);
    padding: 15px;
  }

  &__content {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(flex-start);
    max-width: 250px;
    margin-bottom: 10px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      .langList__title {
        color: #4887f5;
        @include simpleAnimate(color);
      }
    }
  }

  &__title {
    @include flex();
    @include flexAlignItems(center);

    margin-left: 15px;
    text-align: left;
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.15px;

    color: #3A3A3A;
  }
}
