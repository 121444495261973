@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.sidebar__wrapper {
  .b-side-panel {
    background-color: $color-contrast;
  }

  .b-side-panel {
    @include flex();
    @include flexDirection(column);
    @include flexJustify(space-between);

    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 64px;
    z-index: 9999999;
    text-align: center;
    border-right: 1px solid $color-border;

    @include sidebarAnimate(all);
    &:hover {
      width: 200px;
      background-color: $color-contrast;
      border-right: none;
      box-shadow: 3px 15px 25px rgba(0, 0, 0, 0.17);
      @include sidebarAnimate(all);
      .menu-item-text {
        display: block;
      }
      .ArrowRightSvg {
        display: block;
      }
    }
  }

  %hoverTab {
    .menu-item-icon {
      filter: invert(86%) sepia(97%) saturate(1702%) hue-rotate(203deg) brightness(103%) contrast(92%);
    }
    .menu-item-text {
      color: #4887f5;
    }
  }

  .activeTab {
    &.menu-item {
      cursor: default;
      &:hover {
        background-color: white;
        @extend %hoverTab;
      }
    }
    @extend %hoverTab;
  }

  .disabledTab {
    &.menu-item {
      cursor: default;

      .menu-item-icon,
      .menu-item-text {
        opacity: 0.3;
      }
    }
  }

      .logo-wrap {
    @include flex();
    @include flexJustify(flex-start);
    @include flexAlignItems(center);
    margin-left: 8px;
    margin-bottom: 11px;
    @media (max-width: $mobile) {
      margin-bottom: 0;
    }
    .logo {
      @include gilroy-bold();
      @include clearLink();
      padding: 22px 0 0 1px;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $color-main;
      @media (max-width: $mobile) {
        margin-bottom: 10px;
      }
      .logo-image {
        display: inline-block;
        vertical-align: middle;
        width: 46px;
        height: 19px;
      }
    }

    @media (max-height: 470px) and (min-width: $mobile) {
      display: none;
    }
  }

  .panel-menu {
    display: block;

    .main-links {
      @media (max-width: $mobile) {
        height: calc(100% - 362px);
        overflow: auto;
        position: fixed;
        top: 50px;
        width: 100%;
      }

      @media (max-height: 470px) and (min-width: $mobile) {
        max-height: 100px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }

  .panel-menu-bottom {
    margin-bottom: 8px;
    @media (max-width: $mobile) {
      position: fixed;
      bottom: 56px;
      left: 0;
      width: 100%;
    }
  }

  .switch-wrap {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(space-between);
    height: 35px;
    .switchUser__content {
      line-height: 1em;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .unreadyAfterSave {
    border-top: 1px solid $color-border;
  }

  .readySwitcher {
    border-bottom: 1px solid $color-border;
  }

  .menu-item-switch {
    @include flex();
    @include flexJustify(flex-start);
    @include flexAlignItems(center);

    padding-left: 12px;
    padding-right: 28px;
    height: 50px;

    .menu-item-text {
      margin-top: 6px;
      padding-left: 12px;
      @include simpleAnimate(color);
    }

    .menu-item__unreadyAfterSave {
      white-space: pre-wrap;
    }

    &:hover {
      cursor: pointer;
      .menu-item-text {
        color: $color-accent;
      }
    }
  }

  .menu-item {
    @include flex();
    @include flexJustify(flex-start);
    @include flexAlignItems(center);
    cursor: pointer;
    margin: 0 8px;
    position: relative;

    .status-icon {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      &.un-ready {
        background-image: url('./icons/not-active-to-call.svg');
      }

      &.unready-after-save, &.ready {
        background-image: url('./icons/active-to-call.svg');
      }
    }

    @media (max-width: $mobile) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 11px;
        top: 17px;
        width: 7px;
        height: 12px;
        background: url('icons/chevron-right.svg') no-repeat center;
      }
    }

    &.smartReadySwitcher {
      .menu-item-text {
        @include simpleAnimate(all);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
      }

      .link {
        padding-left: 15px;
      }

      @media (max-width: $mobile) {
        &:after {
          display: none;
        }
      }
    }

    &:hover {
      @include simpleAnimate(all);
      @include borderRadiusTop(6px);
      @include borderRadiusBottom(6px);
      background-color: $color-accent;

      .menu-item-text {
        color: $color-contrast;
      }

      &.smartReadySwitcher {
        .menu-item-text {
          color: $color-accent;
        }
        background-color: transparent;

        .statusList {
          opacity: 1;
          visibility: visible;
        }
      }

      .menu-item-icon {
        filter: brightness(0) invert(1);
      }
    }

    .link {
      @include flex();
      @include flexAlignItems(center);
      @include clearLink();
      @include borderBox();
      height: 48px;
      padding-left: 12px;
      padding-right: 20px;
    }

    &.menu-item-avatar {
      &:after {
        display: none;
      }
      .link {
        padding-left: 6px;
        padding-right: 14px;
        display: flex;
        align-items: flex-start;
        height: 50px;
      }
    }

    &.menu-item-logout {
      &:after {
        display: none;
      }
      .link {
        padding-left: 14px;
        .menu-item-icon {
          width: 20px;
          height: 20px;
        }
      }

      .menu-item-text {
        padding-left: 2px;
        letter-spacing: 0.02em;
      }
    }
  }

  .menu-item-border {
    display: block;
    border-top: 1px solid  $color-tertiary;
  }

  .menu-item-text {
    display: none;
    @include arial-bold();
    @include clearLink();
    color: $color-icony;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: -0.02em;
    margin-top: 2px;
    padding: 0 8px 0 0;
    text-align: left;
  }

  .menu-item-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
  }

  .sideElement__langList {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(space-between);
    margin-left: 8px;
    padding-left: 10px;
    margin-right: 8px;
    height: 50px;
    cursor: pointer;

    .menu-item-text {
      @include simpleAnimate(color);
      flex: 1;
      text-align: left;
      padding-left: 15px;
    }

    &:hover {
      .menu-item-text {
        color: $color-accent;
      }

      .ArrowRightSvg {
        filter: invert(86%) sepia(97%) saturate(1702%) hue-rotate(203deg) brightness(103%) contrast(92%);
      }

      .langList {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .ArrowRightSvg {
    display: none;
  }

  .langIcon {
    border-radius: 50%;
    width: 30px;
    min-width: 30px;
    height: 30px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.3);
  }

  .avatar {
    border-radius: 50%;
    z-index: 2;
    position: relative;
    overflow: hidden;
    width: 36px;
    height: 36px;
    margin: auto;
  }

  .avatar-image {
    @include absoluteCenterHorizontal();
    display: block;
    height: 100%;
  }

  .text-link {
    display: none;
  }

  @media (max-width: $mobile) {
    width: 100%;
    transition: all 0.2s ease-in;
    transform: translateX(-100%);
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    border: none;
    .b-side-panel {
      width: 100% !important;
      margin-bottom: 56px;
    }
    .menu-item-text {
      display: block;
    }
    .ArrowRightSvg {
      display: block;
    }
    &.visible-on-mobile {
      transform: translateX(0);
    }
  }
}
