@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;


.CommunicationQualityModal {
  width: 600px;
  position: relative;
  top: -30px;

  @media (max-width: $tablet) {
    width: 100%;
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__header {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &__main_header {
    @include arial-bold();
    font-size: 24px;
    line-height: 32px;
    color: $color-main;
    text-align: center;
    margin: 0;
  }

  &__sub_header {
    @include arial-regular();
    font-size: 14px;
    line-height: 16px;
    color: $color-main;
    text-align: center;
  }

  &__textarea {
    width: 100%;
    margin: 10px 42px 0 42px;
    padding: 10px 10px 0 10px;
    height: 60px;
  }

  &__block {
    margin-top: 17px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #BCBCBC;
    border-bottom: 1px solid #BCBCBC;
    max-height: 383px;
    overflow-y: auto;

    @media (max-width: $tablet) {
      max-height: 300px;
    }
  }

  &__block__bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    flex-wrap: wrap;

    .e-button__wrapper + .e-button__wrapper {
      margin-left: 8px;
    }
  }

  &__item {
    label {
      user-select: none;
      display: flex;
      align-items: center;
      height: 64px;
      padding: 0 42px;

      @media (max-width: $tablet) {
        padding: 0 16px;
      }
      transition: all 0.3s ease;
      background: transparent;
      cursor: pointer;

      &:hover {
        background: rgba(35, 35, 35, 0.05);
      }

      .cq_checkbox {
        input {
          width: 20px;
          height: 20px;
        }
        margin-right: 18px;
      }

      .cq_name {
        @include arial-bold();
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
