.switchUser__wrapper {
  .switchUser {
    @include ib();
    @include simpleAnimate(background-color);
    width: 40px;
    height: 12px;
    position: relative;
    background-color: fade($color-contrast, 0.6);
    cursor: pointer;
    mix-blend-mode: normal;
    border: 1px solid #D7D7D7;
    border-radius: 6px;
  }

  .switchUser__handler {
    @include simpleAnimate(all);
    @include borderBox();
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -5px;
    left: 0;
    background-color: $color-contrast;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 15px 0 rgba(0, 0, 0, 0.15), 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .switchUser__label {
    @include flex();
    @include flexAlignItems(center);
    @include arial-regular();
    font-size: 6px;
    line-height: 12px;
    text-transform: uppercase;
    color: $color-main;
    &.disable {
      padding-right: 5px;
      @include flexJustify(flex-end);
    }
    &.enable {
      padding-left: 5px;
      color: $color-success;
      @include flexJustify(flex-start);
    }
  }

  .switchUser__content {
    &.active {
      .switchUser__handler {
        left: 20px;
        background-color: $color-success;
      }
    }
  }
}