@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shakeUp {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  50% {
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.animate-me {
  &.bounce {
    animation: shake 1s cubic-bezier(.36,.07,.19,.97) both;
    animation-delay: 0.2s;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
  }

  &.shake-up {
    animation-name: shakeUp;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }

}
