.call-button__wrapper {
  @keyframes grow {
    from {
      width: 0;
      height: 0;
    }
    to {
      width: 296px;
      height: 296px;
    }
  }

  .button {
    width: 100%;
    height: 100%;
    z-index: 10;
    display: block;
    position: relative;
    border-radius: 50%;
    border: 1px solid $color-tertiary;
  }

  .icon {
    width: 32px;
    height: 32px;
    display: block;
    @include absoluteCenter();
    fill: $color-icony;
  }

  .shadows {
    display: none;
    @include absoluteCenterHorizontal();
    bottom: 0;
    width: 296px;
    height: 296px;
    border-radius: 50%;
    div {
      display: block;
      @include absoluteCenterHorizontal();
      bottom:0;
      border-radius: 50%;
      opacity: 0.07;
      background-color: $color-icony;
      animation: grow 4s ease-out infinite;
      &:nth-child(1) {
        animation-delay: -3s;
      }
      &:nth-child(2) {
        animation-delay: -2s;
      }
      &:nth-child(3) {
        animation-delay: -1s;
      }
      &:nth-child(4) {
        animation-delay: 0s;
      }
    }
  }

  .e-call-button {
    @include ib();
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    cursor: pointer;
    &.active {
      .button {
        border: none;
        background-color: $color-contrast;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 14px 35px 6px rgba(0, 0, 0, 0.14);
      }
      .icon {
        fill: $color-success;
      }
    }
    &.calling {
      .button {
        border: none;
        background-color: $color-success;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 14px 35px 6px rgba(0, 0, 0, 0.14);
      }
      .icon {
        fill: $color-contrast;
      }
      .shadows {
        display: block;
      }
    }
    &.error {
      .button {
        border: none;
        background-color: $color-error;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 14px 35px 6px rgba(0, 0, 0, 0.14);
      }
      .icon {
        fill: $color-contrast;
      }
      .shadows {
        display: block;
        div {
          background-color: $color-error;
        }
      }
    }
    &.live {
      .button {
        border: none;
        background-color: $color-contrast;
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 5px 5px 2px rgba(0, 0, 0, 0.05);
      }
      .icon {
        width: 42px;
        height: 42px;
      }
    }
  }
}