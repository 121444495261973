@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;

.browser-not-allowed {
  width: 100%;
  height: 100%;
  background: $color-contrast;


  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    max-width: 600px;
    padding: 10px;
    height: auto;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .message {
      margin: 20px 0;
      color: $color-grey-additional-2;
      @include roboto-regular;
      text-align: center;
    }

    .action {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
