.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: hand;
  }
}


.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  &:before,
  &:after {
    display: table;
    content: '';
  }
  &:after {
    clear: both;
  }
}

.slick-loading {
  .slick-track,
  .slick-slide {
    visibility: hidden;
  }
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  img {
    display: block;
  }
  &.slick-loading {
    img {
      display: none;
    }
  }
  &.dragging {
    img {
      pointer-events: none;
    }
  }
}

[dir='rtl'] {
  .slick-slide {
    float: right;
  }
}

.slick-initialized {
  .slick-slide {
    display: block;
  }
}

.slick-vertical {
  .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow {
  &.slick-hidden {
    display: none;
  }
}
