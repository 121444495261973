.callButtonSvg {
  cursor: pointer;

  .callButtonSvg__phone__simple {
    display: none;
  }

  .callButtonSvg__phone__dial {
    display: none;
    transform: translate(46px, 40px);
  }

  .callButtonSvg__phone__endCall {
    display: none;
    transform: translate(40px, 36px);
  }

  .callButtonSvg__shadow {
    overflow: visible;
    opacity: 1;
  }

  &.inactive {
    cursor: default;

    .callButtonSvg__shadow {
      visibility: hidden;
      opacity: 0;
    }

    .callButtonSvg__phone__simple {
      fill: #C7C7CC;
      display: initial;
    }
  }

  &.ready {
    .callButtonSvg__phone__simple {
      display: initial;
    }

    &:hover {
      .callButtonSvg__phone__dial {
        display: initial;
      }

      g {
        path {
          fill: #34C759;
        }
      }
    }
  }

  &.endCall {
    .callButtonSvg__phone__endCall {
      display: initial;
    }

    g {
      path {
        fill: #E6492D;
      }
    }
  }
}