.callHistory {

  &__dropdown {
    height: 35px;
    font-size: 14px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    color: rgb(57, 144, 255);
    gap: 10px;
    position: relative;

    & > * {
      cursor: pointer;
    }
  }

  &__audiosource {
    position: absolute;
    padding: 20px;
    z-index: 100;
    background: white;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    display: none;
    flex-direction: column;
    gap: 10px;
    top: 50px;
    border-radius: 5px;

    audio {
      padding-right: 30px;
    }

    &_active {
      display: flex;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  &__date {
    font-size: 14px !important;
  }

  &__spinner {
    position: relative;
  }

}