@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.billing-list-weeks {
  position: relative;

  &__charts {
    padding: 7px 10px 0 10px;
    display: flex;
    justify-content: space-around;

  }

  &__columns {
    width: 26px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 72px;
  }

  &__line {
    border-bottom: 1px solid $color-light-gray;
    position: absolute;
    top: 78px;
    width: 100%;
  }

  &__date {
    font-size: 10px;
    line-height: 12px;
    padding-top: 3px;
  }

  &__first-week, &__second-week, &__third-week {
    width: 6px;
    border-radius: 6px 6px 0px 0px;
  }

  &__first-week {
    background: $color-light-gray;
  }

  &__second-week {
    background: $color-gray-text;
  }

  &__third-week {
    background: $color-light-blue;
  }

}