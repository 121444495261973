@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.billing-list-table {
  max-width: 1232px;
  margin-top: 24px;

  &__table {
    width: 100%;
    border-radius: 4px;
    color: $color-grey-additional;
    border-collapse: collapse;
    font-family: Roboto;
  }

  &__thead {
    background: $background-gray;
    border: 1px solid $color-test-border;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;

  }

  &__th {
    padding: 10.63px 0 10.64px 16px;

    &:first-child {
      padding-left: 20px;
    }
  }

  &__tr {
    border: 1px solid $color-test-border;

  }

  &__td {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.5px;
    padding: 8px 0 8px 16px;
    background: $color-contrast;

    &:first-child {
      padding-left: 20px;
    }

    &_green {
      background: rgba(0, 204, 109, 0.15);
    }

    &_purple {
      background: rgba(255, 58, 71, 0.15);
    }

    &_sum {
      background: $background-gray;
    }

  }

}