@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';

.choosed {
  .PromotionBuilderToApply__product_image__wrapper {
    border-color: $color-accent!important;
    cursor: initial;
  }
}

.promo_alert {
  @include roboto-regular();
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  position: absolute;
  top: 167px;
  z-index: 99;
  width: 128px;
  height: 27.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid #D1D1D6;
  padding: 8px;
}

.hovered {
  z-index:3!important;

  .PromotionBuilderToApply__product_image__wrapper {
    z-index: 3!important;
  }

  .ProductImage {
    z-index: 3!important;
    .PromotionBuilderToApply__product_image {
      z-index: 3!important;
    }
  }

  .PromotionBuilderToApply__product_gallery__wrapper {
    z-index: 3!important;
    .ProductImage {
      z-index: 3!important;
    }
  }

  .PromotionBuilderToApply__product_name {
    z-index: 3!important;
  }

  .PromotionBuilderToApply__product_price_wrapper {
    z-index: 3!important;
  }
  .PromotionBuilderToApply__product_type {
    z-index: 3!important;
  }
  .PromotionBuilderToApply_curtain {
    z-index: 2!important;
  }
}

.PromotionBuilderToApply__wrapper {
  z-index: 2;
  height: 196px;
  width: 128px;
  transition: all 0.2s ease-in-out;
  margin: 6px;
  box-shadow: none;
  position: relative;

  .PromotionBuilderToApply__product_image__wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 196px;
    width: 128px;
    border: 1px solid #D1D1D6;
    background: #fff;
    border-radius: 4px;
    z-index: 2;

    .ProductImage {
      height: 68px;
      margin-top: 24px;
      z-index: 2;
      .PromotionBuilderToApply__product_image {
        z-index: 2;
        width: 100%;
        height: 68px;
        object-fit: contain;
        padding: 4px;
        transition: all 0.2s ease;
      }
    }

    .PromotionBuilderToApply__product_gallery__wrapper {
      display: flex;
      flex-direction: row;
      height: 32px;
      margin-top: -24px;
      z-index: 2;
      .ProductImage {
        height: 32px;
        width: 32px;
        border: 1px solid #F3F3F3;
        box-sizing: border-box;
        border-radius: 0;
        color:#fff;
        z-index: 2;
        .PromotionBuilderToApply__product_image {
          height: 32px;
        }
        .blur {
          background: rgba(255, 255, 255, 0.2);
          mix-blend-mode: normal;
          backdrop-filter: blur(2px);
          width: auto;
          position: absolute;
          z-index: 11;
          left: 1px;
          right: 1px;
          top: 1px;
          bottom: 1px;

          .remaining_number {
            @include roboto-regular;
            font-style: normal;
            font-size: 10px;
            line-height: 12px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            -webkit-transform: translate(-50%, -50%);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }

  .PromotionBuilderToApply__product_name {
    z-index: 2;
    @include roboto-bold();
    font-style: normal;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    color: $color-main;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    margin: 8px;
    width: 112px;
    top: 125px;
    position: relative;
  }

  .PromotionBuilderToApply__product_price_wrapper {
    z-index: 2;
    display: flex;
    padding: 8px;
    width: 128px;
    top: 137px;
    position: absolute;

    svg {
      max-width: 14px;
      min-width: 14px
    }

    .product_price {
      @include roboto-regular();
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.4px;
      color: $color-grey-additional;
      padding-left: 4px;
      max-width: 100px;
      min-width: 43px;
      width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .e-button__wrapper {
    position: absolute;
    top: 168px;
    z-index: 3;
    .v-size-0 {
      height: 28px;
      line-height: 28px;
    }

    .link {
      @include roboto-regular();
      font-weight: 500;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .e-button {
      @include simpleAnimateZeroPointTwoSecs(all);
      &:before,
      &:after {
        @include simpleAnimateZeroPointTwoSecs(all);
      }
    }

    .link {
      @include simpleAnimateZeroPointTwoSecs(all);
      svg {
        path {
          @include simpleAnimateZeroPointTwoSecs(all);
        }
      }
    }

    .e-button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-color: transparent;
      &::before {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-color: transparent;
        border-top: 1px solid #D1D1D6;
      }
    }
  }


  .PromotionBuilderToApply__product_type {
    z-index: 2;
    position: absolute;
    width: auto;
    max-width: 152px;
    left: 8px;
    top: 8px;
  }

  .PromotionBuilderToApply_curtain {
    z-index: 1;
    width: 0px;
    height: 196px;
    border: 1px solid #D1D1D6;
    background: #fff;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    position: absolute;
    top: 0;
    left: 125px;
    transition: all 0.2s ease-in-out;

    .curtainHeader {
      position: relative;
      top: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      transition: all 0.2s ease-in-out;

      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      width: 0;
      height: 24px;

      @include roboto-regular();
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 12px;
      color: #99A2AD;
      text-transform: uppercase;
      letter-spacing: 1px;
      background: #F2F3F5;

      .firstCol {
        margin-left: 8px;
        width: 156px;
      }

      .secondCol {
        width: 70px;
      }

      .thirdCol {
        width: 38px;
      }

      .fourthCol {
        width: 48px;
      }

      // анимация для заголовков таблицы
      .entering {
        opacity: 0;
        transition: all 0.07s 0.15s ease-in-out;
      }

      .entered {
        opacity: 1;
        transition: all 0.07s 0.15s ease-in-out;
      }

      .exiting {
        transition-delay: 0s;
        opacity: 0;
      }

      .exited {
        display: none;
      }
    }

    // анимация для хидера заголовков таблицы
    .entering {
      width: 0;
      opacity: 0;
      transition: opacity 0.2s 0.1s ease-in-out;
    }

    .entered {
      width: 322px;
      opacity: 1;
      transition: opacity 0.2s 0.1s ease-in-out;
    }

    .exiting {
      transition-delay: 0s;
      opacity: 0;
    }

    .exited {
      display: none;
    }

    .popup__wrapper .e-popup {
      visibility: visible;
      opacity: 1;
    }

    .PromotionBuilderToApply__popup {
      top: 24px;
      margin-top: 1px;
      left: 0;
      width: 100%;
      overflow-x: hidden;
      @include normalLine();
      border-radius: 0;
      background-color: none;
      border-top: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 0 solid transparent;
      border-right: 0 solid transparent;
      box-shadow: none;

      ul {
        padding: 5px 0;
        margin: 0;

        li {
          @include roboto-regular();
          font-style: normal;
          font-weight: 500;
          @include borderBox();
          padding-bottom: 8px;
          font-size: 12px;
          line-height: 14px;
          color: $color-grey-additional;
          list-style: none;

          &:last-child {
            margin-top: 2px;
          }

          .li_row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;

            .firstCol {
              margin-left: 8px;
              width: 156px;
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;

              .product_icon {
                width: 12px;
                height: 12px;
                display:flex;
                align-items: center;
                justify-content: center;
                margin-right: 4px;
              }

              .product_name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 5px;
              }

              .mandatoryGift {
                width: 14px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  path {
                    fill: #99A2AD;
                  }
                }
              }
            }

            .secondCol {
              width: 70px;
              .amount {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-transform: uppercase;
                font-size: 10px;
              }
            }

            .thirdCol {
              width: 38px;

              .product_discount {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                color: $color-error;
                font-size: 10px;
              }
            }

            .fourthCol {
              width: 48px;
              display: flex;
              justify-content: flex-end;

              .product_price {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                margin-right: 10px;

                span {
                  color: #00CC6D;
                }
              }
            }
          }
        }
      }
    }
  }

  &:hover {
    .PromotionBuilderToApply_curtain {
      box-shadow: 0 1px 12px rgba(0, 0, 0, 0.15);
      left: 127px;
      width: 324px;
    }

    .PromotionBuilderToApply__product_image__wrapper {
      box-shadow: -5px 1px 7px rgba(0, 0, 0, 0.15);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .e-button {
      border-bottom-right-radius: 0;
      &::before {
        border-bottom-right-radius: 0;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.6;

    .e-button__wrapper {
      .e-button {
        &:before {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

// общие стили анимации transition
.entering {
  opacity: 0;
  transition: opacity 0.2s 0.2s ease-in-out;
}

.entered {
  opacity: 1;
  transition: opacity 0.2s 0.2s ease-in-out;
}

.exiting {
  transition-delay: 0s;
  opacity: 0;
}

.exited {
  display: none;
}

// стиль для скроллбара
.thumb-vertical-promotions {
  background: #000000;
  opacity: 0.1;
  border-radius: 2px;
  width: 4px!important;
}

.thumb-horizotnal-promotions {
  background: transparent;
}
