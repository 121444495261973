@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;

.counter-input__wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  .e-counter-input {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;

    .input__wrapper {
      width: 32px;
      height: 40px;
      border: 1px solid $color-border;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include simpleAnimate(all);

      svg.input-plus, svg.input-minus {
        path, rect {
          @include simpleAnimate(all);
        }
      }

      &:hover {
        background: $color-accent;
        border-color: $color-accent;

        svg.input-plus, svg.input-minus {
          path, rect {
            fill: $color-contrast;
          }
        }
      }

      &.right {
        border-radius: 0 4px 4px 0;
        border-left: none;
      }

      &.left {
        border-radius: 4px 0 0 4px;
        border-right: none;
      }

      .input-plus, .input-minus {
        @extend %unselectable;
        @include ib();
        @include arial-regular();
        cursor: pointer;
        box-sizing: content-box;
      }
    }
  }

  input {
    @include ib();
    @include arial-regular();
    @include borderReset();
    @include borderBox();
    color: #000;
    height: 40px;
    border: 1px solid $color-border;
    width: 40px;
    letter-spacing: 0.2px;
    padding-left: 8px;
    padding-right: 8px;
    text-align: center;
    font-size: 15px;
    line-height: 40px;

    &:focus {
      border-color: $color-accent;
    }
  }
}
