.resize-control {
  position: fixed;
  right: 312px;
  top: 0;
  height: 100vh;
  display: flex;
  z-index: 10002;
  &__stick {
    width: 1px;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.2) 0px, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.1) 1px, rgba(0, 0, 0, 0) 100%);
    height: 100%;
    pointer-events: none;
  }

  &__grab {
    width: 24px;
    background: transparent;
    height: 100%;
    border: none;
    padding: 0;
    cursor: ew-resize;
    transition-duration: 0.22s;
    transition-property: left, opacity, width;
    transition-timing-function: cubic-bezier(0.2, 0, 0, 1);
  }

  &:hover {
    .resize-control__stick {
      background: #1665D8;
      width: 1.5px;
    }
  }
}