@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.create_appeal_form_wrapper {
    width: 100%;
    margin: 0 0 30px;
    padding: 20px 52px 28px 52px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    background-color: $color-contrast;
    @include borderBox();

    .headline {
        @include roboto-regular;
        letter-spacing: 0.15px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: $color-grey-additional;

        margin-bottom: 28px;
    }
    .create_appeal_form {
        .customer_info_block {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-bottom: 10px;
            &.disabled {
                .customer_info_item {
                    .textArea__wrapper {
                        .e-textarea-input {
                            textarea {
                                background-color: $color-surface;
                                cursor: not-allowed;
                            }
                            .input-label {
                                &::before {
                                    background-color: $color-surface;
                                }
                            }
                        }
                    }
                }

            }
            .customer_info_item {
                margin-right: 16px;
                width: 675px;

                .textArea__wrapper {
                    .e-textarea-input {
                        textarea {
                            background-color: $color-contrast;

                            &::-webkit-scrollbar-track {
                                background-color: #f5f5f5;
                            }
                        
                            &::-webkit-scrollbar-thumb {
                                background: #90CAF9;
                                border: 2px solid #90CAF9;
                                border-radius: 2px;
                            }
                        
                            &::-webkit-scrollbar {
                                width: 4px;
                                height: 4px;
                            }
                        }
                        .input-label {
                            &::before {
                                background-color: $color-contrast;
                            }
                        }
                    }
                }
            }
        }
    }
}

.alert_wrapper {
    p {
        @include roboto-regular();
        color: $color-success;
        font-size: 14px;
    }
}
.errorMessage {
    p {
        @include roboto-regular();
        color: $color-error;
        font-size: 14px;
    }
}

.rotating_spinner {
    .spinner__wrapper.e-spinner {
        .lds-spinner {
            width: 24px;
            height: 24px;
            div {
                transform-origin: 10px 10px;
                &:after {
                    top: 9px;
                    left: 0px;
                    width: 6px;
                    height: 2px;
                }
            }
        }
    }
}

.errorMessage {
    p {
        @include roboto-regular();
        color: $color-error;
        font-size: 14px;
    }
}

.entering {
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.entered {
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.exiting {
    opacity: 0;
}

.exited {
    display: none;
}