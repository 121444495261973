@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';

.textInput__wrapper {
  .fixed-value {
    display: flex;
    width: 100%;
    height: 100%;

    input {
      border-radius: 4px;
      text-align: left;
      padding-right: 1px;
      width: 120px;

      &:focus {
        //padding: 0 1px 0 15px;
      }

      &:focus + span {
        //padding: 0 16px;
        //border: 2px solid $color-accent;
      }
    }

    span {
      width: 100%;
      outline: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include arial-regular();
      height: 100%;
      border-radius: 4px;
      border: 1px solid $color-tertiary;
      color: $color-secondary;
      font-size: 14px;
      letter-spacing: 0.2px;
      padding: 1px 17px 1px 17px;
      background: $color-surface;
      margin-left: 8px;
    }
  }

  .fixed-value__solid {
    input {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-right: unset;
      padding-right: unset;
      padding-left: 14.2px;
    }
    span {
      margin-left: unset;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border-left: unset;
      padding-left: 1px;
    }
  }

  .select-popup {
    top: 100%;
    margin-top: 4px;
    left: 0;
    width: 100%;
    overflow-x: hidden;
    @include normalLine();
    ul {
      padding: 4px 0;
      margin: 0;
      li {
        @include borderBox();
        line-height: 1.3em;
        padding: 8px 16px;
        @include gilroy-regular();
        font-size: 13px;
        color: $color-main;
        list-style: none;
        &.result:hover {
          cursor: pointer;
          background-color: fade($color-accent, 0.4);
        }

        &.no-results {
          cursor: default;
        }
      }
    }
  }

  &.e-text-input {
    display: inline-block;
    position: relative;
    height: var(--textInput-height);
    width: var(--textInput-width);
  }

  &.focus,
  &.error,
  &.has-value {
    .input-label {
      opacity: 1;
    }
  }

  &.error {
    input {
      border-color: $color-error;
      color: $color-error;
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: $color-error;
        opacity: 1;
      }
    }
    .input-label-text {
      color: $color-error;
    }
  }

  &.focus {
    input {
      padding: 0 15px;
      border: 2px solid $color-accent;
      background-color: $color-contrast;
    }
    .input-label-text {
      color: $color-accent;
    }

    .input-label::before {
      background-color: $color-contrast;
    }

    .fixed-value__solid {
      input, span {
        border: 2px solid $color-accent;
      }
      input {
        padding-right: unset;
        border-right: none;
        padding-left: 13px;
      }
      span {
        padding-left: 1px;
        border-left: none;
      }
    }
  }

  &.has-value {
    input {
      background-color: $color-contrast;
    }

    .input-label::before {
      background-color: $color-contrast;
    }
  }

  input {
    @include arial-regular();
    @include borderReset();
    @include borderBox();
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $color-tertiary;
    padding: 0 16px;
    color: $color-secondary;
    font-size: 14px;
    letter-spacing: 0.2px;
    background-color: lighten($color-tertiary, 20%);
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $color-secondary;
      opacity: 1;
    }
  }

  .input-error {
    @include arial-regular();
    display: block;
    position: absolute;
    font-size: 10px;
    line-height: 10px;
    left: 16px;
    top: 100%;
    margin-top: 4px;
    color: $color-error;
  }

  .input-label::before {
    background-color: lighten($color-tertiary, 20%);
  }
}
