@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;


.DeletedOptionalGiftsFromPromotionBuilder {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__nameItem {
    margin-bottom: 10px;
    color: $color-error;
  }
}
