@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/breakpoint' as *;

.orderComments__root {
  padding: 21px 24px;

  @media (max-width: $mobile) {
    padding: 12px;
  }
  .orderComments__list {

    .orderComments__item {
      @include arial-regular();
      color: $color-secondary;
      font-size: 12px;

      .orderComments__item__header {
        display: flex;
        .orderComments__item__date {
          font-style: italic;
          color: $color-secondary;
          margin-left: 10px;
        }

        .orderComments__item__author {
          font-weight: bold;
        }
      }

      .orderComments__item__body {
        font-size: 14px;
        color: $color-main;
        padding: 5px 0;
      }

      & + .orderComments__item {
        margin-top: 10px;
      }
    }
  }

  .orderComments__ownComment {
    margin-top: 10px;
  }
}
