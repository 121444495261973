.orderPage__wrapper {
  .status-unready-polling-notifier {
    @include absoluteCenter();
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 16px;
    color: #3a3a3a;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 400px;

    @media (max-width: $mobile) {
      width: 300px;
    }

    h3 {
      font-weight: 400;
      font-size: 28px;
      margin-top: 0;
    }

    .message {
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }

    textarea {
      background: #FFFFFF;
      border: 1px solid #D4D4D8;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 10px 16px;
      width: 100%;
      margin-top: 16px;
      height: 96px;
    }

    .send-button-root {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &__image {
      margin-bottom: 24px;
    }

    &__message {
      margin-bottom: 24px;
      text-align: center;

      a {
        margin-left: 5px;
      }
    }
  }

  .info-panel__block {
    padding-right: 3px;
    display: block;
    @include normalLine();
    @include borderBox();
    position: fixed;
    top: 0;
    bottom: 0;
    left: 88px;
    width: 316px;
    padding-top: 12px;
    z-index: 9999;
    .block {
      margin: 0 8px 12px 8px;
      display: block;
      &.switch {
        height: 40px;
        line-height: 40px;
        background-color: $color-contrast;
        border-radius: 4px;
        box-shadow: 0 0 0 0 rgba(0,0,0,0.1), 0 5px 5px 2px rgba(0,0,0,0.05);
      }
    }

    .block-switch-head {
      @include arial-bold();
      @include borderBox();
      color: $color-icony;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 0.8px;
      padding: 0 12px 0 16px;
      display: inline-block;
    }

    .block-switch-value {
      @include arial-bold();
      color: $color-accent;
      letter-spacing: 0.8px;
      display: inline-block;
      width: 152px;
    }

    .block-switch-switch {
      display: inline-block;
    }
  }

  .order-page {
    position: relative;
    flex-grow: 1;
    height: 100vh;
    padding-right: 287px;
    padding-top: 8px;
    padding-bottom: 8px;
    &.no-padding {
      padding-bottom: 0 !important;
    }

    @media (max-width: $tablet) {
      padding-right: 0;
    }
  }

  .page {
    @include borderBox();
    @include zeroFont();
    display: block;
    position: relative;
    min-height: 100vh;
    background-color: $color-bg-grey;
    padding-left: 64px;
    padding-top: 0 !important;

    @media (max-width: $tablet) {
      padding-right: 0;
    }

    @media (max-width: $mobile) {
      padding-left: 0;
    }

    &.hidden {
      position: fixed;
      max-height: 100%;
      overflow: hidden;
      top: 0;
      width: 100%;
    }
  }

  .page-content {
    @include normalLine();
    @include borderBox();
    display: flex;
    padding-right: 16px;

    .notifyWidget__wrapper {
      position: relative;
      width: 100%;

      &.with-timer {
        top: 40px;
      }
    }

    @media (max-width: $tablet) {
      padding-right: 0;
    }

    &__loader {
      height: 50px;
      position: relative;
      margin-top: 20px;
    }
  }

  .loading {
    @include absoluteCenter();
  }

  .disabledClass {
    pointer-events: none;
    opacity: 0.4;
  }

  .b-order {
    display: block;
    padding: 4px 21px 24px 16px;

    &.with-timer {
      padding-top: 40px;
    }

    &__uneditable {
      pointer-events: none;
    }

    @media (max-width: $mobile) {
      margin-bottom: 56px;
      padding: 0 8px 16px 8px;
    }
  }

  .order-head {
    display: block;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    border: 1px solid $color-border;
    @include borderBox();
  }

  .order-info {
    @include borderBox();
    display: block;
    padding: 16px;

    @media (max-width: $mobile) {
      padding: 12px;
    }
  }

  .order-tag {
    @include ib();
    @include arial-bold();
    height: 20px;
    padding: 0 8px;
    text-align: center;
    line-height: 19px;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 4px;
    letter-spacing: 1px;
    border: 1px solid $color-tertiary;
    color: $color-icony;

    + .order-tag {
      margin-left: 10px;
    }
  }

  .order-name {
    display: block;
    .name {
      @include ib();
      @include arial-bold();
      font-size: 24px;
      color: $color-main;
      padding-top: 8px;
    }
  }

  .order-flag {
    @include ib();
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-right: 8px;
    margin-top: 5px;
    .flag {
      display: block;
      height: 16px;
      width: 16px;
      position: absolute;
      left: 0;
      top: 1px;
      transform: scale(1.3);
    }
    .langIcon {
      @include borderRadiusTop(50%);
      @include borderRadiusBottom(50%);
      width: 25px;
      height: 25px;
    }
  }

  .order-date {
    margin-top: 14px;
    .item {
      @include ib();
      @include arial-regular();
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $color-secondary;
      margin-right: 64px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bold {
      font-weight: bold;
    }
    .accent {
      color: $color-accent;
    }

    .value {
      white-space: nowrap;
    }
  }

  .order-collapse-table {
    margin-top: 16px;
  }

  .offMode__table {
    @include borderBox();
    @include flex();
    @include flexJustify(space-between);
    @include flexWrap(wrap);
    padding: 12px 16px 29px 16px;
    &-column {
      display: block;
      width: 50%;
    }
    &-row {
      @include zeroFont();
      display: flex;
      align-items: flex-start;
      margin-top: 12px;
      &.full {
        margin-top: 16px;
      }
      &-name, &-value {
        @include ib();
        @include arial-regular();
        letter-spacing: 0.4px;
        margin-top: 0;
      }
      &-name {
        font-size: 13px;
        color: $color-secondary;
        width: 30%;
      }
      &-value {
        font-size: 15px;
        color: $color-main;
        width: 34%;
      }
    }
  }

  .map {
    display: block;
    position: relative;
    height: 48px;
    overflow: hidden;
    z-index: 10;
    &.big {
      height: 240px;
    }
    .map-img {
      display: block;
      @include absoluteCenter();
    }
  }

  .order-form {
    display: block;
    padding: 16px;
  }

  .order-input {
    margin-right: 24px;
    margin-bottom: 28px;
    vertical-align: top;
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }

    @media (max-width: $mobile) {
      margin-right: 0;
    }
  }

  .order-checkbox {
    margin-right: 16px;
    &:last-child {
      margin-right: 0;
    }
  }

  .collapse-head-button {
    position: absolute !important;
    right: 10px;
    top: 10px;
  }

  .tabs {
    @include flex();
    @include flexJustify(flex-start);
    position: relative;
  }

  .country-form {
    @include borderBox();
    display: block;
    padding: 24px 16px;
    .country-input {
      margin-right: 16px;
      vertical-align: middle;
    }
  }

  .country-form-label {
    @include borderBox();
    @include arial-bold();
    color: $color-secondary;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
    width: 192px;
  }

  .bubble-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .b-profile-info-panel {
    display: block;
    @include normalLine();
    @include borderBox();
    position: fixed;
    top: 0;
    bottom: 0;
    left: 96px;
    border-right: 1px solid
    fade($color-tertiary, 0.6);
    width: 312px;
    padding: 8px 8px 0;
    z-index: 9999;
  }

  .image {
    display: block;
    position: relative;
    overflow: hidden;
    z-index: 10;
    height: 200px;
    @include borderRadiusTop(4px);
  }

  .image-img {
    display: block;
    @include absoluteCenterHorizontal();
    height: 100%;
    width: auto;
    top: 0;
  }

  .user-id {
    @include ib();
    @include arial-bold();
    color: $color-icony;
    letter-spacing: 1px;
    font-size: 10px;
    text-transform: uppercase;
    margin-left: 8px;
  }

  .b-call-panel {
    @include borderBox();
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 312px;
    background-color: lighten($color-tertiary, 20%);
    z-index: 10001;
    //border-left: 1px solid #D4D4D8;
    z-index: 101;
    &.inactive {
      .overlay {
        display: block;
      }
      .tab {
        &.active {
          border-color: $color-secondary;
          .text {
            color: $color-secondary;
          }
        }
      }
    }

    @media (max-width: $tablet) {
      position: fixed;
      transform: translateX(400px);
      z-index: 9999;
      transition: all 0.2s ease-in-out;

      &.visible {
        display: block;
        transform: translateX(0);
      }
    }

    @media (max-width: $mobile) {
      width: 100%;
      transform: translateX(100%);
    }
  }

  .overlay {
    z-index: 9999;
    pointer-events: none;
    @include fullSize();
    background-color: fade($color-contrast, 0.6);
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in;

    &.visible {
      visibility: visible;
      opacity: 1;
    }
  }

  .tabs-container {
    display: block;
    position: relative;
    height: 100%;
    &.active {
      display: block;
    }
  }

  .tab {
    height: 48px;
    line-height: 47px;
    display: block;
    width: 50%;
    border-bottom: 2px solid $color-tertiary;
    text-align: center;
    cursor: pointer;
    @include simpleAnimate(border-color);
    &.active {
      border-color: $color-accent;
      .text {
        color: $color-accent;
      }
    }
    .text {
      font-size: 14px;
      letter-spacing: 0.8px;
      line-height: 1;
      @include arial-bold();
      @include ib();
      position: relative;
      color: $color-main;
      @include simpleAnimate(color);
    }
    .counter {
      @include arial-bold();
      display: block;
      position: absolute;
      top: -10px;
      right: -20px;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      line-height: 15px;
      font-size: 8px;
      letter-spacing: 0;
      color: $color-secondary;
      background-color: $color-contrast;
    }
  }
}

@media (max-width: 328px) {
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    margin: 0.055rem;
  }
  .react-datepicker__month {
    margin: 0.1rem;
  }
}
