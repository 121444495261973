.product_list_items {

    .flex__row {
        pointer-events: none;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        .image_wrapper {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            margin-right: 8px;
            pointer-events: none;
            .ProductImage {
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
    
                .ProductImage__name {
                    display: none;
                }
            }
        }

        .custom_label {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            pointer-events: none;
        }
    }
}