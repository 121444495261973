@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';


.clad-autofill-select__wrapper {
  .input-label::before {
    background-color: lighten($color-tertiary, 20%);
  }

  &.searchable {
    position: relative;
    cursor: pointer;
    height: var(--countrySelect-height);
    width: var(--countrySelect-width);
    display: inline-block;
    text-align: left;

    &.disabled {
      cursor: default;
    }

    &.error {
      input {
        border-color: $color-error;
        color: $color-error;
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
          color: $color-error;
          opacity: 1;
        }
      }
      .input-label-text {
        color: $color-error;
      }
    }

    .select-popup {
      top: 100%;
      margin-top: 4px;
      left: 0;
      width: 100%;
      overflow-x: hidden;
      @include normalLine();
      ul {
        padding: 4px 0;
        margin: 0;
        li {
          @include borderBox();
          line-height: 1.3em;
          padding: 8px 16px;
          @include gilroy-regular();
          font-size: 13px;
          color: $color-main;
          list-style: none;
          &.result:hover {
            cursor: pointer;
            background-color: fade($color-accent, 0.4);
          }

          &.no-results {
            cursor: default;
          }
          &.result_active {
            background: $color-accent;
            color: $color-contrast;
          }
        }
      }
    }

    &.focus {
      input {
        padding: 0 15px;
        border: 2px solid $color-accent;
        background-color: $color-contrast;
      }

      .input-label-text {
        color: $color-accent;
      }

      .input-label::before {
        background-color: $color-contrast;
      }
    }

    &.has-value {
      input {
        background-color: $color-contrast;
      }

      .input-label::before {
        background-color: $color-contrast;
      }
    }

    input {
      @include arial-regular();
      @include borderReset();
      @include borderBox();
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 4px;
      border: 1px solid $color-tertiary;
      padding: 0 16px;
      color: $color-secondary;
      font-size: 14px;
      letter-spacing: 0.2px;
      background-color: lighten($color-tertiary, 20%);
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder,
      &:-moz-placeholder {
        color: $color-secondary;
        opacity: 1;
      }
    }

    &.invalid {
      input {
        border: 1px solid $color-error;
      }

      .input-label-text {
        color: $color-error;
      }
    }

    .checkIcon,
    .arrow {
      position: absolute;
      right: 11px;
      will-change: transform;
      path {
        fill: $color-secondary;
      }
    }

    .checkIcon {
      width: 17px;
      height: 17px;
      top: 16px;
    }

    .arrow {
      top: 13px;
      width: 8px;
      height: 20px;
      &.arrow--up {
        transform: rotate(-180deg);
      }
    }
  }
  &.isKlader {
    input {
      border-color: $color-common-green;
    }
  }
}
