@use '@styles/core/breakpoint' as *;

.ChangeOrderButtonsInModal {
  padding: 0 20px 20px;
  width: 400px;

  @media (max-width: $mobile) {
    max-width: 100%;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    > div:nth-child(2n) {
      justify-content: flex-end;
    }

    > div:nth-child(2n+1) {
      justify-content: flex-start;
    }

    .e-button__wrapper {
      flex-basis: 50%;
      min-width: 128px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 16px;

      .e-button {
        width: calc(100% - 8px) !important;
        min-width: 128px;
      }
    }

    .ButtonWithSplit {
      flex-basis: 50%;
      .e-button__wrapper {
        flex-basis: 50%;
        min-width: 128px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 16px;

        .e-button {
          width: 90% !important;
          min-width: 128px;
        }
      }
    }

    .change-queue-btn-wrapper, .approve-btn-wrapper {
      flex: 1;
      flex-basis: 100%;

      .e-button__wrapper {
        width: 100% !important;

        .e-button {
          width: 100% !important;
        }
      }
    }

    .ButtonWithSplit.full-width {
      flex-basis: 100%;
      .e-button__wrapper {
        width: 100% !important;

        .e-button {
          width: 100% !important;
        }
      }
    }
  }
}
