.currentProducts__table {
  display: block;

  .preview_table__controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 60px;
    padding: 0px 15px;

    &--details {
      text-align: left;
      width: 100%;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $color-grey-additional-2;

      .product_name {
        color: $color-grey-additional;
        font-weight: bold;
      }
    }

    .e-button__wrapper {
      margin-left: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      .e-button {
        padding: 0 6px;
        border-radius: 4px;
        height: 32px;
        line-height: 32px;

        .link {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 32px;
        }
      }
    }

    .cancel_detail {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 10px;

      &:hover {
        cursor: pointer;

        svg {
          path {
            fill: $color-error;
          }
        }
      }
    }
  }

  .no-items {
    text-align: center;
    font-size: 16px;
    @include arial-bold();
  }

  .promotionBuilder_optional_gift {
    top: 10px;
    .currentProducts__actions {
      .e-button__wrapper {

        .v-type-2 {
          &:hover {
            &:before {
              background-color: $color-accent;
              border: 1px solid $color-accent;
            }

            .link {
              color: $color-contrast;
            }
          }
        }

        .v-type-2 {
            &:before {
              border-width: 1px;
            }
        }

        .e-button {
          height: 24px;
          line-height: 24px;

          .link {
            @include roboto-regular();
            color: #3A3A3A;
            font-size: 10px;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .currentProducts__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    .swapItemButton, .collapseItemButton {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transform: rotate(-360deg);
      @include simpleAnimateZeroPointTwoSecs(all);

      &:hover {
        svg {
          path {
            fill: $color-accent;
          }
        }
      }
    }

    .opened {
      transform: rotate(-180deg);

      svg {
        path {
          fill: $color-accent;
        }
      }
    }
  }

  .currentProducts__add-selector {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .wrapper__add_from_catalog {
    padding: 35px 0 15px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;

    .icon__add_from_catalog {
      margin-bottom: 8px;
    }

    .heading_add_from_catalog {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: $color-grey-additional;
      margin-bottom: 8px;
    }

    .text_add_from_catalog {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $color-grey-additional;
      margin-bottom: 8px;
    }
  }

  &-noProducts {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(center);
    @include roboto-bold();
    font-size: 14px;
    line-height: 16px;
    vertical-align: middle;
    margin: 15px 0;
    color: $color-error;
  }

  &-head {
    display: block;
    text-transform: uppercase;
    @include zeroFont();
    @include oneLine();
    min-height: 24px;
    line-height: 24px;
    background-color: fade($color-tertiary, 0.2);

    .currentProducts__table-col {
      @include arial-bold();
      font-size: 10px;
      letter-spacing: 0.4px;
      color: $color-secondary;

      &:first-child {
        padding-left: 20px;
      }
    }
  }

  &-container {
    display: block;

    .currentProducts__table-container-rows {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      display: block;
      @include arial-bold();
      color: $color-secondary;
      font-size: 12px;
      letter-spacing: 0.4px;
      margin-bottom: 4px;
    }
  }

  .ReactCollapse--collapse {
    transition: height 200ms;
    margin-bottom: -1px;
  }

  transition: all 0.2s ease-in-out;
  .mandatory-promo-added {
    background: #d0f3d9!important;
  }

  .composition-set {

    background: $color-surface;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
    border-top-right-radius: unset;
    border-top-left-radius: unset;

    &:last-child {
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
      border-top-right-radius: unset;
      border-top-left-radius: unset;
    }

    &:first-child {
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;
      border-top-right-radius: unset;
      border-top-left-radius: unset;
    }

    .currentProducts__table {
      &-col {
        &-first {
          padding-left: 115px;

        }
      }

      &-avatar {
        left: 40px;
      }
    }
  }

  &-row {
    display: block;
    position: relative;
    height: 64px;
    line-height: 62px;
    border-top: 1px solid $color-border;

    &.promoApplied {
      height: 130px;
    }

    &.EmptyValueForMainProducts {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      text-align: center;
      padding: 10px;
      font-family: arial, sans-serif;
      font-weight: bold;
      color: #e6492d;
      border-color: #e6492d !important;
      line-height: 16px;
      border-top: none;
    }

    &:first-child {
      @include borderRadiusTop(4px);
    }

    &:last-child {
      @include borderRadiusBottom(4px);
      //border-bottom: 1px solid $color-tertiary;
    }

    &:first-child {
      .currentProducts__table-avatar {
        @include borderRadiusTopLeft(4px)
      }
    }

    &:last-child {
      .currentProducts__table-avatar {
        @include borderRadiusBottomLeft(4px)
      }
    }
  }

  &-promotionGifts {
    @include flex();
    @include flexAlignItems(center);
    width: 100%;
    height: 50%;

    margin-left: 5px;

    &--imageWrapper {
      display: block;
      width: 64px;
      height: 64px;
      overflow: hidden;
    }
  }

  &-promotionGiftContent {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(flex-start);
    height: 100%;
  }

  &-promoTitle {
    font-size: 9px;
    @include roboto-regular();
    color: #3E3F42;
    letter-spacing: 0.2px;
    line-height: 14px;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    width: 95%;
    overflow: hidden;
    margin-top: 0;
  }

  &-promoInfo {
    display: flex;
    align-items: center;

    .currentProducts__table-promoTitle {
      width: 70%;
    }

    .removePromo__button {
      width: 30%;
      height: 20px;
      background-color: red;
      padding: 3px;
      border-radius: 3px;
      text-align: center;
      color: white;
      font-weight: bold;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  &-promoQuantity {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &-promotionGiftInfo {
    @include flex();
    @include flexAlignItems(center);
    @include flexDirection(column);
    @include flexJustify(center);

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 10px;
      line-height: 0;
      font-size: 10px;
      margin-bottom: 3px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-product--info {
    height: 100%;

    &.promoApplied__col {
      height: 50%;
    }

    .highlight_collapsed {
      display: block;
      position: absolute;
      width: 9px;
      z-index: 10;
      top: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;

      &:before {
        content: "";
        position: absolute;
        border-right: 2px $color-accent solid;
        height: 100%;
        width: 100%;
        -webkit-transform: translateX(-47%);
        -moz-transform: translateX(-47%);
        -o-transform: translateX(-47%);
        transform: translateX(-47%);
      }
    }

    .blur {
      background: rgba(255, 255, 255, 0.2);
      mix-blend-mode: normal;
      backdrop-filter: blur(2px);
      width: auto;
      position: absolute;
      z-index: 11;
      left: 1px;
      right: 1px;
      top: 1px;
      bottom: 1px;

      .button_wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 15px;
        height: 60px;

        .remove_fully {
          margin-right: 17px;
        }

        .removed_notification {
          width: 100%;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.5px;
          text-align: center;
          padding: 0 10px;
        }

        .e-button__wrapper {
          display: flex;
          align-items: center;
          margin-right: 15px;

          .e-button {
            &:before {
              border: 1px solid $color-accent;
            }

            &:hover {
              .link {
                color: $color-contrast;
              }
            }

            .link {
              color: $color-accent;
            }
          }
        }
      }
    }

    &.EmptyValueForMainProducts {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      text-align: center;
      padding: 10px;
      font-family: arial, sans-serif;
      font-weight: bold;
      color: #e6492d;
      border-bottom: 1px solid #BCBCBC;
      margin-bottom: 10px;
      border-color: #e6492d !important;
      line-height: 16px;
    }

    &:first-child {
      .currentProducts__table-avatar {
        @include borderRadiusTopLeft(4px)
      }
    }

    &:last-child {
      .currentProducts__table-avatar {
        @include borderRadiusBottomLeft(4px)
      }
    }
  }

  &-col {
    @include borderBox();
    @include ibt();
    height: 100%;
    position: relative;
    @include normalLine();

    &-first {
      padding-left: 80px;
    }

    &-last {
      position: absolute;
      right: 0;
      top: 0;
      text-align: right;
      padding-right: 16px;
    }

    &-price-col {
      min-width: 110px;
    }

    &-1 {
      width: 5%;
    }

    &-2 {
      width: 20%;
      text-align: left;
    }

    &-3 {
      width: 30%;
    }

    &-4 {
      width: 40%;
    }

    &-5 {
      width: 50%;
    }

    &-6 {
      width: 60%;
    }

    &-7 {
      width: 70%;
    }

    &-8 {
      width: 80%;
    }

    &-9 {
      width: 90%;
    }

    &-10 {
      width: 100%;
    }
  }

  &-restore {
    display: block;
    position: absolute;
    width: 35px;
    height: 62px;
    z-index: 10;
    top: 0;
    left: 0;
    overflow: hidden;

    &:before { /* vertical gray line */
      content: '';
      position: absolute;
      border-right: 1px $color-border solid;
      height: 100%;
      width: 100%;
      transform: translateX(-47%);
    }

    &:after { /* small or big circle */
      content: "";
      width: 7px;
      height: 7px;
      border-radius: 50%;
      border: 1px $color-border solid;
      display: block;
      background: $color-surface;
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-restore-active {
    display: block;
    position: absolute;
    width: 35px;
    height: 62px;
    z-index: 10;
    top: 0;
    left: 0;
    overflow: hidden;

    .RestoreIconSvg {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      height: 100%;
      z-index: 12;

      &:hover {
        cursor: pointer;
      }

      g {
        &:hover {
          g {
            path {
              fill: $color-contrast;
            }

            circle {
              fill: $color-accent;
            }
          }
        }
      }
    }

    &:before { /* vertical gray line */
      content: '';
      position: absolute;
      border-right: 1px $color-border solid;
      height: 100%;
      width: 100%;
      transform: translateX(-47%);
      z-index: -1;
    }
  }

  &-avatar {
    display: block;
    position: absolute;
    width: 64px;
    height: 62px;
    z-index: 10;
    top: 0;
    left: 5px;
    overflow: hidden;

    &-image {
      @include absoluteCenter();
      display: block;
      height: 100%;
      width: 100%;
      object-fit: contain;
      padding: 5px;
    }
  }
  &-product--name-flex {
    @include roboto-regular();
    color: #3E3F42;
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 14px;
    width: 95%;
    margin-top: 8px;
    display: flex;
    flex-wrap: nowrap;

    .product-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 5px;
    }

    .mandatoryGift {
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        path {
          fill: #99A2AD;
        }
      }
    }

    .mandatoryPromo {
      width: 14px;
      height: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        path {
          fill: #99A2AD;
        }
      }
    }
  }

  &-product--name {
    &.promotionBuilder-placeholder {
      color: #99A2AD;
    }

    @include roboto-regular();
    color: #3E3F42;
    font-size: 12px;
    letter-spacing: 0.2px;
    line-height: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 95%;
    margin-top: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    .appliedPromo__title {
      color: red;
      font-weight: bold;
      height: 20px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-product--quantity {
    @include roboto-regular();
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 6px;
    letter-spacing: 0.4px;
    position: absolute;
    bottom: 0;
    align-items: center;

    .flex-inner {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      top: 0;
      left: 0;
    }

    .product_quantity_image {
      width: auto;
      height: 100%;
      margin: 0 5px;
      cursor: help;

      .quantity__img {
        height: 8px;
        width: 18px;
      }
    }

    .product_quantity_spec {
      width: 80%;
      height: 100%;
      color: $color-grey-additional;
      cursor: help;
    }

    .out_of_stock {
      cursor: initial !important;
      color: $color-error;
      width: 95%;
      margin-top: 8px;
      white-space: nowrap;
    }
  }

  &-text-label--webkit {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 3px;
  }

  &-text {
    @include ib();
    @include roboto-regular();
    color: #3E3F42;
    font-size: 15px;
    letter-spacing: 0.2px;
    line-height: 16px;

    &--bold {
      @include roboto-bold();
    }
  }

  &--addGift-block {
    @include flex();
    @include flexAlignItems(center);
    @include flexJustify(flex-end);
  }

  &-add-gift-button {
    vertical-align: middle;
    margin-right: 8px;
  }

  .similarOrders-head {
    &.currentProducts__table-head {
      .currentProducts__table-col {
        width: 10%;

        &:first-child {
          padding-left: 10px;
          width: 15%;
        }

        &:nth-child(3),
        &:nth-child(4),
        {
          width: 20%;
        }

        &:last-child,
        {
          width: 15%;
        }
      }
    }
  }

  .similarOrders-body {
    .currentProducts__table-text {
      font-size: 13px;
    }

    &__approve {
      background: #EAFFF1;
    }

    .currentProducts__table-row {
      min-height: 64px;
      height: auto;

      .currentProducts__table-col {

        width: 10%;

        &:first-child {
          width: 15%;
          .currentProducts__table-text {
            padding-left: 10px;
          }
        }

        &:nth-child(3),
        &:nth-child(4),
        {
          width: 20%;
        }

        &:last-child,
        {
          width: 15%;
        }
      }
    }
  }
}

.currentProducts__table-text__price-input {
  @include ib();
  @include arial-regular();
  @include borderReset();
  @include borderBox();
  color: #000;
  height: 40px;
  border: 1px solid #E5E5EA;
  width: 100px;
  letter-spacing: 0.2px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: left;
  font-size: 15px;
  line-height: 40px;
  border-radius: 4px;

  &:focus {
    border-color: #007AFF;
    outline: 1px solid #007AFF;
  }
}

.currentProducts__promo_condition_info {
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
  height: 100px;
  display: flex;
  align-items: center;
  color: #8E8E93;
  @include arial-regular();
  font-weight: bold;
}

.swapProduct__panel {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background: #FFFFFF;
  mix-blend-mode: normal;
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 64px;
  border-top: 1px solid $color-accent;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 0 10px;

  &.replacePromo {
    border-top: none;
    justify-content: flex-end;
    box-shadow: none;
  }

  &--item {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    height: 90%;
    width: 32%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 3px;
    background: $color-surface;

    .product_name {
      width: 80%;
      text-align: left;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      padding: 0 5px;

      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1.5px;
      color: $color-grey-additional;
    }

    .image_wrapper {
      width: auto;
      padding: 0 10px;

      .box_img {
        width: 40px;
        height: 39px;
      }
    }
  }

  .choosed {
    background: rgba(22, 101, 216, 0.1);
  }

  &--icon {
    width: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--btn {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .e-button__wrapper {
      width: 100px;

      .v-size-1 {
        line-height: 32px;
      }

      .e-button {
        width: 100%;
        height: 32px;
      }
    }
  }

  &--close {
    width: 8%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      svg {
        path {
          fill: $color-error;
        }
      }
    }
  }
}

.currentProducts_items_to_add {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 16px;

  @media (max-width: $tablet) {
    margin-top: 5px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .header {
    font-size: 17px;
    line-height: 20px;
    color: #8E8E93;
    @include arial-regular();
    font-weight: bold;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .crutch {
    .ProductSetToAdd__wrapper {
      &:last-child {
        margin-bottom: 170px;
      }
    }

    .ProductItemToAdd__wrapper {
      margin: 15px 10px;

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }

  .items {
    display: flex;
    min-height: 100px;
    flex-wrap: wrap;
    padding-top: 12px;

    .no-items {
      min-height: 100px;
      display: flex;
      align-items: center;
      color: #8E8E93;
      @include arial-regular();
      font-weight: bold;
      justify-content: center;
      width: 100%;
    }

    .search-helper {
      min-height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #8E8E93;
      @include arial-regular();
      margin: auto;
      width: 100%;
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}


.currentProducts__action_buttons {
  display: flex;
  justify-content: flex-start;
  margin-top: 14px;
  margin-bottom: 14px;
  flex-direction: row;
  flex-wrap: wrap;

  .products_search {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
    width: 25px;
    margin-bottom: 8px;
    margin-right: 8px;
    user-select: none;
    z-index: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border-radius: 4px;
    background: #FFFFFF;
    border-color: #1665D8;
    z-index: 10;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;

    .searchIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s 0.2s ease-in-out;

      &:hover {
        & svg {
          & path {
            fill: #1665D8;
          }
        }
      }
    }

    .closeIcon {
      display: none;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      transition: all 0.2s 0.2s ease-in-out;

      .CrossIconSvg {
        width: 13px;
        height: 13px;
      }

      &:hover {
        & svg {
          & path {
            fill: $color-error;
          }
        }
      }
    }

    .searchInput {
      border: none;
      background: none;
      outline: none;
      float: left;
      padding: 0;
      color: #000;
      font-size: 16px;
      transition: all 0.2s 0.2s ease-in-out;
      line-height: 40px;
      width: 0;
    }
  }

  .e-button__wrapper {
    .e-button {
      padding: 0 15px;
      border-radius: 16px;
    }
  }

  .e-button__wrapper {
    margin-bottom: 8px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .e-button__wrapper {
    .v-type-0 {
      &:before {
        border-radius: 16px;
      }
    }
  }
}

.search_mode {
  .products_search {
    width: 100%;
    border: 1px solid #1665D8;
    padding: 0px 5px;

    .searchInput {
      width: 100%;
      padding: 0 6px;
    }

    .searchIcon {
      & svg {
        & path {
          fill: #1665D8;
        }
      }
    }

    .closeIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s 0.2s ease-in-out;
    }
  }
}

@media (max-width: 1147px) {

  .currentProducts {

    &__form-wrapper {
      clip-path: inset(0 0 0 0);
    }

    &__table {
      width: 1100px;
    }
    &__similar-order-table-wrapper {
      overflow-x: auto; /* Включает горизонтальный скролл */
      width: calc(100vw - 63px - 312px - 30px); /* ширина обертки для таблицы похожих заказов */
    }

  }

}

@media (max-width: 970px) {
  .currentProducts__similar-order-table-wrapper {
    width: calc(100vw - 63px - 40px); /* Максимальная ширина контейнера */
  }
}
