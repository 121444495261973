@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.billing-list-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 23px;
  color: $color-grey-additional;
  font-family: Roboto;


  &__tabs {
    display: flex;
  }

  &__tab {
    font-size: 11px;
    line-height: 12px;
    padding: 6px 10.5px;
    box-shadow: 0px 1.6px 0px $color-shadow;
    cursor: pointer;

    &_active {
      color: $color-test-border-hover;
      box-shadow: 0px 1.6px 0px $color-test-border-hover;
    }
  }

  &__svg {
    cursor: pointer;
  }

  &__periods {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 33px;
    width: 100%;
  }

  &__period {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__date {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    line-height: 12px;
    margin-left: 7px;
  }
}