@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.billing-list-page {

  background: #F5F7FB;
  height: 100vh;
  padding-left: 104px;
  padding-top: 20px;

  &__header {

    max-width: 1232px;
    min-height: 144px;
    background: $color-contrast;
    border: 1px solid #D5D6D8;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
  }

  &__button {
    margin: 44px 0 0 auto;
    padding-right: 14px;
  }
}