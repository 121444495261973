@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';

.choosed {
  .PromotionToApply__product_image__wrapper {
    border-color: $color-accent!important;
    cursor: initial;
  }
}

.hovered {
  z-index:3!important;

  .PromotionToApply__product_image__wrapper {
    z-index: 3!important;
  }

  .ProductImage {
    z-index: 3!important;
    .PromotionToApply__product_image {
      z-index: 3!important;
    }
  }

  .PromotionToApply__product_gallery__wrapper {
    z-index: 3!important;
    .ProductImage {
      z-index: 3!important;
    }
  }

  .PromotionToApply__product_name {
    z-index: 3!important;
  }

  .PromotionToApply__product_price_wrapper {
    z-index: 3!important;
  }
  .PromotionToApply__product_type {
    z-index: 3!important;
  }
  .PromotionToApply_curtain {
    z-index: 2!important;
  }
}

.PromotionToApply__wrapper {
  z-index: 2;
  height: 196px;
  width: 128px;
  transition: all 0.2s ease-in-out;
  margin: 6px;
  box-shadow: none;
  position: relative;

  .PromotionToApply__product_image__wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 196px;
    width: 128px;
    border: 1px solid #D1D1D6;
    background: #fff;
    border-radius: 4px;
    z-index: 2;

    .ProductImage {
      height: 68px;
      margin-top: 24px;
      z-index: 2;
      .PromotionToApply__product_image {
        z-index: 2;
        width: 100%;
        height: 68px;
        object-fit: contain;
        padding: 4px;
        transition: all 0.2s ease;
      }
    }

    .PromotionToApply__product_gallery__wrapper {
      display: flex;
      flex-direction: row;
      height: 32px;
      margin-top: -24px;
      z-index: 2;
      .ProductImage {
        height: 32px;
        width: 32px;
        border: 1px solid #F3F3F3;
        box-sizing: border-box;
        border-radius: 0;
        color:#fff;
        z-index: 2;
        .PromotionToApply__product_image {
          height: 32px;
        }
        .blur {
          background: rgba(255, 255, 255, 0.2);
          mix-blend-mode: normal;
          backdrop-filter: blur(2px);
          width: auto;
          position: absolute;
          z-index: 11;
          left: 1px;
          right: 1px;
          top: 1px;
          bottom: 1px;

          .remaining_number {
            @include roboto-regular;
            font-style: normal;
            font-size: 10px;
            line-height: 12px;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50% , -50%);
            -webkit-transform: translate(-50%, -50%);
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
          }
        }
      }
    }
  }

  .PromotionToApply__product_name {
    z-index: 2;
    @include roboto-bold();
    font-style: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: $color-main;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    padding: 8px;
    width: 128px;
    top: 120px;
    position: absolute;
  }

  .PromotionToApply__product_price_wrapper {
    z-index: 2;
    display: flex;
    padding: 8px;
    width: 128px;
    top: 137px;
    position: absolute;

    svg {
      max-width: 14px;
      min-width: 14px
    }

    .product_price {
      @include roboto-regular();
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.4px;
      color: $color-grey-additional;
      padding-left: 4px;
      max-width: 100px;
      min-width: 43px;
      width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .e-button__wrapper {
    position: absolute;
    top: 168px;
    z-index: 3;
    .v-size-0 {
      height: 28px;
      line-height: 28px;
    }

    .link {
      @include roboto-regular();
      font-weight: 500;
      text-transform: uppercase;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .e-button {
      @include simpleAnimateZeroPointTwoSecs(all);
      &:before,
      &:after {
        @include simpleAnimateZeroPointTwoSecs(all);
      }
    }

    .link {
      @include simpleAnimateZeroPointTwoSecs(all);
      svg {
        path {
          @include simpleAnimateZeroPointTwoSecs(all);
        }
      }
    }

    .e-button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-color: transparent;
      &::before {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-color: transparent;
        border-top: 1px solid #D1D1D6;
      }
    }
  }


  .PromotionToApply__product_type {
    z-index: 2;
    position: absolute;
    width: auto;
    max-width: 152px;
    left: 8px;
    top: 8px;
  }

  .PromotionToApply_curtain {
    z-index: 1;
    width: 0px;
    height: 196px;
    border: 1px solid #D1D1D6;
    background: #fff;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    position: absolute;
    top: 0;
    left: 125px;
    transition: all 0.2s ease-in-out;

    .popup__wrapper .e-popup {
      visibility: visible;
      opacity: 1;
    }

    .PromotionToApply__popup {
      top: 0;
      margin-top: 1px;
      left: 0;
      width: 100%;
      overflow-x: hidden;
      @include normalLine();
      border-radius: 0;
      background-color: none;
      border-top: 0 solid transparent;
      border-bottom: 0 solid transparent;
      border-left: 0 solid transparent;
      border-right: 0 solid transparent;
      box-shadow: none;

      ul {
        padding: 5px 0;
        margin: 0;

        li {
          @include roboto-regular();
          font-style: normal;
          @include borderBox();
          padding: 0 8px 10px 8px;
          font-size: 12px;
          line-height: 14px;
          color: $color-grey-additional;
          list-style: none;

          &:last-child {
            margin-top: 2px;
          }

          .li_row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .product_icon {
              width: 12px;
              height: 12px;
              display:flex;
              align-items: center;
              justify-content: center;
              margin-right: 4px;
            }

            .price_icon {
              margin-left: 16px;
              margin-right: 4px;

              svg {
                width: 12px;
                height: 12px;
              }
            }

            .product_name_and_quantity {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }

            .product_price {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              width: 59%;
            }

            .product_discount {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              color: $color-error;
            }
          }

          &.entering {
            opacity: 0;
            transition: all 0.2s 0.2s ease-in-out;
          }

          &.entered {
            opacity: 1;
            transition: all 0.2s 0.2s ease-in-out;
          }

          &.exiting {
            transition-delay: 0s;
            opacity: 0;
          }

          &.exited {
            display: none;
          }
        }
      }
    }
  }

  &:hover {
    .PromotionToApply_curtain {
      box-shadow: 0 1px 12px rgba(0, 0, 0, 0.15);
      left: 127px;
      width: 192px;
    }

    .PromotionToApply__product_image__wrapper {
      box-shadow: -5px 1px 7px rgba(0, 0, 0, 0.15);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    .e-button {
      border-bottom-right-radius: 0;
      &::before {
        border-bottom-right-radius: 0;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.6;

    .e-button__wrapper {
      .e-button {
        &:before {
          background: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}


// стиль для скроллбара
.thumb-vertical-promotions {
  background: #000000;
  opacity: 0.1;
  border-radius: 2px;
  width: 4px!important;
}

.thumb-horizotnal-promotions {
  background: transparent;
}