@mixin arial-regular() {
  font-family: arial, sans-serif;
  font-weight: normal;
}

@mixin arial-bold() {
  font-family: arial, sans-serif;
  font-weight: bold;
}

@mixin gilroy-bold() {
  font-family: 'gilroy', sans-serif;
  font-weight: bold;
}

@mixin gilroy-regular() {
  font-family: 'gilroy', sans-serif;
  font-weight: normal;
}

@mixin roboto-regular() {
  font-family: 'roboto', sans-serif;
  font-weight: normal;
}

@mixin roboto-bold() {
  font-family: 'roboto', sans-serif;
  font-weight: bold;
}

