$color-main: #3E3F42;
$color-secondary: #6B6C6F;
$color-tertiary: #BCBCBC;
$color-contrast: #FFFFFF;
$color-accent: #1665D8;
$color-accent-dark: #0D47A1;
$color-success: #34C759;
$color-error: #FF3B30;
$color-warning: #F0A92E;
$color-info: #4282DE;
$color-expiried: #E6D84E;
$color-icony: #8E8E93;
$color-bg: #F3F3F3;
$color-surface: #F8F8F8;
$color-border: #D4D4D8;
$color-bg-grey: #F2F6F8;
$color-grey-additional: #3A3A3A;
$color-grey-additional-2: #7C7C7C;
$color-primary-light-blue: #90CAF9;
$color-gray-text: #99A2AD;
$color-test-border: #D5D6D8;
$color-test-border-hover: #0060FF;
$color-shadow: #D8D8DA;
$color-light-gray: #B8C1CC;
$color-light-blue: #6FA0F2;
$color-common-green: #008000;
$background-gray: #F5F7FB;
$color-green: #00CC6D;
$color-bright-red: #FF3A47;
$background-training-room: #F5F7FB;
$background-warning: #F3E8E8;