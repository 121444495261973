@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.bonusItem {
  height: 196px;
  width: 128px;
  border-radius: 4px;
  background-color: $color-icony;
  margin-right: 8px;
}
