@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/breakpoint' as *;

.OrderTableMobile {
  display: none;
  @media (max-width: $orderBlockBreakpoint) {
    display: block;
  }

  .tabs {
    width: 100%;
    overflow: auto;
    margin-top: 6px;
    position: sticky;
    top: 0;
    z-index: 1;
    background: $color-contrast;

    .tab {
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 56px;
      color: #7C7C7C;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 16px;
      height: 32px;
      border-bottom: 1px solid #D4D4D8;

      &.active {
        color: $color-accent;
        border-bottom: 1px solid $color-accent;
      }
    }
  }

  .items-tab-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #D4D4D8;
    padding-top: 4px;
    padding-bottom: 4px;

    .item {
      width: 88px;
      height: 88px;
      border: 1px solid $color-border;
      margin: 4px;
      padding: 4px;
      border-radius: 4px;

      &.disabled {
        pointer-events: none;
        filter: grayscale(1);
        opacity: 0.6;
      }

      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: contain;
        padding: 5px;
      }
    }
  }

  .no-items {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    display: block;
    padding-left: 10px;
    padding-right: 10px;

    &.EmptyValueForMainProducts {
      font-weight: bold;
      color: #e6492d;
      border: 1px solid #e6492d;
      border-radius: 4px;
      margin: 4px;
      padding: 4px;
      line-height: 16px;
      width: auto;
    }
  }

  .label {
    display: inline-block;
    font-size: 12px;
    line-height: 17px;
    border-radius: 4px;
    height: 17px;
    font-weight: bold;
    padding: 0 4px;

    &-gift {
      background-color: rgba(52, 199, 89, 0.2);
      color: #34C759;
    }

    &-promo {
      background-color: rgba(22, 101, 216, 0.2);
      color: #1665D8;
    }
  }

  .tabs-content {
    .add {
      font-family: Roboto, sans-serif;

      .row {
        display: flex;
        width: 100%;
        padding: 8px 12px;

        .add-item-image {
          width: 64px;
          max-width: 64px;
          min-width: 64px;
          height: auto;
          flex: 1;
          background: #F8F8F8;
          border: 1px solid #D4D4D8;
          border-radius: 4px;

          img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: contain;
            padding: 5px;
          }
        }

        .add-item-name {
          padding-left: 8px;
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          font-family: Roboto, sans-serif;
          font-size: 14px;
          color: #3A3A3A;
          height: auto;
        }

        .add-item-close-action-wrapper {
          width: 40px;
          height: auto;
          display: flex;

          .add-item-close-action {
            background: url('./cross.svg') no-repeat center;
            width: 40px;
            height: 40px;
          }
        }
      }

      .control-wrapper {
        padding: 0 16px 0 12px;
        margin: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          color: #8E8E93;
          font-size: 14px;
        }

        .control {
          .price {
            color: #3A3A3A;
            font-size: 16px;
            width: 128px;
            height: 40px;
            padding: 10px 0;
            text-align: right;
          }

          .price-input {
            font-size: 16px;
            color: #3A3A3A;
            width: 128px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #D4D4D8;
            padding: 10px 16px;
          }
        }
      }

      .add-total {
        display: flex;
        font-family: Roboto, sans-serif;
        justify-content: space-between;
        padding-left: 12px;
        padding-right: 20px;
        height: 40px;
        border-top: 1px solid $color-border;

        .title {
          color: #8E8E93;
          font-size: 14px;
          display: flex;
          align-items: center;
        }

        .price {
          display: flex;
          align-items: center;
          font-size: 16px;
          text-align: right;
          font-weight: 500;
        }
      }
    }

    .current-total {
      display: flex;
      font-family: Roboto, sans-serif;
      justify-content: space-between;
      padding-left: 17px;
      padding-right: 20px;
      height: 40px;

      .title {
        color: #8E8E93;
        font-size: 14px;
        display: flex;
        align-items: center;
      }

      .price {
        display: flex;
        align-items: center;
        font-size: 16px;
        text-align: right;
        font-weight: 500;
      }
    }

    .current {
      .current-item {
        display: flex;
        width: 100%;
        min-height: 64px;
        border-bottom: 1px solid $color-border;

        .current-item-price-info {
          display: flex;
          width: 100%;
          &--item {
            flex: 1;
            display: flex;
          }

          &--label {
            font-size: 10px;
            color: #8E8E93;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-weight: 500;
          }

          &--value {
            font-size: 10px;
            letter-spacing: 1.5px;
            font-weight: 500;
          }
        }

        .current-item-image {
          width: 64px;
          max-width: 64px;
          min-width: 64px;
          height: auto;
          background: #F8F8F8;

          img {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: contain;
            padding: 5px;
            max-height: 64px;
          }
        }

        .current-item-name {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          color: #3A3A3A;
          padding-top: 10px;
          padding-bottom: 10px;
          height: auto;
          flex: 1;
          display: flex;
          padding-left: 8px;
          align-items: flex-start;
          flex-direction: column;
          justify-content: space-between;

          .amount {
            span:nth-child(1) {
              color: #8E8E93;
            }
          }
        }

        .current-item-action {
          height: auto;
          width: 50px;
          min-width: 50px;
          cursor: pointer;
          background: url('./trash.svg') no-repeat center;
        }
      }
    }

    .similarOrders {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      color: #3A3A3A;

      .row {
        width: 100%;
        display: flex;
        border-bottom: 1px solid $color-border;
        padding: 4px 20px;

        &.header {
          height: 24px;
          background: #F8F8F8;
          font-weight: 500;

          .col {
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          }
        }

        .col {
          width: 33%;
        }
      }
    }
  }
}
