@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.OrderInformation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    background-color: $color-contrast;
    border: 1px solid $color-border;
    @include borderBox();
    border-radius: 4px;
    width: 100%;
    padding: 0 0 30px;
    margin-bottom: 30px;

    @media (max-width: 1200px) {
        display: none;
    }

    height: 712px;

    .tabs-container {
        background: $color-contrast;
        @include flex();
        @include flexJustify(flex-start);
        width: 100%;
        
        border: 1px solid #D4D4D8;
        border-style: none none solid none;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        .tabs__text {
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 18px;
          @include roboto-regular();
          font-weight: 500;
          @include ib();
          position: relative;
          color: #7C7C7C;
          @include simpleAnimate(color);
        }

        .currentProducts__tab {
          height: 36px;
          line-height: 34px;
          display: block;
          width: auto;
          margin: 0;
          text-align: center;
          cursor: pointer;
          @include simpleAnimate(all);
    
          padding: 0 26px;
    
          &:first-child {
            border-right: 1px solid $color-border;
          }

          &:nth-child(2) {
            border-right: 1px solid $color-border;
          }
    
          &:hover,
          &.active {
            &:after {
              width: 100%;
              opacity: 1;
            }
          }
    
          &:after {
            content: "";
            display: block;
            height: 1px;
            width: 0;
            background: $color-accent;
            border: 1px solid $color-accent;
            opacity: 0;
            margin: auto;
            transition: opacity, width ease-in-out 0.2s;
            border-style: none none solid none;
            margin-top: 1px;
          }
    
          &.active {
            border-bottom: 1px solid $color-accent;
    
            .tabs__text {
              color: $color-accent;
            }
          }
        }

        .currentProducts__tab-link {
          margin-left: auto;

          height: 36px;
          line-height: 34px;
          display: block;
          width: auto;
          text-align: center;
          cursor: pointer;
          @include simpleAnimate(all);
          padding: 0 26px;
          &:hover {
            .tabs__text {
              color: $color-accent;
            }
          }
        }
      }
}
