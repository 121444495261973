.product-benefit__wrapper {
  &.e-benefit {
    display: block;
    position: relative;
    text-align: left;
    background-color: fade($color-tertiary,0.2);
    &:nth-child(2n) {
      background-color: $color-contrast;
    }
  }
  .icon {
    display: block;
    vertical-align: top;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $color-accent;
    position: absolute;
    top: 8px;
    left: 8px;
  }
  .icon-svg {
    filter: invert(68%) sepia(50%) saturate(543%) hue-rotate(86deg) brightness(93%) contrast(85%);
    width: 14px;
    height: 14px;
    top: 5px;
    left: 5px;
    position: absolute;
    display: block;
  }
  .infoProduct__benefit {
    display: block;
    @include borderBox();
    padding: 8px 40px 8px 44px;
  }
  .text {
    @include arial-regular();
    color: $color-secondary;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 16px;
  }
  .title {
    @include arial-bold();
    color: $color-main;
    font-size: 12px;
    letter-spacing: 0.4px;
    margin-bottom: 4px;
  }
}
