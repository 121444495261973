@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;


.BlockWithBonusChips {
  &__title {
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: $color-grey-additional;

    margin-right: 5px;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;

        justify-content: flex-start;

      .v-size-0 {
        height: 24px;
        line-height: 24px;
      }

      .categorySelectionButton {
        margin-right: 8px;
        border-radius: 16px;
        padding: 0 15px;
        background-color: $color-contrast;

        &:before {
          border-radius: 16px;
        }

        .link {
          display: flex;
          align-items: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.01em;

          .circle {
            background-color: $color-bg;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            max-height: 16px;
            margin-left: 5px;
            padding: 0 6px;
          }
        }

        &:before {
          border: none;
        }
      }

      .categorySelectionButton__noGoods {
        background-color: $color-surface;
        opacity: 0.5;
      }

      .categorySelectionButton__active {
        span {
          color: $color-accent;
        }

        &:before {
          border: 1px solid $color-accent;
          border-radius: 16px;
        }

        .circle {
          background-color: $color-accent !important;
          color: $color-contrast;
        }
      }

      .control-toggle {
        margin-left: auto;

        span {
          font-family: Roboto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0.01em;
          text-align: right;
          color: $color-grey-additional;

          margin-right: 13px;
        }

        .chevronIcon__active {
          path {
            fill: $color-icony;
            transition: all .3s ease;
          }
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}


.products_search {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  width: 25px;
  margin-bottom: 3px;
  margin-right: 8px;
  user-select: none;
  z-index: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-radius: 4px;
  border-color: #1665D8;
  z-index: 10;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  .searchIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s 0.2s ease-in-out;

    &:hover {
      & svg {
        & path {
          fill: #1665D8;
        }
      }
    }
  }

  .closeIcon {
    display: none;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    transition: all 0.2s 0.2s ease-in-out;

    .CrossIconSvg {
      width: 13px;
      height: 13px;
    }

    &:hover {
      & svg {
        & path {
          fill: $color-error;
        }
      }
    }
  }

  .searchInput {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: #000;
    font-size: 16px;
    transition: all 0.2s 0.2s ease-in-out;
    line-height: 40px;
    width: 0;
  }

  &.search_mode {
    width: 100%;
    border: 1px solid #1665D8;
    padding: 0px 5px;

    .searchInput {
      width: 100%;
      padding: 0 6px;
    }

    .searchIcon {
      & svg {
        & path {
          fill: #1665D8;
        }
      }
    }

    .closeIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s 0.2s ease-in-out;
    }
  }
}


.entering {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.entered {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.exiting {
  opacity: 0;
}

.exited {
  display: none;
}