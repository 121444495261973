@use '@styles/core/breakpoint' as *;

:root {
    --tfnp-left: 64px;
    --tfnp-right: 312px;
}

@media screen and (max-width: $tablet) {
    :root {
        --tfnp-left: 64px;
        --tfnp-right: 0;
    }
}

@media screen and (max-width: $mobile) {
    :root {
        --tfnp-left: 0;
        --tfnp-right: 0;
    }
}

#top-floating-notification-portal {
    z-index: 10000;
    position: fixed;
    top: 0;
    width: 100%;
    padding-left: var(--tfnp-left);
    padding-right: var(--tfnp-right);
    pointer-events: none;
    > * {
        pointer-events: auto;
    }
}
