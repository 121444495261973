.billing-list-tooltip-content {

  background: rgba(33, 33, 33, 0.75);
  box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 8px 8px 10px;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.4px;
  color: #FFFFFF;
  font-family: Roboto;

  &__row {
    width: 148px;
    display: flex;
    margin-top: 8px;
  }

  &__value {
    font-weight: bold;
    margin-left: auto;
  }
}