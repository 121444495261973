.switch__wrapper {
  .switcher {
    @include ib();
    @include simpleAnimate(background-color);
    width: 40px;
    height: 12px;
    border-radius: 12px;
    position: relative;
    background-color: fade($color-secondary, 0.6);
    cursor: pointer;
  }

  .switcher-handler {
    @include simpleAnimate(all);
    @include borderBox();
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    width: 20px;
    height: 20px;
    top: -4px;
    left: 0;
    background-color: $color-secondary;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1), 0 1px 15px 0 rgba(0, 0, 0, 0.15), 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .switcher-label {
    @include arial-regular();
    @include ib();
    margin-left: 8px;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: $color-main;
  }

  .e-switch {
    &.active {
      .switcher {
        background-color: fade($color-accent, 0.6);
      }
      .switcher-handler {
        background-color: $color-accent;
        left: 20px;
      }
    }
  }
}