@use '@styles/core/breakpoint' as *;
@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';
@use 'core/slick';
@use '@styles/AnimateMe.scss';

@import "~react-datepicker/src/stylesheets/datepicker";
@import "~leaflet/dist/leaflet.css";

@import "components/login/login";
@import 'components/main/profile-page/profile';
@import "components/main/order-page/OrderPage";
@import "components/main/order-page/OrderTable";
@import "components/main/order-page/ProductCard";
@import "components/main/order-page/OrderAddress";
@import "components/main/order-page/CurrentProducts";
@import "components/main/order-page/OrderCustomerInfo";
@import "components/main/finance-page/finance";
@import "components/main/about-page/about";

@import "components/ui-elements/BenefitSlider";
@import "components/ui-elements/Button";
@import "components/ui-elements/Calendar";
@import "components/ui-elements/CallButton";
@import "components/ui-elements/Collapse";
@import "components/ui-elements/CollapseTable";
@import "components/ui-elements/Dialing";
@import "components/ui-elements/FileInput";
@import "components/ui-elements/Flag";
@import "components/ui-elements/ModalOld";
@import "components/ui-elements/ModalWindow";
@import "components/ui-elements/Notify";
@import "components/ui-elements/PriorityTag";
@import "components/ui-elements/ProductBenefit";
@import "components/ui-elements/ShipCheckbox";
@import "components/ui-elements/Spinner";
@import "components/ui-elements/Switch";
@import "components/ui-elements/SwitchUser";
@import "components/ui-elements/TablePagination";
@import "components/ui-elements/Tag";
@import "components/ui-elements/TextArea";
@import "components/ui-elements/TextInput";
@import "components/ui-elements/TextSlider";

@import "components/UserStatistic";

#root {
  height: 100%;
}

html, body {
  height: 100%;
}

html {
  overflow: auto;
}

textarea {
  background-clip: padding-box;
}

input {
  background-clip: padding-box;
}

%callButton {
  @include ib();
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: $color-success;
  cursor: pointer;
}

.hiddenBlock {
  display: none;
}

.column__custom {
  @include borderBox();
  display: block;
  text-align: left;

  &:first-child {
    text-align: center;
  }

  &.col-last__custom {
    margin-left: auto;
  }

  &.col-5__custom {
    width: 5%;
  }

  &.col-8__custom {
    width: 8%;
  }

  &.col-10__custom {
    width: 10%;
  }

  &.col-15__custom {
    width: 15%;
  }

  &.col-20__custom {
    width: 20%;
  }

  &.col-24__custom {
    width: 24%;
  }

  &.col-25__custom {
    width: 25%;
  }

  &.col-30__custom {
    width: 30%;
  }

  &.col-35__custom {
    width: 35%;
  }

  &.col-40__custom {
    width: 40%;
  }

  &.col-45__custom {
    width: 45%;
  }

  &.col-50__custom {
    width: 50%;
  }
}

.table-head__custom {
  @include flex();
  @include flexJustify(flex-start);
  @include flexAlignItems(center);
  height: 40px;
  background-color: fade($color-tertiary, 0.2);

  .column__custom {
    @include arial-regular();
    color: $color-secondary;
    font-size: 10px;
    letter-spacing: 0.4px;
  }
}

.table-row__custom {
  @include flex();
  @include flexJustify(flex-start);
  @include flexAlignItems(center);
  @include borderBox();
  height: 65px;
  border-top: 1px solid $color-tertiary;

  &.pagination {
    height: 56px;
    padding-right: 40px;
    @include flexJustify(flex-end);
  }

  .column__custom {
    @include arial-regular();
    color: $color-main;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: 1.2;
  }
}

.sub-table__custom {
  height: 64px;
  display: block;
  width: 90%;

  .row__custom {
    @include borderBox();
    @include flex();
    @include flexJustify(space-between);
    @include flexAlignItems(center);
    border-bottom: 1px solid fade($color-tertiary, 0.6);
    height: 21px;

    &:last-child {
      border-bottom: 0;
    }

    div {
      &.input-form__auditOrder, &.select-form__auditOrder {
        display: inline-block;
        position: relative;
        margin: 2px 2px;
      }
    }
  }

  .column__custom {
    @include arial-regular();
    display: block;
    font-size: 10px;
    letter-spacing: 0.4px;
    color: $color-main;
    text-align: left;

    &.label {
      width: 14%;
      color: $color-icony;
    }

    &.name {
      width: 46%;
      font-weight: bold;
    }

    &.price {
      width: 16%;
      text-align: right;
    }

    &.count {
      width: 24%
    }
  }
}

.phone {
  line-height: 16px;
}

.name-link {
  font-size: 10px;
  color: $color-icony;
}

.d-none {
  display: none;
}

.-left {
  text-align: left;
}

.preloader {
  @include absoluteCenter();
}

.input-label {
  @include gilroy-regular();
  display: block;
  position: absolute;
  font-size: 10px;
  line-height: 10px;
  left: 12px;
  top: -5px;
  padding: 0 8px;
  color: $color-secondary;
  height: 10px;

  &:before {
    @include pseudoBlock();
    left: 4px;
    bottom: 0;
    top: 5px;
    width: calc(100% - 8px);
    height: 4px;
    background-color: #E8EAEC;
    z-index: 1;
  }
}

.e-text-input {
  &.has-value, &.focus {
    .input-label {
      &:before {
        background-color: $color-contrast;
      }
    }
  }
}

.phone-flag {
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -1px;
}

.comments-count {
  @include ib();
  border-radius: 50%;
  text-align: center;
  height: 32px;
  line-height: 31px;
  width: 32px;
  border: 1px solid $color-tertiary;
}

.input-error {
  @include gilroy-regular();
  display: block;
  position: absolute;
  font-size: 10px;
  line-height: 10px;
  left: 16px;
  top: 100%;
  margin-top: 4px;
  color: $color-error;
}

.input-label-text {
  @include arial-regular();
  @extend %unselectable;
  color: $color-secondary;
  font-size: 10px;
  position: relative;
  z-index: 2;
}


