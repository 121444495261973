.flag {
  background-image: url("flags16.png");
  background-repeat: no-repeat;

  &._African_Union {
    background-position: center -16px;
  }
  &._Arab_League {
    background-position: center -32px;
  }
  &._ASEAN {
    background-position: center -48px;
  }
  &._CARICOM {
    background-position: center -64px;
  }
  &._CIS {
    background-position: center -80px;
  }
  &._Commonwealth {
    background-position: center -96px;
  }
  &._England {
    background-position: center -112px;
  }
  &._European_Union,
  &.eu {
    background-position: center -128px;
  }
  &._Islamic_Conference {
    background-position: center -144px;
  }
  &._Kosovo {
    background-position: center -160px;
  }
  &._NATO {
    background-position: center -176px;
  }
  &._Northern_Cyprus {
    background-position: center -192px;
  }
  &._Northern_Ireland {
    background-position: center -208px;
  }
  &._Olimpic_Movement {
    background-position: center -224px;
  }
  &._OPEC {
    background-position: center -240px;
  }
  &._Red_Cross {
    background-position: center -256px;
  }
  &._Scotland {
    background-position: center -272px;
  }
  &._Somaliland {
    background-position: center -288px;
  }
  &._Tibet {
    background-position: center -304px;
  }
  &._United_Nations {
    background-position: center -320px;
  }
  &._Wales {
    background-position: center -336px;
  }
  &.ad {
    background-position: center -352px;
  }
  &.ae {
    background-position: center -368px;
  }
  &.af {
    background-position: center -384px;
  }
  &.ag {
    background-position: center -400px;
  }
  &.ai {
    background-position: center -416px;
  }
  &.al {
    background-position: center -432px;
  }
  &.am {
    background-position: center -448px;
  }
  &.ao {
    background-position: center -464px;
  }
  &.aq {
    background-position: center -480px;
  }
  &.ar {
    background-position: center -496px;
  }
  &.as {
    background-position: center -512px;
  }
  &.at {
    background-position: center -528px;
  }
  &.au {
    background-position: center -544px;
  }
  &.aw {
    background-position: center -560px;
  }
  &.ax {
    background-position: center -576px;
  }
  &.az {
    background-position: center -592px;
  }
  &.ba {
    background-position: center -608px;
  }
  &.bb {
    background-position: center -624px;
  }
  &.bd {
    background-position: center -640px;
  }
  &.be {
    background-position: center -656px;
  }
  &.bf {
    background-position: center -672px;
  }
  &.bg {
    background-position: center -688px;
  }
  &.bh {
    background-position: center -704px;
  }
  &.bi {
    background-position: center -720px;
  }
  &.bj {
    background-position: center -736px;
  }
  &.bm {
    background-position: center -752px;
  }
  &.bn {
    background-position: center -768px;
  }
  &.bo {
    background-position: center -784px;
  }
  &.br {
    background-position: center -800px;
  }
  &.bs {
    background-position: center -816px;
  }
  &.bt {
    background-position: center -832px;
  }
  &.bw {
    background-position: center -848px;
  }
  &.by {
    background-position: center -864px;
  }
  &.bz {
    background-position: center -880px;
  }
  &.ca {
    background-position: center -896px;
  }
  &.cg {
    background-position: center -912px;
  }
  &.cf {
    background-position: center -928px;
  }
  &.cd {
    background-position: center -944px;
  }
  &.ch {
    background-position: center -960px;
  }
  &.ci {
    background-position: center -976px;
  }
  &.ck {
    background-position: center -992px;
  }
  &.cl {
    background-position: center -1008px;
  }
  &.cm {
    background-position: center -1024px;
  }
  &.cn {
    background-position: center -1040px;
  }
  &.co {
    background-position: center -1056px;
  }
  &.cr {
    background-position: center -1072px;
  }
  &.cu {
    background-position: center -1088px;
  }
  &.cv {
    background-position: center -1104px;
  }
  &.cy {
    background-position: center -1120px;
  }
  &.cz {
    background-position: center -1136px;
  }
  &.de {
    background-position: center -1152px;
  }
  &.dj {
    background-position: center -1168px;
  }
  &.dk {
    background-position: center -1184px;
  }
  &.dm {
    background-position: center -1200px;
  }
  &.do {
    background-position: center -1216px;
  }
  &.dz {
    background-position: center -1232px;
  }
  &.ec {
    background-position: center -1248px;
  }
  &.ee {
    background-position: center -1264px;
  }
  &.eg {
    background-position: center -1280px;
  }
  &.eh {
    background-position: center -1296px;
  }
  &.er {
    background-position: center -1312px;
  }
  &.es {
    background-position: center -1328px;
  }
  &.et {
    background-position: center -1344px;
  }
  &.fi {
    background-position: center -1360px;
  }
  &.fj {
    background-position: center -1376px;
  }
  &.fm {
    background-position: center -1392px;
  }
  &.fo {
    background-position: center -1408px;
  }
  &.fr {
    background-position: center -1424px;
  }
  &.bl,
  &.cp,
  &.mf,
  &.yt {
    background-position: center -1424px;
  }
  &.ga {
    background-position: center -1440px;
  }
  &.gb {
    background-position: center -1456px;
  }
  &.sh {
    background-position: center -1456px;
  }
  &.gd {
    background-position: center -1472px;
  }
  &.ge {
    background-position: center -1488px;
  }
  &.gg {
    background-position: center -1504px;
  }
  &.gh {
    background-position: center -1520px;
  }
  &.gi {
    background-position: center -1536px;
  }
  &.gl {
    background-position: center -1552px;
  }
  &.gm {
    background-position: center -1568px;
  }
  &.gn {
    background-position: center -1584px;
  }
  &.gp {
    background-position: center -1600px;
  }
  &.gq {
    background-position: center -1616px;
  }
  &.gr {
    background-position: center -1632px;
  }
  &.gt {
    background-position: center -1648px;
  }
  &.gu {
    background-position: center -1664px;
  }
  &.gw {
    background-position: center -1680px;
  }
  &.gy {
    background-position: center -1696px;
  }
  &.hk {
    background-position: center -1712px;
  }
  &.hn {
    background-position: center -1728px;
  }
  &.hr {
    background-position: center -1744px;
  }
  &.ht {
    background-position: center -1760px;
  }
  &.hu {
    background-position: center -1776px;
  }
  &.id {
    background-position: center -1792px;
  }
  &.mc {
    background-position: center -1792px;
  }
  &.ie {
    background-position: center -1808px;
  }
  &.il {
    background-position: center -1824px;
  }
  &.im {
    background-position: center -1840px;
  }
  &.in {
    background-position: center -1856px;
  }
  &.iq {
    background-position: center -1872px;
  }
  &.ir {
    background-position: center -1888px;
  }
  &.is {
    background-position: center -1904px;
  }
  &.it {
    background-position: center -1920px;
  }
  &.je {
    background-position: center -1936px;
  }
  &.jm {
    background-position: center -1952px;
  }
  &.jo {
    background-position: center -1968px;
  }
  &.jp {
    background-position: center -1984px;
  }
  &.ke {
    background-position: center -2000px;
  }
  &.kg {
    background-position: center -2016px;
  }
  &.kh {
    background-position: center -2032px;
  }
  &.ki {
    background-position: center -2048px;
  }
  &.km {
    background-position: center -2064px;
  }
  &.kn {
    background-position: center -2080px;
  }
  &.kp {
    background-position: center -2096px;
  }
  &.kr {
    background-position: center -2112px;
  }
  &.kw {
    background-position: center -2128px;
  }
  &.ky {
    background-position: center -2144px;
  }
  &.kz {
    background-position: center -2160px;
  }
  &.la {
    background-position: center -2176px;
  }
  &.lb {
    background-position: center -2192px;
  }
  &.lc {
    background-position: center -2208px;
  }
  &.li {
    background-position: center -2224px;
  }
  &.lk {
    background-position: center -2240px;
  }
  &.lr {
    background-position: center -2256px;
  }
  &.ls {
    background-position: center -2272px;
  }
  &.lt {
    background-position: center -2288px;
  }
  &.lu {
    background-position: center -2304px;
  }
  &.lv {
    background-position: center -2320px;
  }
  &.ly {
    background-position: center -2336px;
  }
  &.ma {
    background-position: center -2352px;
  }
  &.md {
    background-position: center -2368px;
  }
  &.me {
    background-position: center -2384px;
  }
  &.mg {
    background-position: center -2400px;
  }
  &.mh {
    background-position: center -2416px;
  }
  &.mk {
    background-position: center -2432px;
  }
  &.ml {
    background-position: center -2448px;
  }
  &.mm {
    background-position: center -2464px;
  }
  &.mn {
    background-position: center -2480px;
  }
  &.mo {
    background-position: center -2496px;
  }
  &.mq {
    background-position: center -2512px;
  }
  &.mr {
    background-position: center -2528px;
  }
  &.ms {
    background-position: center -2544px;
  }
  &.mt {
    background-position: center -2560px;
  }
  &.mu {
    background-position: center -2576px;
  }
  &.mv {
    background-position: center -2592px;
  }
  &.mw {
    background-position: center -2608px;
  }
  &.mx {
    background-position: center -2624px;
  }
  &.my {
    background-position: center -2640px;
  }
  &.mz {
    background-position: center -2656px;
  }
  &.na {
    background-position: center -2672px;
  }
  &.nc {
    background-position: center -2688px;
  }
  &.ne {
    background-position: center -2704px;
  }
  &.ng {
    background-position: center -2720px;
  }
  &.ni {
    background-position: center -2736px;
  }
  &.nl {
    background-position: center -2752px;
  }
  &.bq {
    background-position: center -2752px;
  }
  &.no {
    background-position: center -2768px;
  }
  &.bv,
  &.nq,
  &.sj {
    background-position: center -2768px;
  }
  &.np {
    background-position: center -2784px;
  }
  &.nr {
    background-position: center -2800px;
  }
  &.nz {
    background-position: center -2816px;
  }
  &.om {
    background-position: center -2832px;
  }
  &.pa {
    background-position: center -2848px;
  }
  &.pe {
    background-position: center -2864px;
  }
  &.pf {
    background-position: center -2880px;
  }
  &.pg {
    background-position: center -2896px;
  }
  &.ph {
    background-position: center -2912px;
  }
  &.pk {
    background-position: center -2928px;
  }
  &.pl {
    background-position: center -2944px;
  }
  &.pr {
    background-position: center -2960px;
  }
  &.ps {
    background-position: center -2976px;
  }
  &.pt {
    background-position: center -2992px;
  }
  &.pw {
    background-position: center -3008px;
  }
  &.py {
    background-position: center -3024px;
  }
  &.qa {
    background-position: center -3040px;
  }
  &.re {
    background-position: center -3056px;
  }
  &.ro {
    background-position: center -3072px;
  }
  &.rs {
    background-position: center -3088px;
  }
  &.ru {
    background-position: center -3104px;
  }
  &.rw {
    background-position: center -3120px;
  }
  &.sa {
    background-position: center -3136px;
  }
  &.sb {
    background-position: center -3152px;
  }
  &.sc {
    background-position: center -3168px;
  }
  &.sd {
    background-position: center -3184px;
  }
  &.se {
    background-position: center -3200px;
  }
  &.sg {
    background-position: center -3216px;
  }
  &.si {
    background-position: center -3232px;
  }
  &.sk {
    background-position: center -3248px;
  }
  &.sl {
    background-position: center -3264px;
  }
  &.sm {
    background-position: center -3280px;
  }
  &.sn {
    background-position: center -3296px;
  }
  &.so {
    background-position: center -3312px;
  }
  &.sr {
    background-position: center -3328px;
  }
  &.st {
    background-position: center -3344px;
  }
  &.sv {
    background-position: center -3360px;
  }
  &.sy {
    background-position: center -3376px;
  }
  &.sz {
    background-position: center -3392px;
  }
  &.tc {
    background-position: center -3408px;
  }
  &.td {
    background-position: center -3424px;
  }
  &.tg {
    background-position: center -3440px;
  }
  &.th {
    background-position: center -3456px;
  }
  &.tj {
    background-position: center -3472px;
  }
  &.tl {
    background-position: center -3488px;
  }
  &.tm {
    background-position: center -3504px;
  }
  &.tn {
    background-position: center -3520px;
  }
  &.to {
    background-position: center -3536px;
  }
  &.tr {
    background-position: center -3552px;
  }
  &.tt {
    background-position: center -3568px;
  }
  &.tv {
    background-position: center -3584px;
  }
  &.tw {
    background-position: center -3600px;
  }
  &.tz {
    background-position: center -3616px;
  }
  &.ua {
    background-position: center -3632px;
  }
  &.ug {
    background-position: center -3648px;
  }
  &.us {
    background-position: center -3664px;
  }
  &.uy {
    background-position: center -3680px;
  }
  &.uz {
    background-position: center -3696px;
  }
  &.va {
    background-position: center -3712px;
  }
  &.vc {
    background-position: center -3728px;
  }
  &.ve {
    background-position: center -3744px;
  }
  &.vg {
    background-position: center -3760px;
  }
  &.vi {
    background-position: center -3776px;
  }
  &.vn {
    background-position: center -3792px;
  }
  &.vu {
    background-position: center -3808px;
  }
  &.ws {
    background-position: center -3824px;
  }
  &.ye {
    background-position: center -3840px;
  }
  &.za {
    background-position: center -3856px;
  }
  &.zm {
    background-position: center -3872px;
  }
  &.zw {
    background-position: center -3888px;
  }
  &.sx {
    background-position: center -3904px;
  }
  &.cw {
    background-position: center -3920px;
  }
  &.ss {
    background-position: center -3936px;
  }
  &.nu {
    background-position: center -3952px;
  }
}