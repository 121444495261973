@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;

.Counter__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  @include arial-regular();
  @include borderReset();
  @include borderBox();
  color: #000;
  height: 100%;
  width: 100%;
  letter-spacing: 0.2px;
  text-align: center;
  font-size: 15px;
  line-height: 40px;
}
