@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.GridTable__wrapper {

    &.pre_defined_scrollbar {
        &::-webkit-scrollbar-track {
            background-color: #f5f5f5;
        }
    
        &::-webkit-scrollbar-thumb {
            background: #90CAF9;
            border: 2px solid #90CAF9;
            border-radius: 2px;
        }
    
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
    }


    .grid_header_cell {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center;
        background: $color-surface;

        @include roboto-regular;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $color-grey-additional;

        height: 24px;

        &__recalls {
            padding-top: 18px !important;
            padding-bottom: 18px !important;
            border-bottom: 1px solid rgba(0,0,0,.06);
        }
    }

    .rgt-text-truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .grid_row {
        display: contents;

        &__recalls {
            border-bottom: 1px solid rgba(0,0,0,.06);

            &:hover {
                background: $color-surface;
            }

            &_red {
                background: $background-warning;
            }
        }

        .grid_cell {
            position: relative;
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            word-break: break-word;

            @include roboto-regular;
            font-style: normal;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.5px;
        }
    }
}
