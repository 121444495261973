@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.find-order {
  margin-bottom: 30px;

  &__button {
    margin-left: auto;

    .e-button__wrapper {
      cursor: pointer;
    }
  }

  .recalls-table-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 50px;

  }
}


