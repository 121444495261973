@use '@styles/core/breakpoint' as *;
@use '@styles/core/mixins/color' as *;

.BottomMobileMenu {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-top: 1px solid #D4D4D8;
  z-index: 110;
  display: none;

  justify-content: space-evenly;
  align-items: flex-start;

  @media (max-width: $mobile) {
    display: flex;
  }

  .item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    cursor: pointer;

    &.ready, &.un-ready {
      & .icon:after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        position: absolute;
        top: 0;
        right: -3px;
      }

      & .icon:before {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        position: absolute;
        top: -2px;
        right: -5px;
        background: #fff;
      }
    }

    &.ready {
      & .icon:after {
        background: $color-accent;
      }
    }

    &.un-ready {
      & .icon:after {
        background: $color-error;
      }
    }

    &.with-duration {
      padding-top: 5px;
    }

    .icon {
      overflow: visible;
      position: relative;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 9px;
    }

    span {
      color: #8E8E93;
      font-family: Roboto, sans-serif;
      font-size: 10px;
      text-align: center;
    }

    .duration {
      font-family: Roboto, sans-serif;
      font-size: 10px;
      background: $color-accent;
      color: $color-contrast;
      border-radius: 3px;
      min-width: 36px;
      max-width: 52px;
      height: 16px;
      padding: 0 3px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      &.expired {
        background: $color-error;
      }
    }

    .icon ~ .duration {
      margin-bottom: 0;
    }

    &.active {
      .icon {
        svg {
          path {
            fill: #1665D8;
          }
        }
      }

      span {
        color: #1665D8;
      }
    }
  }
}
