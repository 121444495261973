.file-input__wrapper {
  .e-file-input {
    position: relative;
  }
  .file-input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: 10;
  }
  .label {
    position: relative;
  }
}
