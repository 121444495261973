@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/breakpoint' as *;

.Notifications {
  &__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    min-width: 300px;
    max-width: 560px;

    @media (max-width: $tablet) {
      min-width: initial;
      max-width: 80%;
    }
  }

  @media (max-width: $mobile) {
    transform: translateY(0);
    transition: 0.3s transform ease-in-out;

    &.hideOnScroll {
      transform: translateY(-100%);
    }
  }

  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(5px);
  min-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 8px;
  padding-bottom: 8px;

  z-index: 99;

  .message {
    color: #3A3A3A;
    letter-spacing: 0.4px;
    text-align: left;
    line-height: 14px;
  }

  .timer_wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    .message {
      margin-left: 16px;
    }
  }

  .timer_wrapper + .notification_wrapper {
    margin-top: 20px;
  }

  &.no-order {
    @media (min-width: $tablet) {
      position: fixed;
    }
  }

  .greetings {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: nowrap;
    align-items: center;

    .message {
      @include roboto-regular();
      font-size: 12px;
      line-height: 14px;
      color: $color-grey-additional;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      p {
        padding-right: 5px;
        font-weight: 700;
      }
    }

    .success_icon {
      padding-right: 16px;
    }

    .close_greeting_btn {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: auto;

      svg {
        path {
          fill: #c2c2c2;
        }
      }

      &:hover {
        svg {
          path {
            fill: $color-error;
          }
        }
      }
    }
  }

  .notification_wrapper {
    margin-top: 4px;
    padding-top: 4px;
    width: auto;
    .feedback-action {
      display: flex;
      align-items: flex-start;
      @media (max-width: $mobile) {
        flex-direction: column;
      }

      textarea {
        background: #FFFFFF;
        border: 1px solid #D4D4D8;
        box-sizing: border-box;
        border-radius: 4px;
        width: 100%;
        margin-top: 4px;
        margin-bottom: 4px;
        padding: 8px;
        height: 40px;
        margin-right: 20px;
        line-height: 20px;
      }

      .e-button__wrapper {
        width: 100px !important;
        margin-top: 4px;
      }
    }
  }

  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.07));
  @include arial-regular();
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;

  @media (max-width: $tablet) {
    width: 100%;
    right: 0;
    left: 64px;
  }

  @media (max-width: $mobile) {
    width: 100%;
    right: 0;
    left: 0;
    padding-left: 8px;
    padding-right: 8px;
  }

  .timer {
    border-radius: 3px;
    background: $color-accent;
    color: $color-contrast;
    font-weight: bold;
    font-size: 12px;
    width: 56px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.expired {
      background: #FF3B30;
    }
  }
}
