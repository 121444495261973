@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;

.AutodialCounter_main {
  position: absolute;
  width: 16px;
  height: 16px;

  .AutodialCounter {
    display: flex;
    align-items: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: $color-surface;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    justify-content: center;
    transition: all ease-in-out .3s;

    &.lastAttempt {
      width: 27px;
      height: 27px;
  
      .AutodialCounter__wrapper {
        width: 20px;
        height: 20px;
    
        background-color: $color-accent-dark;
        overflow: hidden;
        box-shadow:
                inset 0 0 0.8rem 0.1rem rgba(darken($color-accent-dark, 20%), 0.2),
                0 20px 30px -10px rgba(darken($color-accent-dark, 20%), 0.26);
    
        .AutodialCounter__input {
          font-size: 12px;
        }
      }
    }
  
    &__wrapper {
      width: 16px;
      height: 16px;
      border-radius: 50px;
      background-color: $color-accent;
      overflow: hidden;
      box-shadow:
              inset 0 0 0.8rem 0.1rem rgba(darken($color-accent, 20%), 0.2),
              0 20px 30px -10px rgba(darken($color-accent, 20%), 0.26);
  
      transition: all ease-in-out .3s;
  
  
    }
  
    &__input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background: none;
      border: none;
      text-align: center;
      color: #fff;
      font-size: 10px;
      outline: none;
      transition-property: transform, opacity;
      text-transform: linear;
      transition-duration: 0.25s;
      transition: all ease-in-out .3s;
    }
  }
}

