.copyright {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  line-height: 72px;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: $color-secondary;
  @include arial-regular();
}

.login__page {
  @include borderBox();
  display: block;
  position: relative;
  min-height: 100vh;
  @media (max-width: $mobile) {
    min-height: calc(var(--vh, 1vh) * 100);
  }
  background-color: $color-bg-grey;

  @media (max-width: $tablet) {
    background-color: $color-contrast;
  }
}

.logo {
  display: inline-block;
  margin-bottom: 28px;
  position: relative;
}

.logo-img {
  max-width: 196px;
  display: block;
}

.login__form {
  @include borderBox();
  @include absoluteCenter();
  display: block;
  background-color: $color-contrast;
  box-shadow: 0px 0px 50px 0px fade($color-main, 0.2);
  z-index: 1002;
  width: 608px;
  border-radius: 8px;
  height: 480px;
  padding-top: 84px;
  @media (max-width: $mobile) {
    background-color: transparent;
  }

  @media (max-width: $tablet) {
    padding-left: 12px;
    box-shadow: none;
    padding-right: 12px;
    width: calc(100% - 24px);
    max-width: 400px;
  }
}

.auth-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-family: Arial, sans-serif;
  font-size: 1em;
}

.login__b-form {
  display: block;
  margin: auto;
  width: 352px;
  text-align: center;

  .row {
    margin-bottom: 20px;
  }

  .e-button__wrapper {
    margin: 0 auto;
  }

  @media (max-width: $tablet) {
    width: auto;
  }
}

.login__title {
  @include gilroy-bold();
  color: $color-main;
  font-size: 36px;
  line-height: 1;
  display: block;
  margin-bottom: 32px;
}

.b-form__login .row {
  display: block;
  margin-bottom: 24px;
}

.notify-order-found__block {
  display: inline-block;
  vertical-align: middle;
}

.notify-order-found__title {
  @include arial-bold();
  font-size: 14px;
  color: inherit;
  letter-spacing: 0.2px;
  line-height: 16px;
}

.notify-order-found__text {
  @include arial-regular();
  font-size: 12px;
  color: inherit;
  letter-spacing: 0.4px;
  line-height: 16px;
  margin-top: 2px;
}

.notify-order-found__button {
  display: inline-block;
  vertical-align: middle;
  @include arial-bold();
  font-size: 14px;
  color: inherit;
  letter-spacing: 0.2px;
  line-height: 16px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #fff;
  border-radius: 4px;
  text-align: center;
  width: 120px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translate(0, -50%);
}

.problem-modal-window {
  width: 100%;
  height: 100%;
}

.problem-modal-window__table {
  @include tableBlock();
}

.problem-modal-window__cell {
  @include tableCell();
}

.rate-the-call-modal-window {
  width: 100%;
  height: 100%;
}

.rate-the-call-modal-window__table {
  @include tableBlock();
}

.rate-the-call-modal-window__cell {
  @include tableCell();
}

