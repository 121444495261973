@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/breakpoint' as *;

.SideBarDeepLanguageChangeScreenMenu {
  width: 100%;
  height: 100%;
  background: $color-bg-grey;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  @media (max-width: $mobile) {
    visibility: visible;
    display: block;
    transform: translateX(100%);

    &.visible {
      transform: translateX(0);;
    }
  }

  .header {
    background-color: $color-contrast;
    border-bottom: 1px solid $color-border;
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #212121;
    height: 56px;
    display: flex;

    .arrow-back {
      background-size: 24px 24px;
      background: url('../icons/arrorw-left.svg') no-repeat center;
      width: 56px;
      height: 56px;
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
    }
  }

  .items {
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: $color-contrast;
    overflow: auto;
    max-height: calc(100% - 112px);

    .item {
      display: flex;
      cursor: pointer;
      padding-top: 4px;
      padding-bottom: 4px;

      .icon {
        width: 24px;
        height: 40px;
        padding-left: 20px;
        padding-right: 16px;
        flex-basis: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        .flag-icon {
          width: 20px;
          height: 20px;
          border-radius: 20px;
        }
      }

      .title {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        color: #3A3A3A;
        font-weight: normal;
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}
