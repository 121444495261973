@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.OrderViewPage {
  @include borderBox();
  height: 100vh;
  overflow-y: hidden;
  &__wrapper {
    @include borderBox();
    min-height: 100%;
    height: 100%;
    background-color: $color-bg;
    padding: 24px 44px 72px 104px;
    width: calc(100% - 312px);
    overflow-y: auto;

    @media (max-width: $tablet) {
      padding-right: 12px;
      padding-left: 76px;
      width: 100%;
    }

    @media (max-width: $mobile) {
      padding-left: 12px;
    }
  }

  &__back {
    @include borderBox();
    flex-shrink: 0;
    margin: 0 0 20px 0;
    font-size: 14px;
    font-family: arial, sans-serif;
    padding: 10px 30px;
    cursor: pointer;
  }

  &__pageTitle {
    @include arial-bold();
    color: $color-secondary;
    font-size: 36px;
    letter-spacing: 0.2px;
    line-height: 40px;
    margin-bottom: 24px;

    @media (max-width: 540px) {
      font-size: 22px;
      white-space: nowrap;
    }
  }

  &__content {
    @include borderBox();
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    box-shadow:
            0 0 0 0 rgba(0, 0, 0, 0.1),
            0 5px 5px 2px rgba(0, 0, 0, 0.05);
    padding: 0;
    margin: 0;
  }
  .order-total {
    margin: 5px 0 0;
    font-family: arial, sans-serif;
    color: $color-main;
    font-size: 14px;
    letter-spacing: 0.2px;
  }

  .order-head {
    display: block;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    border: 1px solid $color-border;
    @include borderBox();
  }

  .order-info {
    @include borderBox();
    display: block;
    padding: 16px;

    @media (max-width: $mobile) {
      padding: 12px;
    }
  }

  .order-tag {
    @include ib();
    @include arial-bold();
    height: 20px;
    padding: 0 8px;
    text-align: center;
    line-height: 19px;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 4px;
    letter-spacing: 1px;
    border: 1px solid $color-tertiary;
    color: $color-icony;

    + .order-tag {
      margin-left: 10px;
    }
  }

  .order-name {
    display: block;
    .name {
      @include ib();
      @include arial-bold();
      font-size: 24px;
      color: $color-main;
      padding-top: 8px;
    }
  }

  .order-flag {
    @include ib();
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-right: 8px;
    margin-top: 5px;
    .flag {
      display: block;
      height: 16px;
      width: 16px;
      position: absolute;
      left: 0;
      top: 1px;
      transform: scale(1.3);
    }
    .langIcon {
      @include borderRadiusTop(50%);
      @include borderRadiusBottom(50%);
      width: 25px;
      height: 25px;
    }
  }

  .order-date {
    margin-top: 14px;
    .item {
      @include ib();
      @include arial-regular();
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $color-secondary;
      margin-right: 64px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bold {
      font-weight: bold;
    }
    .accent {
      color: $color-accent;
    }

    .value {
      white-space: nowrap;
    }
  }
}

.OrderViewComponent__wrapper {
  padding-bottom: 20px;

  .order-head {
    display: block;
    position: relative;
    background-color: $color-contrast;
    border-radius: 4px;
    border: 1px solid $color-border;
    @include borderBox();
  }

  .order-info {
    @include borderBox();
    display: block;
    padding: 16px;

    @media (max-width: $mobile) {
      padding: 12px;
    }
  }

  .order-tag {
    @include ib();
    @include arial-bold();
    height: 20px;
    padding: 0 8px;
    text-align: center;
    line-height: 19px;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: 4px;
    letter-spacing: 1px;
    border: 1px solid $color-tertiary;
    color: $color-icony;

    + .order-tag {
      margin-left: 10px;
    }
  }

  .order-name {
    display: block;
    .name {
      @include ib();
      @include arial-bold();
      font-size: 24px;
      color: $color-main;
      padding-top: 8px;
    }
  }

  .order-flag {
    @include ib();
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin-right: 8px;
    margin-top: 5px;
    .flag {
      display: block;
      height: 16px;
      width: 16px;
      position: absolute;
      left: 0;
      top: 1px;
      transform: scale(1.3);
    }
    .langIcon {
      @include borderRadiusTop(50%);
      @include borderRadiusBottom(50%);
      width: 25px;
      height: 25px;
    }
  }

  .order-date {
    margin-top: 14px;
    .item {
      @include ib();
      @include arial-regular();
      font-size: 12px;
      letter-spacing: 0.4px;
      color: $color-secondary;
      margin-right: 64px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bold {
      font-weight: bold;
    }
    .accent {
      color: $color-accent;
    }

    .value {
      white-space: nowrap;
    }
  }
}

.CustomerServicePageInfo {
  @include borderBox();
  display: flex;
  flex-wrap: wrap;
  padding: 30px 16px 10px;

  @media (max-width: $mobile) {
    padding-left: 4px;
    padding-right: 4px;
  }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: 240px;
    min-height: 40px;
    padding: 0;
    margin: 0 8px 16px;
    font-family: arial, sans-serif;
    color: $color-main;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  &__label {
    font-family: arial, sans-serif;
    font-weight: normal;
    color: #6B6C6F;
    font-size: 10px;
    position: absolute;
    z-index: 2;
    line-height: 10px;
    left: 0;
    top: -5px;
    padding: 0;
    height: 10px;
  }
}

.CustomerServicePageProducts {
  padding: 0;
  margin-top: 15px;
  font-family: arial, sans-serif;
  &__head {
    display: flex;
    height: 24px;
    padding: 0 24px;
    line-height: 24px;
    background-color: rgba(188, 188, 188, 0.2);
    font-family: arial, sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    letter-spacing: 0.4px;
    color: #6B6C6F;

    @media (max-width: $tablet) {
      padding: 0 5px 0 19px;
    }

    @media (max-width: $desktop) {
      display: none;
    }
  }
  &__body {
    display: block;
    margin: 12px 24px;

    @media (max-width: $tablet) {
      margin: 5px;
    }

    @media (max-width: $desktop) {
      margin: 12px 0;
    }
  }
  &__details {
    display: flex;
    align-items: center;

    @media (max-width: $tablet) {
      flex-grow: 1;
    }

    @media (max-width: $desktop) {
      flex-grow: 1;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding: 10px 8px;
    }
  }
  &__row {
    display: flex;
    align-items: center;
    &--main {
      @media (max-width: $tablet) {
        width: auto;
        flex-grow: 1;
      }
    }
    &--secondary {
      @media (max-width: $tablet) {
        flex-shrink: 0;
      }
      @media (max-width: $desktop) {
        font-size: 10px;
        line-height: 12px;
        width: 100%;
      }
    }
  }
  &__item {
    display: flex;
    align-items: center;
    border-top: 1px solid #BCBCBC;
    border-left: 1px solid #BCBCBC;
    border-right: 1px solid #BCBCBC;
    min-height: 64px;
    font-family: arial, sans-serif;
    font-weight: normal;
    color: #3E3F42;
    font-size: 15px;
    letter-spacing: 0.2px;
    line-height: 16px;

    @media (max-width: $desktop) {
      height: auto;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #3A3A3A;
      border: none;
      align-items: unset;
      border-bottom: 1px solid #BCBCBC;
    }

    &:first-child {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      @media (max-width: $desktop) {
        border-radius: 0;
        border-top: 1px solid #BCBCBC;
      }
    }
    &:last-child {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      border-bottom: 1px solid #BCBCBC;

      @media (max-width: $desktop) {
        border-radius: 0;
      }
    }
    span {
      display: none;

      @media (max-width: $desktop) {
        display: block;
        color: #8E8E93;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-weight: 500;
        margin-right: 3px;
      }
    }
  }
  &__setInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 12px;

    .setItem {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        margin: 0;
      }
    }
  }
  &__image {
    flex-shrink: 0;
    width: 80px;

    @media (max-width: $desktop) {
      width: auto;
      padding: 5px;
    }

    img {
      display: block;
      width: 60px;
      height: 60px;
      object-fit: contain;

      @media (max-width: $desktop) {
        width: 64px;
        height: 64px;
      }
    }
  }
  &__name {
    width: 300px;

    @media (max-width: $tablet) {
      width: auto;
      flex-grow: 1;
    }

    @media (max-width: $desktop) {
      width: auto;
    }

    &--type {
      padding: 0 5px;

      &.gift {
        background-color: fade($color-success, 0.2);
        color: $color-success;
      }

      &.promo {
        background-color: fade($color-accent, 0.2);
        color: $color-accent;
      }
    }
  }
  &__quantity {
    flex-shrink: 0;
    width: 100px;

    @media (max-width: $tablet) {
      width: 90px;
    }

    @media (max-width: $desktop) {
      display: flex;
      align-items: center;
      width: auto;
      margin-left: 5px;
    }

    span {
      @media (max-width: $desktop) {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
        margin-top: 3px;
      }
    }
  }
  &__price {
    flex-shrink: 0;
    width: 100px;

    @media (max-width: $tablet) {
      width: 100px;
    }

    @media (max-width: $desktop) {
      display: flex;
      align-items: center;
      width: 50%;
    }
  }
  &__total {
    flex-shrink: 0;
    width: 100px;

    @media (max-width: $tablet) {
      width: 100px;
    }

    @media (max-width: $desktop) {
      display: flex;
      align-items: center;
      width: auto;
    }
  }
}

.full-width {
  width: 100% !important;
}