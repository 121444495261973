.finance__wrapper {
  .page {
    @include borderBox();
    @include zeroFont();
    display: block;
    position: relative;
    background-color: $color-bg;
    padding-left: 104px;
    min-height: 100vh;
    padding-top: 24px;
  }

  .call-button {
    @include ib();
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $color-success;
  }

  .call-button .icon {
    width: 20px;
    height: 20px;
    display: block;
    @include absoluteCenter();
    fill: transparent;
    stroke: $color-contrast;
    stroke-width: 1.5px;
  }

  .type {
    line-height: 16px;
  }

  .type .icon {
    width: 16px;
    height: 16px;
    position: relative;
    @include ib();
    margin-right: 4px;
  }

  .type.minus {
    color: $color-error;
  }

  .type.minus .icon {
    fill: $color-error;
  }

  .type.plus {
    color: $color-success;
  }

  .type.plus .icon {
    fill: $color-success;
  }

  .button {
    width: 112px;
    border: 1px solid $color-tertiary;
    border-radius: 4px;
    color: $color-secondary;
    @include arial-bold();
    font-size: 12px;
    letter-spacing: 0.4px;
    background-color: transparent;
    text-align: center;
    padding: 8px;
    cursor: pointer;
    @include simpleAnimate(all);
  }

  .button:hover {
    border-color: $color-accent;
    color:  $color-accent;
  }
}