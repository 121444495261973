.benefit-slider__wrapper {
  display: block;
  border: 1px solid $color-tertiary;
  border-radius: 4px;
  padding: 12px 0;
  text-align: center;
  position: relative;
  margin-bottom: 12px;
  width: 648px;
  div {
    outline: none !important;
  }
  .dots {
    @include zeroFont();
    margin: 0;
    position: absolute;
    bottom: -24px;
    left: 0;
    right: 0;
  }
  .dots li {
    @include ib();
    padding: 0;
    height: 4px;
    width: 4px;
    border-radius: 4px;
    background-color: #e6ecf2;
    margin: 0 4px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    &.slick-active {
      background-color: $color-accent;
    }
  }
  .dots button {
    opacity: 0;
    height: 4px;
    width: 4px;
    border-radius: 4px;
  }
}
