@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;

.popup__wrapper {
  .e-popup {
    position: absolute;
    border-radius: 4px;
    background-color: $color-contrast;
    border: 1px solid $color-icony;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    @include simpleAnimate(opacity);
    z-index: 1000;
  }

  .e-popup.active {
    visibility: visible;
    opacity: 1;
  }
}

.PopupWithoutAnimations__wrapper {
  .e-PopupWithoutAnimations {
    position: absolute;
    background-color: $color-contrast;
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
}