@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;


.MissingItemsForPromotionBuilder {
  width: 400px;

  &__title {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    color: $color-grey-additional;

    text-align: center;
    width: 100%;
    padding: 10px 10px 20px 10px;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 10px;

    .missingItemsColumn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;

      .optionalGifts__title,
      .mandatoryGifts__title {
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        color: $color-grey-additional;

        padding-bottom: 10px;
        text-align: center;
        width: 100%;
      }

      .productName {
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        color: $color-grey-additional;
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 10px 20px;
  }
}
