@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;

.e-button__wrapper {
  user-select: none;
  z-index: 0;

  %afterTypeDefault {
    top: 50%;
    margin-top: -48px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 0;
    height: 96px;
    border-radius: 96px;
    opacity: 0;
  }

  .button__disabled {
    pointer-events: none;
    opacity: .7;
  }

  .e-button {
    display: flex;
    align-items: center;
    justify-content: center;

    @include borderBox();
    @include simpleAnimate(all);
    position: relative;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;

    &.with-padding {
      .link {
        padding: 0 20px;
      }
    }

    &:before,
    &:after {
      @include pseudoBlock();
      @include simpleAnimate(all);
      @include borderBox();
      z-index: 1;
    }

    &:before {
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      border-radius: 4px;
    }

    &__Spinner {
      position: relative;
      z-index: 9;

      .lds-spinner {
        position: absolute !important;
        bottom: -27px !important;
        left: -31px !important;
      }

      div {
        &:after {
          top: 17px !important;
          width: 3px !important;
          height: 7px !important;
        }
      }
    }
  }

  .link {
    position: relative;
    z-index: 3;
    @include oneLine();
    @include arial-bold();
    @include simpleAnimate(all);
    text-transform: uppercase;
    width: 100%;

    svg {
      path {
        @include simpleAnimate(all);
      }
    }
  }

  .v-size-0, .v-size-default {
    .link {
      font-size: 14px;
      letter-spacing: 0.8px;
    }

    height: 32px;
    line-height: 32px;
  }

  .v-size-1 {
    .link {
      font-size: 14px;
      letter-spacing: 0.8px;
    }

    height: 40px;
    line-height: 40px;
  }

  .v-size-2 {
    .link {
      font-size: 14px;
      letter-spacing: 0.8px;
    }

    height: 56px;
    line-height: 56px;
  }

  .v-size-3 {

    .link span {
      white-space: normal;
    }
  }

  .v-size-10 {
    .link {
      font-size: 12px;
      letter-spacing: 0.4px;
      text-transform: none;
    }

    height: 32px;
    line-height: 32px;
  }

  .v-type-0,
  .v-type-default {
    .link {
      color: $color-secondary;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &:before {
      border: 1px solid $color-tertiary;
    }

    &:after {
      background-color: fade($color-accent, 0.2);
      @extend %afterTypeDefault;
    }
  }

  .v-type-default {
    cursor: default !important;
  }

  .v-type-0 {
    &:hover {
      .link {
        color: $color-accent;
      }
    }

    &:hover:before {
      border-color: $color-accent;
    }

    &:active:after {
      width: 96px;
      opacity: 1;
    }

    &:focus:after {
      width: 100%;
      opacity: 1;
    }
  }

  .v-type-1 {
    .link {
      color: $color-secondary;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &:before {
      background-color: $color-tertiary;
    }

    &:hover {
      .link {
        color: $color-contrast;

        svg {
          path {
            fill: $color-contrast;
          }
        }
      }
    }

    &:hover:before {
      background-color: $color-accent;
    }

    &:after {
      background-color: fade($color-contrast, 0.2);
      @extend %afterTypeDefault;
    }

    &:active:after {
      width: 96px;
      opacity: 1;
    }

    &:focus:after {
      width: 100%;
      opacity: 1;
    }
  }

  .v-type-3 {
    .link {
      color: $color-secondary;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &.status {
      .link {
        color: $color-contrast;
        background: $color-accent;
      }
    }

    &:before {
      border: 2px solid $color-accent;
    }

    &:hover {
      .link {
        color: $color-contrast;

        svg {
          path {
            fill: $color-contrast;
          }
        }
      }
    }

    &:hover:before {
      background-color: $color-accent;
    }
  }

  .v-type-5 {
    .link {
      color: $color-secondary;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &.status {
      .link {
        color: $color-contrast;
        background: $color-error;
      }
    }

    &:before {
      border: 2px solid $color-error;
    }

    &:hover {
      .link {
        color: $color-contrast;

        svg {
          path {
            fill: $color-contrast;
          }
        }
      }
    }

    &:hover:before {
      background-color: $color-error;
    }
  }

  .status {
    box-shadow: none;
    filter: grayscale(0.1);
  }

  .status:hover {
    filter: grayscale(0);
    box-shadow: 3px 15px 25px rgba(0, 0, 0, 0.17);
  }

  .v-type-6 {
    .link {
      color: $color-secondary;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &.status {
      .link {
        color: $color-contrast;
        background: $color-warning;
      }
    }

    &:before {
      border: 2px solid $color-warning;
    }

    &:hover {
      .link {
        color: $color-contrast;

        svg {
          path {
            fill: $color-contrast;
          }
        }
      }
    }

    &:hover:before {
      background-color: $color-warning;
    }
  }

  .v-type-4 {
    &.status {
      .link {
        color: $color-contrast;
        background: $color-success;
      }
    }

    .link {
      color: $color-secondary;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &:before {
      border: 2px solid $color-success;
    }

    &:hover {
      .link {
        color: $color-contrast;

        svg {
          path {
            fill: $color-contrast;
          }
        }
      }
    }

    &:hover:before {
      background-color: $color-success;
    }
  }

  .v-type-2 {

    &.status {
      .link {
        color: $color-contrast;
        background: $color-secondary;
      }
    }

    .link {
      color: $color-secondary;

      svg {
        path {
          fill: $color-secondary;
        }
      }
    }

    &:before {
      border: 2px solid $color-tertiary;
    }

    &:hover {
      .link {
        color: $color-contrast;

        svg {
          path {
            fill: $color-contrast;
          }
        }
      }
    }

    &:hover:before {
      background-color: $color-tertiary;
    }
  }

  .v-type-7 {
    .link {
      color: $color-contrast;

      svg {
        path {
          fill: $color-contrast;
        }
      }
    }

    &:before {
      background-color: var(--background-button);
    }

    &:after {
      background-color: fade($color-contrast, 0.2);
      top: 50%;
      margin-top: -48px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 0;
      height: 96px;
      border-radius: 96px;
      opacity: 0;
    }

    &:hover:after {
      width: 96px;
      opacity: 1;
    }

    &:focus:after,
    &:active:after {
      width: 100%;
      opacity: 1;
    }
  }

  .disabled:not(.status) {
    background-color: gray;
    cursor: default;
  }

  .status.disabled {
    opacity: 0.6;
    filter: grayscale(0.5);
    cursor: not-allowed;
  }
}
