@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;

.script-item-theme {
  @include roboto-regular();
  border-bottom: 1px solid $color-test-border;
  padding: 10px 0;

  &__title {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 1.25px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;

    .arrowNav_next {
      transform: rotate(180deg);
      order: 2;
    }
  }

  &__title_reverse {

    justify-content: flex-start;

    .arrowNav_prev {
      order: 1;
      margin-right: 16px;
    }

    .script-item-theme__name {
      order: 2;
    }
  }

  &__name {
    word-wrap: break-word;
    max-width: calc(100% - 10px);
    order: 1;
  }

  &__text {
    font-size: 16px;
    line-height: 20px;
    padding: 1px 8px;
    margin: 8px 0;
    border-radius: 0px 16px 16px 16px;

    &_color {
      border-radius: 16px 0px 16px 16px;
    }
  }

}