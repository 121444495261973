.error-page {
  display: flex;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  padding: 50px;
  overflow-wrap: break-spaces;
}
