@use '@styles/core/mixins/color' as *;

.no-internet-modal {
  padding: 20px;
  background: $color-contrast;
  border: 1px solid $color-error;
  border-radius: 4px;
  font-family: "Roboto";
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 400px;
  min-height: 200px;
  flex-direction: column;

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: $color-main;
  }

  &__text {
    margin: 12px 0 27.64px 0;
    color: $color-gray-text;
    font-size: 16px;
    line-height: 20px;
    margin-top: 20px;
  }
}