@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/core/breakpoint' as *;

.customer_info_wrapper {
    @media (max-width: 1200px) {
        display: none;
    }

    width: 100%;
    margin: 0 0 30px;
    padding: 20px 52px 28px 52px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;

    background-color: $color-contrast;
    border-radius: 4px;
    border: 1px solid $color-border;
    @include borderBox();
    border-radius: 4px;

    .headline {
        @include roboto-regular;
        letter-spacing: 0.15px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;

        color: $color-grey-additional-2;

        margin-bottom: 28px;
    }
    .client_card_form {
        .customer_info_block {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
        
            .customer_info_item {
                margin-right: 16px;
                width: 296px;
            }
        }
    }
}

.errorMessage {
    p {
        @include roboto-regular();
        color: $color-error;
        font-size: 14px;
    }
}

.entering {
    opacity: 0;
    transition: all 0.2s ease-in-out;
}

.entered {
    opacity: 1;
    transition: all 0.2s ease-in-out;
}

.exiting {
    opacity: 0;
}

.exited {
    display: none;
}