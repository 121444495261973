@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';

.country-select__wrapper {
  &.e-searchable-select {
    position: relative;
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    width: var(--countrySelect-width);
    display: inline-block;
    border-radius: 4px;
    border: 1px solid $color-border;
    text-align: left;
    background: #F2F2F2;

    &.disabled {
      cursor: default;
    }

    &.error {
      border-color: $color-error;
      .handler {
        color: $color-error;
      }
      .input-label {
        color: $color-error;
      }
    }

    .langIcon {
      border-radius: 50%;
      width: 25px;
      height: 25px;
    }

    .select-popup {
      top: 100%;
      margin-top: 4px;
      left: 0;
      width: 100%;
      overflow-x: hidden;
      @include normalLine();
      ul {
        padding: 4px 0;
        margin: 0;
        li {
          @include borderBox();
          line-height: 1.3em;
          padding: 8px 16px;
          @include gilroy-regular();
          font-size: 13px;
          color: $color-main;
          list-style: none;
          &:hover {
            background-color: fade($color-accent, 0.4);
          }
        }
      }
    }

    .handler {
      @extend %unselectable;
      @include borderBox();
      @include borderReset();
      cursor: pointer;
      height: 100%;
      width: 100%;
      padding-left: 32px;
      padding-right: 16px;
      font-size: 13px;
      color: $color-main;
      @include gilroy-regular();
      @include simpleAnimate(background-color);
      overflow: hidden;
      @include oneLine();
      &.placeholder {
        color: $color-main;
      }

      &.disabled {
        cursor: default;
      }
    }

    .input-flag {
      display: block;
      position: absolute;
      z-index: 2;
      overflow: hidden;
      left: 4px;
      top: -14px;
      .flag {
        display: block;
        height: 16px;
        width: 16px;
        background-repeat: no-repeat;
        position: absolute;
        left: 0;
        top: 1px;
        transform: scale(1.5);
      }
    }

    .arrow {
      position: absolute;
      width: 8px;
      height: 20px;
      top: 13px;
      right: 11px;
      path {
        fill: $color-secondary;
      }
      will-change: transform;

      &.arrow--up {
        transform: rotate(-180deg);
      }
    }
  }
}

