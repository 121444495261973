.about__wrapper {
  .page {
    @include borderBox();
    display: block;
    position: relative;
    min-height: 100vh;
    background-color: $color-bg-grey;
    padding-left: 104px;
    padding-top: 24px;
    padding-bottom: 72px;
  }
  .page-title {
    @include arial-bold();
    color: $color-secondary;
    font-size: 36px;
    letter-spacing: 0.2px;
    line-height: 40px;
    margin-bottom: 24px;
  }
  .b-content {
    @include borderBox();
    @include zeroFont();
    @include flex();
    @include flexJustify(space-between);
    position: relative;
    width: 1232px;
    background-color: $color-contrast;
    border-radius: 4px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1),
    0 5px 5px 2px rgba(0, 0, 0, 0.05);
    padding-top: 16px;
    padding-left: 8px;
  }
  .content-tabs {
    display: block;
    width: 192px;
  }
  .content-tabs .tab {
    height: 56px;
    line-height: 55px;
    display: block;
    position: relative;
    border-bottom: 1px solid fade($color-tertiary, 0.6);
  }
  .content-tabs .tab:last-child {
    border-bottom: none;
  }
  .content-tabs .tab.active .tab-text {
    color: $color-accent;
  }
  .content-tabs .tab.active .tab-icon {
    fill: $color-accent;
  }
  .content-tabs .tab-text {
    @include ib();
    @include borderBox();
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    font-size: 14px;
    letter-spacing: 0.4px;
    line-height: 1.2;
    @include arial-bold();
    color: $color-icony;
  }
  .content-tabs .tab-icon {
    @include absoluteCenterVertical();
    right: 4px;
    display: block;
    fill: $color-tertiary;
    width: 24px;
    height: 24px;
  }
  .content-separator {
    position: absolute;
    right:210px;
    width: 1px;
    top:0;
    bottom:0;
    background-color: fade($color-tertiary, 0.6);
  }
  .content {
    @include borderBox();
    display: block;
    width: 1038px;
    padding: 40px 16px 144px 40px;
    @include arial-regular();
    font-size: 17px;
    line-height: 24px;
    color: $color-main;
  }
  .content p {
    margin-top: 24px;
    margin-bottom:0;
  }
  .content h1 {
    @include arial-bold();
    font-size: 36px;
  }
  .content h2 {
    font-size: 24px;
  }
  .content img {
    display: block;
    max-width: 100%;
  }
  .structure {
    width: 280px;
  }
  .branches-list-container {
    @include flex();
    @include flexJustify(space-between);
  }
  .branches-list {
    display: inline-block;
    vertical-align: top;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .branch {
    @include arial-bold();
    font-size: 17px;
    line-height: 28px;
  }
  .structure-title {
    @include arial-bold();
    font-size: 20px;
    line-height: 32px;
  }
  .structure-tree .directs {
    position: relative;
  }
  .structure-tree > .tree-branch {
    margin-left: 0 !important;
  }
  .structure-tree > .tree-branch > .directs {
    margin-left: 16px;
  }
  .structure-tree > .tree-branch > .directs:before {
    left: 8px;
  }
  .structure .tree-branch {
    position: relative;
    margin-top: 16px;
    margin-left: 32px;
  }

  .structure .directs:before {
    @include pseudoBlock();
    border-bottom: 1px solid $color-tertiary;
    height: 1px;
    left: 16px;
    width: 24px;
    top: 16px;
  }
  .structure-tree > .tree-branch > .employee:before {
    height: 32px;
    left: 24px;
    top: 48px;
  }
  .structure-tree .employee:before {
    @include pseudoBlock();
    border-left: 1px solid $color-tertiary;
    height: 32px;
    left: 16px;
    width: 1px;
    top: 32px;
  }
  .structure .employee-avatar-wrap {
    width: 48px;
    height: 48px;
    overflow: hidden;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
  }
  .structure .tree-branch .tree-branch .employee-avatar-wrap {
    width: 32px;
    height: 32px;
    overflow: hidden;
    border-radius: 50%;
  }
  .structure .employee {
    @include oneLine();
    @include zeroFont();
    position: relative;
  }
  .structure .employee-info {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
  }

  .structure .employee-name {
    @include arial-bold();
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  .structure .employee-position {
    @include arial-regular();
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.4px;
  }
  .structure .operators {
    margin-top: 16px;
  }
  .structure .operators-counter {
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: $color-tertiary;
    line-height: 32px;
    text-align: center;
    @include arial-regular();
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .structure .operators-label {
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    @include arial-bold();
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
}
