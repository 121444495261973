@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/utils' as *;
@use '@styles/flags/flags';

.input-autocomplete-select__wrapper {
  .input-label::before {
    background-color: lighten($color-tertiary, 20%);
  }

  .handler {
    @extend %unselectable;
    @include borderBox();
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: $color-secondary;
    @include arial-regular();
    @include simpleAnimate(background-color);
    overflow: hidden;
    @include oneLine();
    border: 1px solid $color-tertiary;
    background-color: lighten($color-tertiary, 20%);
    border-radius: 4px;
    line-height: 48px;
    text-overflow: ellipsis;

    &.selected {
      background-color: $color-contrast;
      padding-right: 52px;
    }

    &.opened {
      background-color: $color-contrast;
      line-height: 48px;
    }

    &.placeholder {
      color: $color-secondary;
    }
  }

  input {
    @include arial-regular();
    @include borderReset();
    @include borderBox();
    position: absolute;
    z-index: 0;
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border: 1px solid $color-tertiary;
    padding: 0 16px;
    color: $color-secondary;
    font-size: 14px;
    letter-spacing: 0.2px;
    background-color: lighten($color-tertiary, 20%);
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
      color: $color-secondary;
      opacity: 1;
    }
    &.opened {
      opacity: 1;
      @include simpleAnimate(all);
      z-index: 1000;
    }
    &.closed {
      opacity: 0;
      z-index: 0;
    }
  }

  &.searchable {
    position: relative;
    cursor: pointer;
    height: 48px;
    width: 280px;
    display: inline-block;
    text-align: left;

    &.disabled {
      cursor: default;
      opacity: 0.5;
      @include simpleAnimate(all);

      .handler {
        cursor: default;
      }

      .clear-selection {
        &:hover {
          svg {
            path {
              fill: #C7C7CC;
            }
          }
        }
      }
    }

    &.error {
      input {
        border-color: $color-error;
        color: $color-error;
        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder {
          color: $color-error;
          opacity: 1;
        }
      }
      .input-label-text {
        color: $color-error;
      }
    }

    &.focus {
      input {
        padding: 0 15px;
        border: 2px solid $color-accent;
        background-color: $color-contrast;
      }

      .input-label-text {
        color: $color-accent;
      }

      .input-label::before {
        background-color: $color-contrast;
      }
    }

    &.has-value {
      input {
        background-color: $color-contrast;
      }

      .input-label::before {
        background-color: $color-contrast;
      }
    }

    &.invalid {
      input {
        border: 1px solid $color-error;
      }

      .input-label-text {
        color: $color-error;
      }
    }

    .clear-selection {
      position: absolute;
      right: 40px;
      will-change: transform;
      top: 19px;
      width: 10px;
      height: 10px;

      &:hover {
        svg {
          path {
            fill: $color-error;
          }
        }
      }
    }

    .select-popup {
      top: 100%;
      margin-top: 48px;
      left: 0;
      width: 100%;
      overflow-x: hidden;
      @include normalLine();

      input {
        @include borderBox();
        line-height: 1.3em;
        padding: 8px 16px;
        @include gilroy-regular();
        font-size: 13px;
        color: $color-main;
      }

      ul {
        padding: 4px 0;
        margin: 0;

        li {
          @include borderBox();
          line-height: 1.3em;
          padding: 8px 16px;
          @include gilroy-regular();
          font-size: 13px;
          color: $color-main;
          list-style: none;
          .highlight-search {
            color: #1665D8;
            font-weight: 700;
          }
          &.result:hover {
            cursor: pointer;
            background-color: fade($color-accent, 0.4);
          }

          &.no-results {
            cursor: default;
          }
        }
      }
    }



    .checkIcon,
    .arrow {
      position: absolute;
      right: 11px;
      will-change: transform;
      path {
        fill: $color-secondary;
      }
    }

    .checkIcon {
      width: 17px;
      height: 17px;
      top: 16px;
    }

    .arrow {
      top: 13px;
      width: 8px;
      height: 20px;
      &.arrow--up {
        transform: rotate(-180deg);
      }
    }
  }
}
