.modal-old__wrapper {
  .e-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-main;
  }
  .wrap-table {
    @include tableBlock();
  }
  .wrap-cell {
    text-align: center;
    @include tableCell();
  }
  .window {
    display: inline-block;
    position: relative;
    padding: 24px;
    min-width: 300px;
    background-color: $color-contrast;
    border: 1px solid $color-icony;
    border-radius: 8px;
    //width: ${(props: IModalOldStylesProps) => props.width}px;
    //height: ${(props: IModalOldStylesProps) => props.height}px;
  }
  .close-icon {
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
  .close-icon-svg {
    width: 100%;
    height: 100%;
    display: block;
    fill: $color-secondary;
  }
}