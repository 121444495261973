@use '@styles/core/normalize';
@use '@styles/core/mixins/color' as *;
@use '@styles/core/mixins/atoms' as *;
@use '@styles/core/mixins/fonts' as *;
@use '@styles/core/breakpoint' as *;

.DeletedPromotionsAndProductsInfo {
  min-height: 30vh;
  min-width: 80vh;
  padding: 15px 15px 75px;

  &__header {
    width: 100%;
    text-align: center;
  }

  &__body {
    margin-top: 15px;
    min-width: 80vh;

    .block {
      padding-right: 15px;
    }
  }

  .continueButton {
    position: absolute;
    bottom: 10px;
    right: 15px;
  }

  .titleBlock {
    color: green;
    margin-bottom: 10px;
  }

  @media (max-width: 900px) {
    min-width: 30vh;
    min-height: 20vh;

    &__body {
      min-width: unset;
    }
  }
}
